import React from 'react';
import './MembershipTypes.scss';
import AdminComponent from '../../../components/admin-component/AdminComponent';

export default () => (
  <AdminComponent
    componentName="MembershipTypes"
    title="Memebership Types"
  />
);
