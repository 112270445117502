import React from 'react';
import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  Selection,
  SearchPanel,
  Lookup,
  ColumnChooser,
  Grouping,
  GroupPanel,
  FilterPanel,
  HeaderFilter,
  FilterRow,
  Button,
} from 'devextreme-react/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import SelectBox from 'devextreme-react/select-box';
import calcGridHeight, {
  setDateTimeInlineEditing,
  renderPreferredFirstNameGridCell,
  convertToTZ
} from '../../helpers/ui';
import { CreateAuthorizedDataSource, SetAuthorizationHeaderAsync } from '../../helpers/data';
import { appConst } from '../../AppConst';

import GridLayout from '../grid-layout-component/GridLayout';
import './PersonCallLog.scss';

const callLogsUrl = `${window.env.apiEndpoint}/api/v1/CallLogs`;

class CallLogs extends React.Component {
  constructor(props) {
    super(props);

    this.subCategoriesData = this.subCategoriesData.bind(this);

    this.state = {
      currentFirstName: '',
    };

    this.categoriesData = CreateAuthorizedDataSource({ loadUrl: `${window.env.apiEndpoint}/api/v1/CallLogCategories/CallLogCategoriesLookup` }, null, 'Value', null, 'Header');
    this.actionsData = CreateAuthorizedDataSource({ loadUrl: `${window.env.apiEndpoint}/api/v1/CallLogActions/CallLogActionsLookup` }, null, 'Value', null, 'Header');
    this.usersData = CreateAuthorizedDataSource({ loadUrl: `${window.env.apiEndpoint}/api/v1/Users/Get` }, null, 'Id');

    this.selectBoxActionColumnTemplate = this.selectBoxActionColumnTemplate.bind(this);
    this.selectBoxCategoryColumnTemplate = this.selectBoxCategoryColumnTemplate.bind(this);
    this.iSPersonGrid = this.iSPersonGrid.bind(this);
  }

  dataSource = () => {
    return CreateAuthorizedDataSource(
      {
        loadUrl: this.props.Id ? `${callLogsUrl}/GetCallLogsByPerson` : `${callLogsUrl}/Get`,
        key: 'Id',
        updateUrl: `${callLogsUrl}/Put`,
        deleteUrl: `${callLogsUrl}/Delete`,
      },
      {
        load: {
          timeZone: window.env.timeZone,
          personId: this.props.Id,
        },
        update: { timeZone: window.env.timeZone },
      }, 'Id',
    );
  }

  setGridHeight() {
    this.callLogsGrid.instance.option('height', calcGridHeight('callLogsGrid'));
  }

  subCategoriesData(options) {
    return (
      createStore({
        loadUrl: `${window.env.apiEndpoint}/api/v1/CallLogSubCategories/CallLogSubCategoriesLookup`,
        key: 'Value',
        onBeforeSend: async (method, ajaxOptions) => {
          await SetAuthorizationHeaderAsync(ajaxOptions);

          if (method === 'load' && typeof options.data !== 'undefined') {
            ajaxOptions.data.categoryId = options.data.CallLogCategoryId;
          }
        },
      }));
  }

  setCategoryValue(rowData, value) {
    rowData.CallLogSubcategoryId = null;
    this.defaultSetCellValue(rowData, value);
  }

  onEditorPreparing(e) {
    if (e.parentType === 'dataRow' && e.dataField === 'CallLogSubcategoryId') {
      e.editorOptions.disabled = (typeof e.row.data.CallLogCategoryId !== 'number');
    }
    if (e.dataField === 'Details') {
      e.editorName = 'dxTextArea';
      e.editorOptions.height = 100;
    }
    if (e.dataField === 'Type'
      || e.dataField === 'FirstName'
      || e.dataField === 'LastName'
      || e.dataField === 'School'
      || e.dataField === 'PreferredFirstName') {
      e.editorOptions.readOnly = true;
    }
    setDateTimeInlineEditing(['CallDate'], e);
  }

  onSelectBoxValueChanged(cell, e) {
    cell.setValue(e.value);
  }

  selectBoxActionColumnTemplate(cell) {
    const onValueChanged = this.onSelectBoxValueChanged.bind(this, cell);
    return (
      <SelectBox
        {...appConst.defaultSelectBoxOptions}
        defaultValue={cell.value}
        {...cell.column.lookup}
        onValueChanged={onValueChanged}
        itemRender={this.itemRender}
        grouped
        dataSource={this.actionsData}
      />
    );
  }

  selectBoxCategoryColumnTemplate(cell) {
    const onValueChanged = this.onSelectBoxValueChanged.bind(this, cell);
    return (
      <SelectBox
        {...appConst.defaultSelectBoxOptions}
        defaultValue={cell.value}
        {...cell.column.lookup}
        onValueChanged={onValueChanged}
        itemRender={this.itemRender}
        grouped
        dataSource={this.categoriesData}
      />
    );
  }

  renderDetails(cellData) {
    return (
      <div className="detail-container">{cellData.data.Details}</div>
    );
  }

  iSPersonGrid() {
    return (!!this.props.Id);
  }

  componentDidMount() {
    this.callLogsGrid.instance.option('height', calcGridHeight('callLogsGrid'));
  }

  render() {
    return (
      <>
        <GridLayout
          layoutKey="CallLogs"
          gridName="callLogGrid"
          pageName="CallLogPage"
          gridRef={
          () => this.callLogsGrid.instance
        }
        />
        <DataGrid
          id="callLogsGrid"
          ref={(ref) => { this.callLogsGrid = ref; }}
          dataSource={this.dataSource()}
          {...appConst.defaultGridOptions}
          onEditorPreparing={this.onEditorPreparing}
          onRowDblClick={(e) => {
            e.component.editRow(e.rowIndex);
          }}
          height={this.iSPersonGrid() ? "100%" : () => calcGridHeight('callLogsGrid')}
        >
          <Scrolling
            mode="virtual"
            rowRenderingMode="virtual"
          />
          <Selection mode="multiple" />
          <SearchPanel
            visible
            width={240}
            placeholder="Search..."
          />
          <Editing
            mode="popup"
            allowAdding={false}
            allowDeleting
            allowUpdating
          />
          <ColumnChooser
            enabled
            mode="dragAndDrop"
          />
          <Grouping
            allowCollapsing
            autoExpandAll={false}
            contextMenuEnabled
            expandMode="rowClick"
          />
          <GroupPanel
            visible
            allowColumnDragging
          />
          <HeaderFilter
            visible
            allowSearch
          />
          <FilterRow visible />
          <FilterPanel visible />
          <Column
            dataField="Type"
            dataType="string"
            caption="Type"
            visible={false}
          />
          <Column
            dataField="CallDate"
            dataType="datetime"
            caption="Call Date"
            format={appConst.dateAndTimeDisplayFormat}
            sortOrder="desc"
            calculateCellValue={
              (data) => {
                return convertToTZ(data.CallDate);
              }
            }
          />
          <Column
            dataField="CallDateYear"
            dataType="string"
            allowEditing={false}
            formItem={{ visible: false }}
            visible={false}
          />
          <Column
            dataField="CallDateMonth"
            dataType="string"
            allowEditing={false}
            formItem={{ visible: false }}
            visible={false}
          />
          <Column
            dataField="PreferredFirstName"
            dataType="string"
            caption="Preferred First Name"
            visible={!this.iSPersonGrid()}
            showInColumnChooser={!this.iSPersonGrid()}
            formItem={{ visible: !this.iSPersonGrid() }}
            cellRender={(data) => renderPreferredFirstNameGridCell(this, data)}
          />
          <Column
            dataField="LastName"
            dataType="string"
            caption="Last Name"
            visible={!this.iSPersonGrid()}
            showInColumnChooser={!this.iSPersonGrid()}
            formItem={{ visible: !this.iSPersonGrid() }}
          />
          <Column dataField="School" dataType="string" caption="School" allowEditing="false" />
          <Column
            dataField="CallLogCategoryId"
            dataType="number"
            caption="Category"
            setCellValue={this.setCategoryValue}
            editCellRender={this.selectBoxCategoryColumnTemplate}
          >
            <Lookup
              dataSource={this.categoriesData.store()}
              valueExpr="Value"
              displayExpr="Text"
            />
          </Column>
          <Column dataField="CallLogSubcategoryId" dataType="number" caption="Subcategory">
            <Lookup dataSource={this.subCategoriesData} valueExpr="Value" displayExpr="Text" />
          </Column>
          <Column
            dataField="CallLogActionId"
            dataType="number"
            caption="Action"
            editCellRender={this.selectBoxActionColumnTemplate}
          >
            <Lookup dataSource={this.actionsData.store()} valueExpr="Value" displayExpr="Text" />
          </Column>
          <Column dataField="UserId" dataType="number" caption="User">
            <Lookup dataSource={this.usersData.store()} valueExpr="Id" displayExpr="Name" />
          </Column>
          <Column
            dataField="Details"
            dataType="string"
            caption="Details"
            cellRender={this.renderDetails}
            width={250}
          />
          <Column
            dataField="FirstName"
            dataType="string"
            caption="First Name"
            visible={false}
            showInColumnChooser={!this.iSPersonGrid()}
            formItem={{ visible: !this.iSPersonGrid() }}
          />
          <Column
            dataField="InProgress"
            dataType="boolean"
            caption="In Progress"
            visible={false}
            allowEditing={false}
            showEditorAlways={false}
            customizeText={(e) => {
              if (e.value) { return 'Yes'; }
              return 'No';
            }}
          />
          <Column type="buttons">
            <Button
              name="edit"
              visible={(e) => !e.row.data.InProgress}
            />
            <Button
              name="delete"
              visible={(e) => !e.row.data.InProgress}
            />
          </Column>
        </DataGrid>
      </>
    );
  }
}
export default CallLogs;
