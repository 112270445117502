import React from 'react';
import { Popup } from 'devextreme-react';
import {
  CreateAuthorizedStore,
} from '../../helpers/data';
import { appConst } from '../../AppConst';
import EditMemberForm from './EditMemberForm';

const schoolsUrl = `${window.env.apiEndpoint}/api/v1/Schools`;

class EditMemberPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
    };

    this.provincesData = CreateAuthorizedStore({ loadUrl: `${schoolsUrl}/provincesLookup` }, null, 'Value');
    this.getMemberPopupRef = this.getMemberPopupRef.bind(this);
  }

  getMemberPopupRef() {
    return this.editMemberFormPopup && this.editMemberFormPopup.instance;
  }

  renderMemberEditPopUp(isEditFormShown) {
    const {
      rowMemberData,
      memberAddNewMode,
      hideMemberEditPopupAndRefreshGrid,
      hideMemberEditPopup,
    } = this.props;

    if (isEditFormShown) {
      const { showMemberEditForm, membersLookupData } = this.props;
      return (
        <Popup
          ref={(ref) => { this.editMemberFormPopup = ref; }}
          visible={showMemberEditForm}
          onHiding={(e) => {
            e.cancel = true;
            this.refs.editMemberForm && this.refs.editMemberForm.cancelData();
          }}
          onShown={(e) => {
            this.refs.editMemberForm.changeEditFormTitle();
          }}
          {...appConst.defaultPopupOptions}
        >
          <EditMemberForm
            ref="editMemberForm"
            rowData={rowMemberData}
            hideEditPopupAndRefreshGrid={hideMemberEditPopupAndRefreshGrid}
            hideEditPopup={hideMemberEditPopup}
            salutationsData={membersLookupData.salutationsData}
            gendersData={membersLookupData.gendersData}
            pronounsData={membersLookupData.pronounsData}
            emailAddressTypesData={membersLookupData.emailAddressTypesData}
            phoneTypesData={membersLookupData.phoneTypesData}
            membershipTypesData={membersLookupData.membershipTypesData}
            employmentStatusesData={membersLookupData.employmentStatusesData}
            contractTypesData={membersLookupData.contractTypesData}
            designationsData={membersLookupData.designationsData}
            ridingsData={membersLookupData.ridingsData}
            addNewMode={memberAddNewMode}
            getMemberPopupRef={this.getMemberPopupRef}
            provincesData={membersLookupData.provincesData}
          />
        </Popup>
      );
    }
  }

  render() {
    const { showMemberEditForm } = this.props;
    return (
      <>
        {this.renderMemberEditPopUp(showMemberEditForm)}
      </>
    );
  }
}
export default EditMemberPopup;
