import { strings } from './locale.js';

export const navigation = [
  {
    text: 'Members',
    path: '/members',
    icon: 'user'
  },
  {
    text: 'Non-Members',
    path: '/non-members',
    icon: 'home'
  },
  {
    text: 'Schools',
    path: '/schools',
    icon: 'fas fa-building'
  },
  {
    text: 'Committees',
    path: '/committees',
    icon: 'group'
  },
  {
    text: `${strings.cor} Roles`,
    path: '/csrroles',
    icon: 'fas fa-user-tag'
  },
  {
    text: 'Call Logs',
    path: '/call-logs',
    icon: 'tel'
  },
  {
    text: 'Funding',
    path: '/funding',
    icon: 'money'
  },
  {
    text: 'Admin',
    icon: 'folder',
    items: [
      {
        text: 'Users and Roles',
        items: [
          {
            text: 'Users',
            path: '/users'
          },
          {
            text: 'Roles',
            path: '/roles'
          },
          {
            text: 'Permissions',
            path: '/permissions'
          }
        ]
      },
      {
        text: 'Member Lookup Fields',
        items: [
          {
            text: 'Genders',
            path: '/admin/genders'
          },
          {
            text: 'Salutations',
            path: '/admin/salutations'
          },
          {
            text: 'Pronouns',
            path: '/admin/pronouns'
          },
          {
            text: 'Membership Types',
            path: '/admin/membership-types'
          },
          {
            text: 'Contract Types',
            path: '/admin/contract-types'
          },
          {
            text: 'Employment Statuses',
            path: '/admin/employment-statuses'
          },
          {
            text: 'Designations',
            path: '/admin/designations'
          },
          {
            text: 'Provinces',
            path:'/admin/provinces'
          }
        ]
      },
      {
        text: 'Ridings',
        path: '/admin/ridings'
      },
      {
        text: 'Divisions',
        path: '/admin/divisions',
      },
      {
        text: 'Fund Types',
        path: '/admin/fund-types',
      },
      {
        text: 'Committees',
        items: [
          {
            text: 'School Years',
            path: '/admin/school-years'
          },
          {
            text: 'Committee Groups',
            path: '/admin/committee-groups'
          },
        ]
      },
      {
        text: 'Audit Logs',
        path: '/admin/audit-logs',
      },
      {
        text: 'Call Log',
        items: [
          {
            text: 'Call Categories',
            path: '/admin/call-categories',
          },
          {
            text: 'Call Sub Categories',
            path: '/admin/call-sub-categories',
          },
          {
            text: 'Call Actions',
            path: '/admin/call-actions',
          }
        ]
      }
    ]
  },
]