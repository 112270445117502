import React, { useContext, Fragment } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/user-panel';
import './header.scss';
import { Template } from 'devextreme-react/core/template';
import { DropDownButton } from 'devextreme-react';
import AppContext from "../../AppContext"
import Moment from 'react-moment';
import CallInProgressPopups from '../call-in-progress-popups/CallInProgressPopups';
import { NameIdentifier } from '../../helpers/miscellaneous'

function RenderItem(item) {
  return (
    <Fragment>
      <div>
        <i className={(item.Type==='Member')?'dx-icon-user':'dx-icon-card'}></i>
          {NameIdentifier(item.PreferredFirstName, item.FirstName, item.LastName)}
      </div>
      <Moment format="YYYY-MM-DD HH:mm a" className="callDate">
          {item.CallDate}
      </Moment>
    </Fragment>
  )
}

export default ({ menuToggleEnabled, title, toggleMenu, userMenuItems }) => {
  const appContext = useContext(AppContext)
  let callsInProgressDropDownButtonRef = React.createRef();
    return (
      <header className={'header-component'}>
        <Toolbar className={'header-toolbar'}>
          <Item
            visible={menuToggleEnabled}
            location={'before'}
            widget={'dxButton'}
            cssClass={'menu-button'}
          >
            <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
          </Item>
          <Item
            location={'before'}
            cssClass={'header-title'}
            text={title}
            visible={!!title}
          />
          <Item
            location={'after'}
            locateInMenu={'auto'}>
            <DropDownButton
              id="callsInProgressDropDownButtonRef"
              ref={callsInProgressDropDownButtonRef}
              visible={appContext.callsInProgress.length > 0}
              width={250}
              height={'100%'}
              stylingMode={'text'}
              icon={'tel'}
              text={`Calls In Progress (${appContext.callsInProgress.length})`}
              displayExpr={'FirstName'}
              keyExpr={'Key'}
              dataSource={appContext.callsInProgress}
              itemRender={RenderItem}
              onItemClick={(e) => {
                const Id = e.itemData.Id;
                const { callsInProgress } = appContext;
                let index = callsInProgress.findIndex(cl => cl.Id === Id);
                callsInProgress[index].Open = true;
                appContext.updateContext({ callsInProgress: callsInProgress });
              }
              }
            >
            </DropDownButton>
          </Item>
          <Item
            location={'after'}
            locateInMenu={'auto'}
            menuItemTemplate={'userPanelTemplate'}
          >
            <Button
              className={'user-button authorization'}
              width={170}
              height={'100%'}
              stylingMode={'text'}
            >
              <UserPanel menuItems={userMenuItems} menuMode={'context'} />
            </Button>
          </Item>
          <Template name={'userPanelTemplate'}>
            <UserPanel menuItems={userMenuItems} menuMode={'list'} />
          </Template>
        </Toolbar>
        <CallInProgressPopups callsInProgressDropDownButtonRef={callsInProgressDropDownButtonRef} />
      </header>
    );
  }
