import React from 'react';
import { Popup } from 'devextreme-react';
import {
  CreateAuthorizedStore,
} from '../../helpers/data';
import { appConst } from '../../AppConst';
import EditNonMemberForm from './EditNonMemberForm';

const schoolsUrl = `${window.env.apiEndpoint}/api/v1/Schools`;

class EditNonMemberPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
    };

    this.provincesData = CreateAuthorizedStore({ loadUrl: `${schoolsUrl}/provincesLookup` }, null, 'Value');
    this.getNonMemberPopupRef = this.getNonMemberPopupRef.bind(this);
  }

  getNonMemberPopupRef() {
    return this.editNonMemberFormPopup && this.editNonMemberFormPopup.instance;
  }

  renderNonMemberEditPopUp(isEditFormShown) {
    const {
      rowMemberData,
      nonMemberAddNewMode,
      hideNonMemberEditPopupAndRefreshGrid,
      hideNonMemberEditPopup,
    } = this.props;

    if (isEditFormShown) {
      const { showNonMemberEditForm, membersLookupData } = this.props;
      return (
        <Popup
          ref={(ref) => { this.editNonMemberFormPopup = ref; }}
          visible={showNonMemberEditForm}
          onHiding={(e) => {
            e.cancel = true;
            this.refs.editNonMemberForm && this.refs.editNonMemberForm.cancelData();
          }}
          onShown={(e) => {
            this.refs.editNonMemberForm.changeEditFormTitle();
          }}
          {...appConst.defaultPopupOptions}
        >
          <EditNonMemberForm
            ref="editNonMemberForm"
            rowData={rowMemberData}
            hideEditPopupAndRefreshGrid={hideNonMemberEditPopupAndRefreshGrid}
            hideEditPopup={hideNonMemberEditPopup}
            salutationsData={membersLookupData.salutationsData}
            gendersData={membersLookupData.gendersData}
            pronounsData={membersLookupData.pronounsData}
            emailAddressTypesData={membersLookupData.emailAddressTypesData}
            phoneTypesData={membersLookupData.phoneTypesData}
            membershipTypesData={membersLookupData.membershipTypesData}
            employmentStatusesData={membersLookupData.employmentStatusesData}
            contractTypesData={membersLookupData.contractTypesData}
            designationsData={membersLookupData.designationsData}
            ridingsData={membersLookupData.ridingsData}
            addNewMode={nonMemberAddNewMode}
            getNonMemberPopupRef={this.getNonMemberPopupRef}
            provincesData={membersLookupData.provincesData}
          />
        </Popup>
      );
    }
  }

  render() {
    const { showNonMemberEditForm } = this.props;
    return (
      <>
        {this.renderNonMemberEditPopUp(showNonMemberEditForm)}
      </>
    );
  }
}
export default EditNonMemberPopup;
