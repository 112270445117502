import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import {
  msalApp,
  acquireToken,
  EPITOME_REQUESTS
} from "../utils/auth-utils";
import { NameIdentifier } from '../helpers/miscellaneous'
import moment from 'moment';

export async function SetAuthorizationHeaderAsync(ajaxOptions) {
  //Get an access token for the Epitome API
  const account = getAccount();
  const tokenResponse = await acquireToken(
    {
      account,
      ...EPITOME_REQUESTS.ALL
    }
  );
  addAuthHeader(ajaxOptions, tokenResponse);
}

export async function getAuthorizationTokenAsync() {
  //Get an access token for the Epitome API
  const account = getAccount();
  const tokenResponse = await acquireToken(
    {
      account,
      ...EPITOME_REQUESTS.ALL
    }
  );
  return tokenResponse;
}

export function SetAuthorizationHeader(ajaxOptions) {
  const account = getAccount();
  const tokenResponse = acquireToken(
    {
      account,
      ...EPITOME_REQUESTS.ALL
    }
  );
  addAuthHeader(ajaxOptions, tokenResponse);
}

function getAccount() {
  const accounts = msalApp.getAllAccounts();
  if (accounts && accounts.length)
    return accounts[0];
}

function addAuthHeader(ajaxOptions, tokenResponse) {
  if (tokenResponse && ajaxOptions) {
    ajaxOptions.headers = { 'Authorization': 'Bearer ' + tokenResponse.accessToken };
  }
}

export function CreateAuthorizedDataSource(urls, parameters, key, paginate, group, sort) {
  let ds = new DataSource({
    store: createStore({
      loadUrl: urls.loadUrl,
      insertUrl: urls.insertUrl,
      updateUrl: urls.updateUrl,
      deleteUrl: urls.deleteUrl,
      key: key,
      onBeforeSend: async (method, ajaxOptions) => await setupRequestAsync(method, parameters, ajaxOptions)
    }), paginate: (paginate !== undefined) ? paginate : undefined
    , group: (group !== undefined) ? group : undefined, sort: (sort !== undefined) ? sort : undefined
  });
  return ds;
}

export async function CreateAuthorizedDataSourceClientGroupingAsync(urls, parameters, key, paginate, group, sort) {
    let ds = CreateAuthorizedDataSource(urls, parameters, key, paginate, undefined, sort);
    let dataSource = new DataSource({
        store: await ds.load(),
        group: group
    });
    ds.dispose();
    return dataSource;
}

export function CreateAuthorizedStore(urls, parameters, key) {
  let store = createStore({
    loadUrl: urls.loadUrl,
    insertUrl: urls.insertUrl,
    updateUrl: urls.updateUrl,
    deleteUrl: urls.deleteUrl,
    key: key,
    onBeforeSend: async (method, ajaxOptions) => await setupRequestAsync(method, parameters, ajaxOptions)
  });
  return store;
}

async function setupRequestAsync(method, parameters, ajaxOptions) {
  await SetAuthorizationHeaderAsync(ajaxOptions);
  if (parameters) {
    if (parameters[method]) {
      for (var index in parameters[method]) {
        ajaxOptions.data[index] = parameters[method][index];
      }
    }
  }
}

export async function loadMembersLookupDataAsync() {
    const endPoints = [
        { endPoint: 'Genders/GendersLookup', property: 'gendersData', clientGrouping:true },
        { endPoint: 'Salutations/SalutationsLookup', property: 'salutationsData' ,clientGrouping: true },
        { endPoint: 'Pronouns/PronounsLookup', property: 'pronounsData', clientGrouping: true },
        { endPoint: 'Members/EmailAddressTypesLookup', property: 'emailAddressTypesData', clientGrouping: false  },
        { endPoint: 'Members/PhoneNumberTypesLookup', property: 'phoneTypesData', clientGrouping: false  },
        { endPoint: 'MembershipTypes/MembershipTypesLookup', property: 'membershipTypesData', clientGrouping: true  },
        { endPoint: 'EmploymentStatuses/EmploymentStatusesLookup', property: 'employmentStatusesData', clientGrouping: true  },
        { endPoint: 'ContractTypes/ContractTypesLookup', property: 'contractTypesData', clientGrouping: true  },
        { endPoint: 'Designations/DesignationsLookup', property: 'designationsData', clientGrouping: true  },
        { endPoint: 'Ridings/RidingsLookup', property: 'ridingsData', clientGrouping: true  },
        { endPoint: 'Provinces/ProvincesLookup', property: 'provincesData', clientGrouping: false  },
    ]
    let lookupData = {};
    endPoints.map(async function (item) {
        if (item.clientGrouping) {
            lookupData[item.property] = await CreateAuthorizedDataSourceClientGroupingAsync(
                { 'loadUrl': `${window.env.apiEndpoint}/api/v1/${item.endPoint}` }, null, 'Value', false, 'Header');
        } else {
            let ds = CreateAuthorizedDataSource(
                { 'loadUrl': `${window.env.apiEndpoint}/api/v1/${item.endPoint}` }, null, 'Value', false);
            lookupData[item.property] = await ds.load();
            ds.dispose();
        }
    })
 
  return lookupData;
}

export function disposeMembersLookupData(lookupData) {
    const lookups = Object.getOwnPropertyNames(lookupData);
    lookups.map(function (lookup) {
        if (typeof lookupData[lookup].dispose === "function") {
            lookupData[lookup].dispose();
        }
        return true;
    });
}

export const getMemberById = (memberId) => {
  return new Promise((resolve, reject) => {
    const membersUrl = `${window.env.apiEndpoint}/api/v1/Members`;
    const getMemberByIdStore = CreateAuthorizedStore(
      { 'loadUrl': `${membersUrl}/GetMemberById` }, { 'load': { 'Id': memberId } }
    )

    getMemberByIdStore.load().then(
      (result) => {
        resolve(result[0]);
      },
      (error) => {
        reject(error)
      }
    );
  });
}

export const getNonMemberById = (nonMemberId) => {
  return new Promise((resolve, reject) => {
    const membersUrl = `${window.env.apiEndpoint}/api/v1/NonMembers`;
    const getNonMemberByIdStore = CreateAuthorizedStore(
      { 'loadUrl': `${membersUrl}/GetNonMemberById` }, { 'load': { 'Id': nonMemberId } }
    )

    getNonMemberByIdStore.load().then(
      (result) => {
        resolve(result[0]);
      },
      (error) => {
        reject(error)
      }
    );
  });
}

export const getSchoolById = (schoolId) => {
  return new Promise((resolve, reject) => {
    const membersUrl = `${window.env.apiEndpoint}/api/v1/Schools`;
    const getSchoolByIdStore = CreateAuthorizedStore(
      { 'loadUrl': `${membersUrl}/GetSchoolById` }, { 'load': { 'Id': schoolId } }
    )

    getSchoolByIdStore.load().then(
      (result) => {
        resolve(result[0]);
      },
      (error) => {
        reject(error)
      }
    );
  });
};

export const createNewCall = (callsInProgress, updateContext, account, rowData, type) => {
  const callLogUrl = `${window.env.apiEndpoint}/api/v1/CallLogs`
  let name = NameIdentifier(rowData.PreferredFirstName, rowData.FirstName, rowData.LastName);

  return new Promise((resolve, reject) => {
    const checkEmailAddress = CreateAuthorizedStore(
      { 'loadUrl': `${callLogUrl}/GetUserByEmail` }, { 'load': { 'Email': account.username } }
    )

    if (callsInProgress.some((cl) => cl.PersonId === rowData.Id)) {
      const index = callsInProgress.findIndex((cl) => cl.PersonId === rowData.Id);
      callsInProgress[index].Open = true;
      updateContext({ callsInProgress });
      reject("Current person has already an open call. You can't have duplicate open call logs.");
    } else {
      checkEmailAddress.load().then(
        (result) => {
          const userId = result[0].Id;
          const postNewCallLog = CreateAuthorizedStore(
            { 'insertUrl': `${callLogUrl}/Post` },
            { insert: { timeZone: window.env.timeZone } },
            'Id'
          );

          let date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
          const newCallLog = {
            PersonId: rowData.Id, CallDate: date, InProgress: true, UserId: userId,
          };
          postNewCallLog.insert(newCallLog).then(
            (Id) => {
              callsInProgress.push({
                Id,
                PersonId: rowData.Id,
                FirstName: name,
                UserId: userId,
                CallDate: date,
                Open: true,
                Type:type
              });
              updateContext({
                callsInProgress,
              });
              resolve(Id);
            },
            (error) => {
              reject(error);
            }
          )
        },
        (error) => {
          reject(error);
        },
      )
    }
  });
};