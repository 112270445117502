import React from 'react';
import './CallSubCategories.scss';
import {
  DataGrid, Column, Editing, Scrolling, Selection, SearchPanel, Lookup,
} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import SelectBox from 'devextreme-react/select-box';
import CurrentSwitch from '../../../components/current-switch/current-switch';
import calcGridHeight from '../../../helpers/ui';
import { CreateAuthorizedDataSource } from '../../../helpers/data';
import { appConst } from '../../../AppConst';

const URL = `${window.env.apiEndpoint}/api/v1/CallLogSubCategories`;

class CallSubCategories extends React.Component {
  constructor(props) {
    super(props);

    this.toolbarItemRender = this.toolbarItemRender.bind(this);
    this.windowResized = this.windowResized.bind(this);

    this.dataSource = CreateAuthorizedDataSource({
      loadUrl: `${URL}/Get`,
      insertUrl: `${URL}/Post`,
      updateUrl: `${URL}/Put`,
      deleteUrl: `${URL}/Delete`,
    }, null, 'Id');

    this.categoriesData = CreateAuthorizedDataSource(
      { loadUrl: `${URL}/CallCategoriesLookup` }, null, 'Value', null, 'Header',
    );
    this.selectBoxCategoryColumnTemplate = this.selectBoxCategoryColumnTemplate.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResized);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResized);
    if (this.dataSource) {
      this.dataSource.dispose();
    }
  }

  static onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'switchCurrent',
      },
    );
  }

  toolbarItemRender() {
    return (
      <CurrentSwitch grid={this.grid} />
    );
  }

  windowResized() {
    this.grid.instance.option('height', calcGridHeight('grid'));
  }

  onSelectBoxCategoryValueChanged(cell, e) {
    cell.setValue(e.value);
  }

  selectBoxCategoryColumnTemplate(cell) {
    const onValueChanged = this.onSelectBoxCategoryValueChanged.bind(this, cell);
    return (
      <SelectBox
        {...appConst.defaultSelectBoxOptions}
        defaultValue={cell.value}
        {...cell.column.lookup}
        onValueChanged={onValueChanged}
        itemRender={this.itemRender}
        grouped
        dataSource={this.categoriesData}
      />
    );
  }

  render() {
    return (
      <>
        <div className="title">
          <span>Call Log Subcategories</span>
        </div>
        <DataGrid
          id="grid"
          ref={(ref) => { this.grid = ref; }}
          dataSource={this.dataSource}
          {...appConst.defaultAdminGridOptions}
          onToolbarPreparing={CallSubCategories.onToolbarPreparing}
          height={() => calcGridHeight('grid')}
        >
          <Selection mode="multiple" />
          <SearchPanel
            visible
            width={240}
            placeholder="Search..."
          />
          <Scrolling
            mode="virtual"
            rowRenderingMode="virtual"
          />
          <Editing
            mode="row"
            allowAdding
            allowDeleting
            allowUpdating
          />
          <Column dataField="CategoryId" dataType="number" caption="Category" editCellRender={this.selectBoxCategoryColumnTemplate}>
            <Lookup dataSource={this.categoriesData.store()} valueExpr="Value" displayExpr="Text" />
          </Column>
          <Column
            dataField="Description"
            dataType="string"
            sortOrder="asc"
            sortIndex="0"
          />
          <Column
            dataField="Current"
            filterValue
            dataType="boolean"
            sortOrder="desc"
            sortIndex="1"
          />
          <Template name="switchCurrent" render={this.toolbarItemRender} />
        </DataGrid>
      </>
    );
  }
}

export default CallSubCategories;
