import React from 'react';
import './CallInProgressPopups.scss';
import PropTypes from 'prop-types';
import AppContext from '../../AppContext';
import CallLogPopup from '../call-log-popup/CallLogPopup';

class CallInProgressPopups extends React.Component {
  constructor(props) {
    super(props);

    this.refreshCallLogDropDownButton = this.refreshCallLogDropDownButton.bind(this);
  }

  refreshCallLogDropDownButton() {
    const { callsInProgress } = this.context;
    const { callsInProgressDropDownButtonRef } = this.props;
    callsInProgressDropDownButtonRef.current.instance.option('items', callsInProgress);
  }

  render() {
    const { callsInProgress } = this.context;
    return (
      <>
        {callsInProgress.filter((cl) => cl.Open)
          && callsInProgress.filter((cl) => cl.Open).map((callLog, index) => (
            <CallLogPopup
              Id={callLog.Id}
              key={callLog.Id}
              FirstName={callLog.FirstName}
              refreshCallLogDropDownButton={this.refreshCallLogDropDownButton}
              visible={callLog.Open}
              memberId={callLog.Key}
              offset={`${50 * index} ${50 * index}`}
            />
          ))}
      </>
    );
  }
}
CallInProgressPopups.contextType = AppContext;
CallInProgressPopups.propTypes = {
  callsInProgressDropDownButtonRef: PropTypes.object.isRequired,
};
export default CallInProgressPopups;
