import {
  ProfilePage,
  MembersPage,
  GendersPage,
  SalutationsPage,
  PronounsPage,
  RidingsPage,
  SchoolsPage,
  DivisionsPage,
  CSRRolesPage,
  MembershipTypesPage,
  CommitteesPage,
  SchoolYearsPage,
  CommitteeGroupsPage,
  FundTypesPage,
  NonMembersPage,
  CallCategoriesPage,
  CallSubCategoriesPage,
  CallActionsPage,
  CallLogsPage,
  UsersPage,
  RolesPage,
  PermissionsPage,
  ContractTypesPage,
  EmploymentStatusesPage,
  DesignationsPage,
  AuditLogsPage,
  FundingPage,
  ProvincesPage
} from './pages';

export default [
  {
    path: '/profile',
    component: ProfilePage
  },
  {
    path: '/home',
    component: MembersPage
  }, 
{
    path: '/members',
    component: MembersPage
  }, 
{
    path: '/admin/genders',
    component: GendersPage
  }, 
{
    path: '/admin/salutations',
    component: SalutationsPage
  }, 
{
    path: '/admin/pronouns',
    component: PronounsPage
  }, 
  {
    path: '/admin/contract-types',
    component: ContractTypesPage
  }, 
  {
    path: '/admin/employment-statuses',
    component: EmploymentStatusesPage
  }, 
{
    path: '/admin/ridings',
    component: RidingsPage
  }, 
{
    path: '/schools',
    component: SchoolsPage
  }, 
{
    path: '/admin/divisions',
    component: DivisionsPage
  }, 
{
    path: '/csrroles',
    component: CSRRolesPage
  }, 
{
    path: '/admin/membership-types',
    component: MembershipTypesPage
  }, 
{
    path: '/committees',
    component: CommitteesPage
  }, 
{
    path: '/admin/school-years',
    component: SchoolYearsPage
  }, 
{
    path: '/admin/committee-groups',
    component: CommitteeGroupsPage
  }, 
{
    path: '/admin/fund-types',
    component: FundTypesPage
  }, 
{
    path: '/non-members',
    component: NonMembersPage
  }, 
{
    path: '/admin/call-categories',
    component: CallCategoriesPage
  }, 
{
    path: '/admin/call-sub-categories',
    component: CallSubCategoriesPage
  }, 
{
    path: '/admin/call-actions',
    component: CallActionsPage
  }, 
{
    path: '/call-logs',
    component: CallLogsPage
  }, 
{
    path: '/users',
    component: UsersPage
  },
  {
    path: '/roles',
    component:RolesPage
  },
  {
    path: '/permissions',
    component:PermissionsPage
  },
  {
    path:'/admin/designations',
    component:DesignationsPage
  },
  {
    path: '/admin/audit-logs',
    component: AuditLogsPage
  },
  {
    path: '/funding',
    component: FundingPage
  },
  {
    path: '/admin/provinces',
    component: ProvincesPage
  },
];
