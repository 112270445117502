import React from 'react';
import './current-switch.scss';
import { Switch } from 'devextreme-react';
import PropTypes from 'prop-types';

export default function CurrentSwitch(props) {
  return (
    <div className="current-switch-container">
      <span className="current-switch-item">Current</span>
      <Switch
        className="current-switch-item"
        defaultValue
        onValueChanged={
                        (e) => {
                          props.grid.instance.beginUpdate();
                          if (e.value) { props.grid.instance.columnOption('Current', 'filterValue', e.value); } else { props.grid.instance.columnOption('Current', 'filterValue', null); }
                          props.grid.instance.endUpdate();
                        }
                    }
      />
    </div>
  );
}

CurrentSwitch.propTypes = {
  grid: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};
