import React from 'react';
import {
    DataGrid,
    Column,
    Editing,
    Scrolling,
    Selection,
    Lookup
} from 'devextreme-react/data-grid';
import { CreateAuthorizedDataSource } from '../../../helpers/data'
import { createStore } from 'devextreme-aspnet-data-nojquery';
import Tabs from 'devextreme-react/tabs';
import notify from 'devextreme/ui/notify';
import Form, { GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import { CreateAuthorizedStore, SetAuthorizationHeaderAsync } from '../../../helpers/data'
import calcGridHeight from '../../../helpers/ui';
import { appConst } from '../../../AppConst';

const rolesUrl = `${window.env.apiEndpoint}/api/v1/Roles`;
const userRolesUrl = `${window.env.apiEndpoint}/api/v1/UserRoles`;
const permissionRolesUrl = `${window.env.apiEndpoint}/api/v1/PermissionRoles`;
const usersUrl = `${window.env.apiEndpoint}/api/v1/Users`;
const permissionsUrl = `${window.env.apiEndpoint}/api/v1/Permissions`;

const usersData = CreateAuthorizedStore(
    { loadUrl: `${usersUrl}/Get` }, null, "Id"
)

const permissionsData = CreateAuthorizedStore(
    { loadUrl: `${permissionsUrl}/Get` }, null, "Id"
)

const tabs = [
    {
        id: 0,
        text: 'Role',
        icon: 'group',
    },
    {
        id: 1,
        text: 'Users',
        icon: 'user',
    },
    {
        id: 2,
        text: 'Permissions',
        icon: 'key'
    }
];

class EditRoleForm extends React.Component {
    constructor(props) {
        super(props);

        this.windowResized = this.windowResized.bind(this);
        this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);
        const { addNewMode, roleId } = this.props;
        this.state = {
            selectedTabIndex: 0,
            addNewMode,
            roleId,
        };
        this.setGridsHeights = this.setGridsHeights.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.windowResized);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResized);
    }

    windowResized() {
        this.setGridsHeights()
    }

    onTabsSelectionChanged(args) {
        if (args.name === 'selectedIndex') {
            const { addNewMode } = this.state;
            if (!addNewMode) {
                this.setState({
                    selectedTabIndex: args.value,
                });
                this.setGridsHeights();
            } else {
                notify('You are not allowed to view users or permissions unless role is saved.');
            }
        }
    }

    saveUserButtonOptions = {
        text: 'Save',
        onClick: () => {
            const { addNewMode } = this.state;
            if (addNewMode) {
                this.rolesStore.insert().then(
                    (result) => { this.props.changeTitle(this.roleForm.instance.option('formData').Name) },
                    (error) => { notify({ message: error.message, shading: true }, 'error'); }
                );
            } else {
                this.rolesStore.update().then(
                    (result) => { this.props.changeTitle(this.roleForm.instance.option('formData').Name) },
                    (error) => { notify({ message: error.message, shading: true }, 'error'); }
                );
            }
        },
    }

    rolesStore = createStore({
        updateUrl: `${rolesUrl}/Put`,
        insertUrl: `${rolesUrl}/Post`,
        key: 'Id',
        onBeforeSend: async (method, ajaxOptions) => {
            await SetAuthorizationHeaderAsync(ajaxOptions);

            if (method === 'update' || method === 'insert') {
                const data = this.roleForm.instance.option('formData');
                const jd = JSON.stringify(data);
                ajaxOptions.data.values = jd;
                const { roleId } = this.state;
                ajaxOptions.data.key = roleId;
            }
        },
        onInserted: (values) => {
            notify('Role is saved.');
            this.setState({ roleId: values, addNewMode: false });
            const { refreshGrid } = this.props;
            refreshGrid();
        },
        onUpdated: () => {
            const { refreshGrid } = this.props;
            refreshGrid();
        },
    });

    userRolesDataSource() {
        return CreateAuthorizedDataSource(
            {
                'loadUrl': `${userRolesUrl}/GetRoleUsers`,
                'insertUrl': `${userRolesUrl}/PostUserRole`,
                'updateUrl': `${userRolesUrl}/PutUserRole`,
                'deleteUrl': `${userRolesUrl}/DeleteUserRole`
            }, { 'load': { 'roleId': this.state.roleId }, 'insert': { 'roleId': this.state.roleId } },
            ["UserId", "RoleId"], false);
    }

    permissionRolesDataSource() {
        return CreateAuthorizedDataSource(
            {
                'loadUrl': `${permissionRolesUrl}/GetRolePermissions`,
                'insertUrl': `${permissionRolesUrl}/PostRolePermission`,
                'updateUrl': `${permissionRolesUrl}/PutRolePermission`,
                'deleteUrl': `${permissionRolesUrl}/DeleteRolePermission`
            }, { 'load': { 'roleId': this.state.roleId }, 'insert': { 'roleId': this.state.roleId } },
            ["RoleId", "PermissionId"], false);
    }

    setGridsHeights() {
        this.usersGrid.instance.option('height', calcGridHeight('usersGrid'));
        this.permissionsGrid.instance.option('height', calcGridHeight('permissionsGrid'));
    }

    render() {
        const { selectedTabIndex } = this.state;
        const { rowData } = this.props;
        return (
            <>
                <Tabs
                    dataSource={tabs}
                    selectedIndex={this.state.selectedTabIndex}
                    onOptionChanged={this.onTabsSelectionChanged}
                />
                <div style={{ display: (selectedTabIndex === 0) ? 'block' : 'none' }}>
                    <Form
                        ref={(ref) => { this.roleForm = ref; }}
                        formData={rowData}
                        {...appConst.defaultFormOptions}
                    >
                        <GroupItem caption=" " colCount={2}>
                            <SimpleItem editorType="dxTextBox" dataField="Name">
                                <Label text="Name" />
                            </SimpleItem>
                            <SimpleItem editorType="dxTextBox" dataField="Description">
                                <Label text="Description" />
                            </SimpleItem>
                        </GroupItem>
                        <SimpleItem
                            itemType="button"
                            horizontalAlignment="left"
                            buttonOptions={this.saveUserButtonOptions}
                        />
                    </Form>
                </div>
                <div style={{ display: (selectedTabIndex === 1) ? 'block' : 'none' }}>
                    <DataGrid
                        id="usersGrid"
                        key={["RoleId", "UserId"]}
                        ref={(ref) => { this.usersGrid = ref; }}
                        dataSource={this.userRolesDataSource()}
                        {...appConst.defaultGridOptions}
                        onInitNewRow={(e) => { e.data.RoleId = this.state.roleId }}
                    >
                        <Selection mode="multiple" />
                        <Scrolling
                            mode="virtual"
                            rowRenderingMode="virtual"
                        />
                        <Editing
                            mode="row"
                            allowAdding={true}
                            allowDeleting={true}
                            allowUpdating={false}
                        />
                        <Column dataField="UserId" caption="User">
                            <Lookup dataSource={usersData} valueExpr="Id" displayExpr="Name" />
                        </Column>
                    </DataGrid>
                </div>
                <div style={{ display: (selectedTabIndex === 2) ? 'block' : 'none' }}>
                    <DataGrid
                        id="permissionsGrid"
                        key={["RoleId", "PermissionId"]}
                        ref={(ref) => { this.permissionsGrid = ref; }}
                        dataSource={this.permissionRolesDataSource()}
                        {...appConst.defaultGridOptions}
                        onInitNewRow={(e) => { e.data.RoleId = this.state.roleId }}
                    >
                        <Selection mode="multiple" />
                        <Scrolling
                            mode="virtual"
                            rowRenderingMode="virtual"
                        />
                        <Editing
                            mode="row"
                            allowAdding={true}
                            allowDeleting={true}
                            allowUpdating={false}
                        />
                        <Column dataField="PermissionId" caption="Permission">
                            <Lookup dataSource={permissionsData} valueExpr="Id" displayExpr="Name" />
                        </Column>
                    </DataGrid>
                </div>
            </>
        );
    }
}

export default EditRoleForm;
