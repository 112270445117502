import React, { useState, useEffect } from 'react';
import { DataGrid, Column, Lookup, Editing, RequiredRule } from 'devextreme-react/data-grid';
import { CreateAuthorizedDataSource } from '../../helpers/data';
import { appConst } from '../../AppConst';

const URL = `${window.env.apiEndpoint}/api/v1/CommitteeMemberships`;

const getRoles = (key) => {

  let ds = CreateAuthorizedDataSource(
    {
      loadUrl: `${URL}/GetRolesForCommitteeMembership`,
      updateUrl: `${URL}/PutCommitteeMembershipRole`,
      deleteUrl: `${URL}/DeleteCommitteeMembershipRole`,
      insertUrl: `${URL}/PostCommitteeMembershipRole`,
    },
    {
      load: { 'committeeMembershipId': key },
      insert: { 'committeeMembershipId': key },
    }
    , 'Id');
  return ds;
};

const CommitteeMembershipRoleDetails = (props) => {
  const [roleLookups, setRoleLookups] = useState();

  useEffect(() => {
    async function fetchData() {
      const roleLookupsStore = CreateAuthorizedDataSource(
        { loadUrl: `${window.env.apiEndpoint}/api/v1/CommitteeMemberships/GetRoleLookupsForCommittee` },
        { load: { committeeId: props.committeeId }, }, 'Value');
      setRoleLookups(await roleLookupsStore.load());
    }
    fetchData();
  }, [props.committeeId]);
  const dataSource = getRoles(props.committeeMembershipId);
  return (
    <React.Fragment>
      <DataGrid
        id="committeeMembershipRoleDetailsGrid"
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        onRowUpdated={() => {
          props.refreshMasterGrid();
        }}
        onRowInserted={() => {
          props.refreshMasterGrid()
        }}
        onRowRemoved={() => {
          props.refreshMasterGrid()
        }}
        onInitNewRow={(e) => {
          e.data.StartDate = props.startDate;
          e.data.EndDate = props.EndDate;
        }}
        onEditorPreparing={(e) => {
          if (e.dataField === "CommitteeRoleId" && !e.row.isNewRow) {
            e.editorOptions.disabled = true;
          }
        }}
      >
        <Editing
          mode="row"
          allowAdding
          allowDeleting
          allowUpdating
        />
        <Column
          dataField='CommitteeRoleId'
          caption='Role'
          allowEditing={true}
        >
          <RequiredRule/>
        <Lookup
          dataSource={roleLookups}
          valueExpr="Value" 
          displayExpr="Text" />
        </Column>
        <Column
          dataField="StartDate"
          dataType="date"
          format={appConst.dateDisplayFormat}
        />
        <Column
          dataField="EndDate"
          dataType="date"
          format={appConst.dateDisplayFormat}
        />
      </DataGrid>
    </React.Fragment>
  );
};
export default CommitteeMembershipRoleDetails;
