import React from 'react';
import './GridLayout.scss';
import { SelectBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import {
  CreateAuthorizedDataSource,
} from '../../helpers/data';
import { appConst } from '../../AppConst';
import ImportGridLayoutPopup from '../import-grid-layout-popup/ImportGridLayoutPopup';

const gridLayoutsUrl = `${window.env.apiEndpoint}/api/v1/GridLayouts`;

class GridLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      layouts: null,
      showImportGridLayout: false,
    };
    this.layoutsDataSource = this.layoutsDataSource.bind(this);
    this.loadLayouts = this.loadLayouts.bind(this);
    this.saveLayoutClick = this.saveLayoutClick.bind(this);
    this.applyLayout = this.applyLayout.bind(this);
    this.loadLayout = this.loadLayout.bind(this);
    this.layoutSelectBoxRef = React.createRef();
    this.clearLayoutClick = this.clearLayoutClick.bind(this);
    this.deleteLayoutClick = this.deleteLayoutClick.bind(this);
    this.exportLayoutClick = this.exportLayoutClick.bind(this);
    this.importLayoutClick = this.importLayoutClick.bind(this);
    this.showHideImportGridLayout = this.showHideImportGridLayout.bind(this);
  }

  getLayoutStorageKey() {
    const { layoutKey, pageName, gridName } = this.props;
    return `${pageName}_${gridName}_${layoutKey}`;
  }

  get layoutSelectBox() {
    return this.layoutSelectBoxRef.current.instance;
  }

  loadLayouts() {
    return new Promise((resolve, reject) => {
      const ds = this.layoutsDataSource();
      ds.load().then(
        (result) => {
          this.setState({
            layouts: result,
          });
          ds.dispose();
          resolve(true);
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  componentDidMount() {
    this.loadLayouts().then(
      (result) => {
        const { rememberTempLayout, gridRef } = this.props;
        if (rememberTempLayout) {
          gridRef().option("onContentReady", (e) => {
            if (!e.component.isNotFirstLoad) {
              e.component.isNotFirstLoad = true;
              this.setState({ initialGridState: e.component.state() })
            }
          });
        }
        const tempLayout = sessionStorage.getItem(this.getLayoutStorageKey());
        if (tempLayout && rememberTempLayout) {
          gridRef().state(JSON.parse(tempLayout));
        } else {
          const layoutId = localStorage.getItem(this.getLayoutStorageKey());

          if (layoutId) {
            this.applyLayout(layoutId).then();
          }
        }
      },
    );
  }

  componentWillUnmount() {
    const { rememberTempLayout, gridRef } = this.props;
    if (rememberTempLayout) {
      const gState = JSON.stringify(gridRef().state());
      if (JSON.stringify(this.state.initialGridState) !== gState) {
        sessionStorage.setItem(this.getLayoutStorageKey(), gState);
      }
    }
  }

  clearLayoutClick(e) {
    const { gridRef } = this.props;
    gridRef().state({});
    this.layoutSelectBox.option('value', null);
    const key = this.getLayoutStorageKey();
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
    this.setState({
      selectedLayoutName: null,
      selectedLayoutId: null,
    });
  }

  deleteLayoutClick(e) {
    const result = confirm(appConst.confirmDialogContent, appConst.confirmDeleteDialogTitle);
    result.then((dialogResult) => {
      if (dialogResult) {
        const ds = this.layoutsDataSource();
        ds.store().remove().then(
          () => {
            this.setState({
              selectedLayoutName: null,
              selectedLayoutId: null,
            }, () => {
              localStorage.removeItem(this.getLayoutStorageKey());
              this.loadLayouts();
              this.layoutSelectBox.option('value', null);
            });
            ds.dispose();
          },
        );
      }
    });
  }

  layoutsDataSource() {
    const { layoutKey } = this.props;
    const { selectedLayoutId, gridLayoutName, gridLayoutData } = this.state;

    return CreateAuthorizedDataSource(
      {
        insertUrl: `${gridLayoutsUrl}/SaveAs`,
        loadUrl: `${gridLayoutsUrl}/Get`,
        deleteUrl: `${gridLayoutsUrl}/Delete`,
      },
      {
        insert: {
          Name: gridLayoutName,
          Key: layoutKey,
          Data: gridLayoutData,
        },
        load: { Key: layoutKey },
        delete: { Id: selectedLayoutId },
      }, 'Id', false,
    );
  }

  applyLayout(Id) {
    const { gridRef } = this.props;
    return new Promise((resolve, reject) => {
      this.loadLayout(Id).then(
        (result) => {
          gridRef().state(JSON.parse(result.Data));
          localStorage.setItem(this.getLayoutStorageKey(), result.Id);
          this.setState({
            selectedLayoutId: result.Id,
            selectedLayoutName: result.Name,
            initialGridState: JSON.parse(result.Data),
          }, () => {
            this.layoutSelectBox.option('value', result.Id);
            sessionStorage.removeItem(this.getLayoutStorageKey());
            resolve(result);
          });
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  loadLayout(Id) {
    return new Promise((resolve, reject) => {
      const ds = CreateAuthorizedDataSource(
        { loadUrl: `${gridLayoutsUrl}/GetLayoutById` },
        { load: { Id } }, 'Id', false,
      );
      ds.load().then(
        (result) => {
          resolve(result[0]);
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  saveLayoutClick(e) {
    const { layoutKey, gridRef } = this.props;

    const name = prompt('Enter a name for the layout. If you specify an existing name, that layout will be overwritten.', this.state.selectedLayoutName ?? '');
    if (name === null) return;
    this.setState({
      gridLayoutName: name,
      gridLayoutData: JSON.stringify(gridRef().state()),
    });
    const ds = this.layoutsDataSource();
    ds.store().insert({ Name: name, LayoutKey: layoutKey, Data: gridRef().state() }).then(
      (result) => {
        const layoutId = result;
        this.loadLayouts().then(
          (result) => {
            this.applyLayout(layoutId);
          },
        );
        ds.dispose();
      },
      (error) => {
        notify({ message: error.message, shading: true }, 'error');
      },
    );
  }

  selectedLayoutChanged(e) {
    if ((e.value === null) || (typeof e.event === 'undefined')) return;
    this.applyLayout(e.value);
  }

  exportLayoutClick(e) {
    const { gridRef, layoutKey } = this.props;

    const textToBLOB = new Blob([JSON.stringify(gridRef().state())], { type: 'text/plain' });
    const name = (this.state.selectedLayoutName !== null) ? this.state.selectedLayoutName : layoutKey;
    const sFileName = `${name}.txt`;

    const newLink = document.createElement('a');
    newLink.download = sFileName;
    if (window.webkitURL != null) {
      newLink.href = window.webkitURL.createObjectURL(textToBLOB);
    } else {
      newLink.href = window.URL.createObjectURL(textToBLOB);
      newLink.style.display = 'none';
      document.body.appendChild(newLink);
    }

    newLink.click();
  }

  showHideImportGridLayout(visible) {
    this.setState({
      showImportGridLayout: visible,
    });
  }

  importLayoutClick(e) {
    this.showHideImportGridLayout(true);
  }

  render() {
    return (
      <>
        <div className="layout-wrapper">
          <SelectBox
            ref={this.layoutSelectBoxRef}
            placeholder="Choose Layout..."
            noDataText="(no saved layouts exist)"
            width={200}
            items={this.state.layouts && this.state.layouts}
            valueExpr="Value"
            displayExpr="Text"
            onValueChanged={this.selectedLayoutChanged.bind(this)}
          />
          <Button
            icon="save"
            hint="Save Layout"
            onClick={this.saveLayoutClick}
          />
          <Button
            icon="trash"
            hint="Delete Layout"
            onClick={this.deleteLayoutClick}
          />
          <Button
            icon="clearformat"
            hint="Clear Layout"
            onClick={this.clearLayoutClick}
          />
          <Button
            icon="download"
            hint="Export Layout"
            onClick={this.exportLayoutClick}
          />
          <Button
            icon="upload"
            hint="Import Layout"
            onClick={this.importLayoutClick}
          />
        </div>
        <ImportGridLayoutPopup
          showImportGridLayout={this.state.showImportGridLayout}
          showHideImportGridLayout={this.showHideImportGridLayout}
          loadLayouts={this.loadLayouts}
          layoutKey={this.props.layoutKey}
          applyLayout={this.applyLayout}
        />
      </>
    );
  }
}
export default GridLayout;
