import React from 'react';
import './members.scss';
import { Popup } from 'devextreme-react/popup';
import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  Selection,
  SearchPanel,
  ColumnChooser,
  Grouping,
  GroupPanel,
  FilterPanel,
  FilterRow,
  HeaderFilter,
  SortByGroupSummaryInfo,
  Button,
} from 'devextreme-react/data-grid';
import 'whatwg-fetch';
import notify from 'devextreme/ui/notify';
import EditMemberForm from './EditMemberForm';
import
calcGridHeight,
{
  renderPreferredFirstNameGridCell,
  addMemberBaseViewColumns,
  initMemberBaseViewColumns,
} from '../../helpers/ui';
import AppContext from '../../AppContext';
import {
  CreateAuthorizedDataSource,
  CreateAuthorizedStore,
  loadMembersLookupDataAsync,
  createNewCall,
  disposeMembersLookupData,
} from '../../helpers/data';
import { NameIdentifier } from '../../helpers/miscellaneous';
import { appConst } from '../../AppConst';

import GridLayout from '../../components/grid-layout-component/GridLayout';
import SubImport from '../../components/member-import/SubImport';

const URL = `${window.env.apiEndpoint}/api/v1/Members`;

class Members extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditForm: false,
      addNewMode: false,
      currentFirstName: '',
      showImportForm:false,
    };

    this.editIconClick = this.editIconClick.bind(this);
    this.callIconClick = this.callIconClick.bind(this);
    this.hideEditPopup = this.hideEditPopup.bind(this);
    this.hideEditPopupAndRefreshGrid = this.hideEditPopupAndRefreshGrid.bind(this);
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.windowResized = this.windowResized.bind(this);

    this.dataSource = CreateAuthorizedDataSource(
      {
        loadUrl: `${URL}/Get`,
        deleteUrl: `${URL}/Delete`,
      },null, 'Id',
    );

    this.getMemberPopupRef = this.getMemberPopupRef.bind(this);
    this.setShowImportForm = this.setShowImportForm.bind(this);
  }

  async componentDidMount() {
    window.addEventListener('resize', this.windowResized);
    this.setState({ membersLookupData: await loadMembersLookupDataAsync() });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResized);

    if (this.dataSource) {
      this.dataSource.dispose();
    }

    disposeMembersLookupData(this.state.membersLookupData);
  }

  hideEditPopup() {
    this.setState({
      rowData: {},
      showEditForm: false,
    });
  }

  hideEditPopupAndRefreshGrid() {
    this.hideEditPopup();
    this.membersGrid.instance.refresh()
      .then(() => {
        // Refresh succeeds
      })
      .catch(() => {
        // Error refesh the grid.
      });
  }

  async getMemberById(id) {
    const getMemberByIdStore = CreateAuthorizedStore(
      { loadUrl: `${URL}/GetMemberById` }, { load: { Id: id } },
    );
    getMemberByIdStore.load().then(
      (result) => result[0],
    );
  }

  editIconClick(key) {
    const getMemberByIdStore = CreateAuthorizedStore(
      { loadUrl: `${URL}/GetMemberById` }, { load: { Id: key } },
    );
    getMemberByIdStore.load().then(
      (result) => {
        this.setState({
          showEditForm: true,
          rowData: { ...result[0] },
          addNewMode: false,
          editFormTitle: NameIdentifier(result[0].PreferredFirstName, result[0].FirstName, result[0].LastName),
        });
      },
    );
  }

  callIconClick(e) {
    const { account, callsInProgress, updateContext } = this.context;
    createNewCall(callsInProgress, updateContext, account, e.row.data, 'Member').then(
      (result) => {

      },
      (error) => {
        notify({ message: error, shading: true }, 'error');
      },
    );
  }

  windowResized() {
    this.membersGrid.instance.option('height', calcGridHeight('membersGrid'));
  }

  getMemberPopupRef() {
    return this.editMemberFormPopup && this.editMemberFormPopup.instance;
  }

  renderPopUp(isEditFormShown) {
    if (isEditFormShown) {
      const { showEditForm, addNewMode, rowData, membersLookupData } = this.state;
      return (
        <Popup
          ref={(ref) => { this.editMemberFormPopup = ref; }}
          visible={showEditForm}
          {...appConst.defaultPopupOptions}
          className="editMemberPopup"
          onHiding={(e) => { e.cancel = true; this.refs.editMemberForm.cancelData(); }}
          onShown={(e) => { this.refs.editMemberForm.changeEditFormTitle(); }}
        >
          <EditMemberForm
            ref="editMemberForm"
            rowData={rowData}
            hideEditPopupAndRefreshGrid={this.hideEditPopupAndRefreshGrid}
            hideEditPopup={this.hideEditPopup}
            salutationsData={membersLookupData.salutationsData}
            gendersData={membersLookupData.gendersData}
            pronounsData={membersLookupData.pronounsData}
            emailAddressTypesData={membersLookupData.emailAddressTypesData}
            phoneTypesData={membersLookupData.phoneTypesData}
            addNewMode={addNewMode}
            membershipTypesData={membersLookupData.membershipTypesData}
            employmentStatusesData={membersLookupData.employmentStatusesData}
            contractTypesData={membersLookupData.contractTypesData}
            designationsData={membersLookupData.designationsData}
            ridingsData={membersLookupData.ridingsData}
            getMemberPopupRef={this.getMemberPopupRef}
            provincesData={membersLookupData.provincesData}
          />
        </Popup>
      );
    }

    return (
      <></>
    );
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: () => {
            this.setState({
              showEditForm: true,
              rowData: { EmailAddresses: [], PhoneNumbers: [], Schools: [] },
              addNewMode: true,
              editFormTitle: '(New Member)',
            });
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'upload',
          hint:'upload an excel file to import',
          onClick: () => {
            this.setShowImportForm(true);
          },
        },
      },
    );
  }

  setShowImportForm(v) {
    this.setState({ showImportForm: v });
  }

  render() {
    const { showEditForm, showImportForm } = this.state;
    return (
      <div>
        <SubImport
          visible={showImportForm}
          setShowImportForm={this.setShowImportForm}
        />
        {this.renderPopUp(showEditForm)}
        <div className="title">
          <span>Members</span>
          <GridLayout
            rememberTempLayout={true}
            layoutKey="Members"
            gridName="membersGrid"
            pageName="MembersPage"
            gridRef={
              () => this.membersGrid.instance
            }
          />
        </div>
        <DataGrid
          id="membersGrid"
          ref={(ref) => { this.membersGrid = ref; }}
          dataSource={this.dataSource}
          {...appConst.defaultGridOptions}
          onToolbarPreparing={this.onToolbarPreparing}
          height={() => calcGridHeight('membersGrid')}
          onRowDblClick={(e) => {
            this.editIconClick(e.key);
          }}
          customizeColumns={addMemberBaseViewColumns}
          onInitialized={(e) => { initMemberBaseViewColumns(e); }}
        >
          <Scrolling mode="virtual" rowRenderingMode="virtual" />
          <Selection mode="multiple" />
          <SearchPanel
            visible
            width={240}
            placeholder="Search..."
          />
          <Editing
            mode="popup"
            allowAdding={false}
            allowDeleting
            allowUpdating
          />
          <ColumnChooser
            enabled
            mode="dragAndDrop"
          />
          <Grouping
            allowCollapsing
            autoExpandAll={false}
            contextMenuEnabled
            expandMode="rowClick"
          />
          <GroupPanel
            visible
            allowColumnDragging
          />
          <HeaderFilter
            visible
            allowSearch
          />
          <SortByGroupSummaryInfo
            summaryItem="count"
            sortOrder="desc"
          />
          <FilterRow visible />
          <FilterPanel visible />
          <Column dataField="Id" visible={false} />
          <Column
            dataField="PreferredFirstName"
            caption="Preferred First Name"
            sortOrder="asc"
            sortIndex="1"
            cellRender={(data) => renderPreferredFirstNameGridCell(this, data)}
          />
          <Column
            dataField="LastName"
            caption="Last Name"
            sortOrder="asc"
            sortIndex="0"
          />
          <Column
            dataField="School"
            caption="School"
          />
          <Column dataField="FirstName" caption="First Name" visible={false} />
          <Column dataField="EmailAddress" caption="Email Address" visible={false} />
          <Column dataField="PreferredEmailType" caption="Preferred Email Type" visible={false} />
          <Column dataField="PhoneNumber" caption="Phone Number" visible={false} />
          <Column dataField="PreferredPhoneType" caption="Preferred Phone Type" visible={false} />
          <Column dataField="Division" caption="Division" visible />
          <Column dataField="OtherSchools" caption="Other Schools" visible={false} />
          <Column dataField="TeachingCertificateNumber" caption="Teaching Certificate Number" visible={false} />
          <Column dataField="FullName" caption="Full Name" visible={false} />
          <Column dataField="Ward" caption="Ward" visible={false} />
          <Column dataField="Riding" caption="Riding" visible={false} />
          <Column type="buttons">
            <Button name="callLog" hint="Call Log" onClick={this.callIconClick} icon="tel" />
            <Button name="edit" hint="Edit Member" onClick={(e) => this.editIconClick(e.row.key)} />
            <Button name="delete" />
          </Column>
        </DataGrid>
      </div>
    );
  }
}
Members.contextType = AppContext;
export default Members;
