import React from 'react';
import Tabs from 'devextreme-react/tabs';
import notify from 'devextreme/ui/notify';
import Form, {
  GroupItem,
  SimpleItem,
  Label,
  RequiredRule,
} from 'devextreme-react/form';

import {
  DataGrid,
  Column,
  Editing,
  Selection,
  SearchPanel,
  ColumnChooser,
  Grouping,
  GroupPanel,
  FilterPanel,
  FilterRow,
  HeaderFilter,
  SortByGroupSummaryInfo,
  Button,
  Paging,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import Tooltip from 'devextreme-react/tooltip';
import { CreateAuthorizedDataSource, CreateAuthorizedStore } from '../../../helpers/data';
import
calcGridHeight,
{
  addMemberBaseViewColumns,
  initMemberBaseViewColumns,
  cancelEditFormData,
  setFocusOnEditor,
} from '../../../helpers/ui';
import './EditDesignationForm.scss';

import { appConst } from '../../../AppConst';
import GridLayout from '../../../components/grid-layout-component/GridLayout';

const URL = `${window.env.apiEndpoint}/api/v1/Designations`;

const tabs = [
  {
    id: 0,
    text: 'Designation Information',
    icon: '',
  },
  {
    id: 1,
    text: 'Members',
    icon: 'group',
  },
];

class EditDesignationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabIndex: 0,
      showMemberEditForm: false,
      addNewMode: false,
      currentRowData: JSON.stringify(this.props.rowData),
    };

    this.membersDataSource = CreateAuthorizedDataSource(
      {
        loadUrl: `${URL}/GetAssociatedMembersByDesignation`,
      }, { load: { designationId: this.props.rowData.Id } }, 'MemberDesignationId',
    );

    this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);
    this.editIconClick = this.editIconClick.bind(this);
    this.saveData = this.saveData.bind(this);
    this.cancelData = this.cancelData.bind(this);
    this.setGridHeight = this.setGridHeight.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setGridHeight);
  }

  componentDidMount() {
    window.addEventListener('resize', this.setGridHeight);
  }

  onTabsSelectionChanged(args) {
    const { addNewMode } = this.props;
    if (args.name === 'selectedIndex') {
      if (addNewMode && args.value > 0) {
        notify('Designation is not saved yet.');
      } else {
        this.setState({ selectedTabIndex: args.value });
        this.setGridHeight();
      }
      if (args.value === 0) {
        setFocusOnEditor(this.form.instance, "Description");
      }
    }
  }

  saveButtonOptions = {
    text: 'Save',
    icon: 'save',
    onClick: (e) => {
      this.saveData();
    },
  }

  cancelData() {
    const { showHideDesignationEditForm } = this.props;
    const { currentRowData } = this.state;

    const editedData = JSON.stringify(this.form.instance.option('formData'));
    cancelEditFormData(currentRowData, editedData, this.saveData, () => { showHideDesignationEditForm(false); });
  }

  saveData() {
    const { addNewMode, refreshDesignationGrid, showHideDesignationEditForm } = this.props;
    const v = this.form.instance.validate();
    if (!v.isValid) return false;

    const data = this.form.instance.option('formData');
    const saveDesignationData = CreateAuthorizedStore(
      { updateUrl: `${URL}/Put`, insertUrl: `${URL}/Post` }, null, 'Id',
    );

    if (!addNewMode) {
      saveDesignationData.update(data.Id, data).then(
        (result) => {
          refreshDesignationGrid();
          showHideDesignationEditForm(false);
        },
        (error) => {
          notify({ message: error.message, shading: true }, 'error');
        },
      );
    } else {
      saveDesignationData.insert(data).then(
        (result) => {
          refreshDesignationGrid();
          showHideDesignationEditForm(false);
        },
        (error) => {
          notify({ message: error.message, shading: true }, 'error');
        },
      );
    }
    return false;
  }

  refreshGrid() {
    this.membersGrid.instance.refresh();
  }

  editIconClick(key) {
    this.props.setShowMemberEditFormValue(true, key);
  }

  setGridHeight() {
    this.membersGrid.instance.option('height', calcGridHeight('membersGrid'));
  }

  render() {
    const { selectedTabIndex } = this.state;
    const { addNewMode } = this.props;
    return (
      <>
        <Tabs
          dataSource={tabs}
          selectedIndex={selectedTabIndex}
          onOptionChanged={this.onTabsSelectionChanged}
        />
        <div className={(selectedTabIndex === 0) ? 'tab-container-visible' : 'tab-container-invisible'}>
          <Form
            ref={(ref) => { this.form = ref; }}
            scrollingEnabled
            formData={this.props.rowData}
            {...appConst.defaultFormOptions}
          >
            <GroupItem caption=" " colCount={3}>
              <SimpleItem editorType="dxTextBox" dataField="Description">
                <Label text="Description" />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem editorType="dxCheckBox" dataField="Current" editorOptions={{ onContentReady: (e) => { if (addNewMode) e.component.option('value', true); } }}>
                <Label text="Current" />
              </SimpleItem>
              <SimpleItem editorType="dxCheckBox" dataField="Common" editorOptions={{ onContentReady: (e) => { if (addNewMode) e.component.option('value', true); } }}>
                <Label text="Common" />
              </SimpleItem>
              <SimpleItem
                itemType="button"
                horizontalAlignment="left"
                verticalAlignment="bottom"
                buttonOptions={this.saveButtonOptions}
              />
            </GroupItem>
          </Form>
        </div>
        <div className={(selectedTabIndex === 1) ? 'tab-container-visible' : 'tab-container-invisible'}>
          <GridLayout
            layoutKey="Members"
            gridName="membersGrid"
            pageName="EditDesignationPage"
            gridRef={
              () => this.membersGrid.instance
            }
          />
          <DataGrid
            id="membersGrid"
            ref={(ref) => { this.membersGrid = ref; }}
            dataSource={this.membersDataSource}
            {...appConst.defaultGridOptions}
            customizeColumns={addMemberBaseViewColumns}
            onRowDblClick={(e) => {
              this.editIconClick(e.data.Id);
            }}
            onInitialized={(e) => {
              initMemberBaseViewColumns(e);
            }}
          >
            <Paging />
            <Selection mode="multiple" />
            <SearchPanel
              visible
              width={240}
              placeholder="Search..."
            />
            <Editing
              mode="popup"
              allowAdding={false}
              allowDeleting
              allowUpdating
            />
            <ColumnChooser
              enabled
              mode="dragAndDrop"
            />
            <Grouping
              allowCollapsing
              autoExpandAll={false}
              contextMenuEnabled
              expandMode="rowClick"
            />
            <GroupPanel
              visible
              allowColumnDragging
            />
            <HeaderFilter
              visible
              allowSearch
            />
            <SortByGroupSummaryInfo
              summaryItem="count"
              sortOrder="desc"
            />
            <FilterRow visible />
            <FilterPanel visible />
            <Column
              dataField="LastName"
              caption="Last Name"
              dataType="string"
              sortIndex="0"
              sortOrder="asc"
            />
            <Column
              dataField="PreferredFirstName"
              caption="Preferred First Name"
              dataType="string"
              sortIndex="1"
              sortOrder="asc"
            />
            <Column dataField="DesignationStartDate" caption="Start Date" dataType="datetime" format={appConst.dateDisplayFormat} />
            <Column dataField="DesignationEndDate" caption="End Date" dataType="datetime" format={appConst.dateDisplayFormat} />
            <Column dataField="EmailAddress" caption="Email Address" dataType="string" />
            <Column dataField="PhoneNumber" caption="Phone Number" dataType="string" />
            <Column dataField="PrimarySchoolName" caption="Primary School" dataType="string" visible={false} />
            <Column type="buttons">
              <Button name="edit" hint="Edit Member" onClick={(e) => this.editIconClick(e.row.data.Id)} />
            </Column>
            <Summary>
              <TotalItem
                column="PreferredFirstName"
                summaryType="count"
              />
            </Summary>
          </DataGrid>
          <Tooltip ref={(ref) => { this.tooltip = ref; }} closeOnOutsideClick position="left">
            <div>{this.state.currentFirstName}</div>
          </Tooltip>
        </div>
      </>
    );
  }
}

export default EditDesignationForm;
