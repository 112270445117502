export function NameIdentifier(preferredName, firstName, lastName) {
	preferredName = (preferredName) ? preferredName : '';
	firstName = (firstName) ? firstName : '';
	lastName = (lastName) ? lastName : '';

	if (preferredName !== '' ) {
		return `${preferredName} ${lastName}`;
	}
	else {
		return `${firstName} ${lastName}`
	}
} 