import React from "react";
import AppContext from "../../AppContext";
import ContextMenu from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import "./user-panel.scss";

export default class UserPanel extends React.Component {
    static contextType = AppContext;

    render() {
        const { menuMode, menuItems } = this.props;
        return (
            <div className={"user-panel"}>
                <div className={"user-info"}>
                    <div className={"image-container"}>
                    </div>
                    <div className={"user-name"}>{this.context.account.name}</div>
                </div>

                {menuMode === "context" && (
                    <ContextMenu
                        items={menuItems}
                        target={".user-button"}
                        showEvent={"dxclick"}
                        width={170}
                        position={{ my: "top center", at: "bottom center" }}
                        cssClass={"user-menu"}
                    />
                )}
                {menuMode === "list" && (
                    <List className={"dx-toolbar-menu-action"} items={menuItems} />
                )}
            </div>
        );
    }
}
