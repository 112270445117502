import React, { Component } from "react";
import {
  msalApp,
  fetchMsGraph,
  acquireToken,
  isIE,
  GRAPH_ENDPOINTS,
  GRAPH_REQUESTS
} from "./utils/auth-utils";
import AppContext from "./AppContext";
import { CreateAuthorizedStore } from './helpers/data'

// If you support IE, our recommendation is that you sign-in using Redirect APIs
const useRedirectFlow = isIE();

const URL = `${window.env.apiEndpoint}/api/v1/CallLogs`;

const callsInProgressData = CreateAuthorizedStore(
  { loadUrl: `${URL}/GetCallsInProgress` },
  { load: { timeZone: window.env.timeZone } },
  "Id"
)

export default C =>
  class AppProvider extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loggedIn: false,
        account: null,
        error: null,
        graphProfile: null,
        onSignIn: () => this.onSignIn(useRedirectFlow),
        onSignOut: () => this.onSignOut(),
        callsInProgress: []
      };
    }

    updateContext = (newContext) => {
      this.setState(newContext);
    }

    async onSignIn(redirect) {
      if (redirect) {
        return msalApp.loginRedirect(GRAPH_REQUESTS.LOGIN);
      }

      const loginResponse = await msalApp
        .loginPopup(GRAPH_REQUESTS.LOGIN)
        .catch(error => {
          this.updateContext({
            error: error.message
          });
        });

      if (loginResponse) {
        this.updateContext({
          loggedIn: true,
          account: loginResponse.account,
          error: null
        });

        callsInProgressData.load().then(
          (data) => {
            this.updateContext({ callsInProgress: data });
          }
        )

        const tokenResponse = await acquireToken(
          GRAPH_REQUESTS.LOGIN
        ).catch(error => {
          this.updateContext({
            error: error.message
          });
        });

        if (tokenResponse) {
          const graphProfile = await fetchMsGraph(
            GRAPH_ENDPOINTS.ME,
            tokenResponse.accessToken
          ).catch(() => {
            this.updateContext({
              error: "Unable to fetch Graph profile."
            });
          });

          if (graphProfile) {
            this.updateContext({
              graphProfile
            });
          }
        }
      }
    }

    onSignOut() {
      msalApp.logout();
    }

    async componentDidMount() {
      const accounts = msalApp.getAllAccounts();
      let account = null;
      if (accounts && accounts.length > 0)
        account = accounts[0];

      this.updateContext({
        loggedIn: account ? true : false,
        account
      });

      if (account) {
        callsInProgressData.load().then(
          (data) => {
            this.updateContext({ callsInProgress: data });
          }
        );
      }
    }

    render() {
      return (
        <AppContext.Provider value={{ ...this.state, updateContext: this.updateContext }}>
          <C
            {...this.props}
          />
        </AppContext.Provider>
      );
    }
  };
