import React, { useEffect } from 'react';
import {
  DataGrid,
  Column,
  Editing,
  Texts,
  Lookup,
  HeaderFilter,
} from 'devextreme-react/data-grid';
import {
  CreateAuthorizedDataSource,
} from '../../helpers/data';
import ExternalMailSystem from '../external-mail-system/ExternalMailSystem';
import { appConst } from '../../AppConst';
import { Fragment } from 'react';

export default function (props) {
  const emailAddressTypesData = CreateAuthorizedDataSource(
    { loadUrl: `${window.env.apiEndpoint}/api/v1/Members/EmailAddressTypesLookup` },
    null,
    'Value', false,
  );

  const divisionDistributionListData = CreateAuthorizedDataSource(
    {
      loadUrl: `${window.env.apiEndpoint}/api/v1/Divisions/GetDivisionDistributionLists`,
      insertUrl: `${window.env.apiEndpoint}/api/v1/Divisions/PostDivisionDistributionList`,
      updateUrl: `${window.env.apiEndpoint}/api/v1/Divisions/PutDivisionDistributionList`,
      deleteUrl: `${window.env.apiEndpoint}/api/v1/Divisions/DeleteDivisionDistributionList`,
    },
    {
      load: { divisionId: props.divisionId },
      insert: { divisionId: props.divisionId },
      update: { divisionId: props.divisionId },
    },
    'Id', false,
  );

  useEffect(() => {
    (async () => {
    })()
  }, [])

  return (
    <Fragment>
      <DataGrid
        dataSource={divisionDistributionListData}
        key="Id"
        {...appConst.defaultGridOptions}
        onRowUpdating={
          (e) => {
            e.newData = { ...e.oldData, ...e.newData };
          }
        }
      >
        <HeaderFilter
          visible
          allowSearch
        />
        <Editing
          mode="row"
          allowAdding
          allowDeleting
          allowUpdating
        >
          <Texts
            confirmDeleteMessage={"Are you sure to delete this division distribution list."} />
        </Editing>
        <Column
          dataField="EmailAddressTypeId"
          dataType="number"
          caption="Email Address Type"
        >
          <Lookup
            dataSource={emailAddressTypesData.store()}
            valueExpr="Value"
            displayExpr="Text"
          />
        </Column>
        <Column
          dataField="Name"
          dataType="text"
          caption="Name"
          allowEditing={false} />
        <Column
          dataField="EmailAddress"
          dataType="text"
          caption="Email Address"
          allowEditing={false}
        >
        </Column>
        <Column
          dataField="StatusMessage"
          cellRender={
            (data) => {
              const row = data.data;
              return <ExternalMailSystem statusMessage={row.StatusMessage} hasError={row.HasError} entityId={row.Id} entityType="DivisionDistributionList" />
            }
          }
        />
        <Column
          type="buttons"
          width={110}
          buttons={[
            {
              name: "delete",
              hint: "Delete division Distribution List",
              icon: 'trash',
            },
            {
            name: "edit",
            hint:"Edit division Distribution List",
            icon: 'edit',
          }]}
        />
      </DataGrid>
    </Fragment>
  );
}