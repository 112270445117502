import React from 'react';
import { Popup } from 'devextreme-react';
import {
  CreateAuthorizedDataSource,
  CreateAuthorizedStore,
  getMemberById,
} from '../../helpers/data';
import { appConst } from '../../AppConst';
import EditSchoolForm from './EditSchoolForm';
import AddMemberPopup from './AddMemberPopup';
import EditMemberPopup from '../members/EditMemberPopup';

const schoolsUrl = `${window.env.apiEndpoint}/api/v1/Schools`;

class EditSchoolPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
    };

    this.ridingsData = CreateAuthorizedDataSource(
      { loadUrl: `${window.env.apiEndpoint}/api/v1/Ridings/RidingsLookup` },
      null,
      'Value',
      null,
      'Header',
    );
    this.provincesData = CreateAuthorizedStore(
      { loadUrl: `${schoolsUrl}/provincesLookup` },
      null,
      'Value',
    );

    this.setShowMemberEditFormValue = this.setShowMemberEditFormValue.bind(this);
    this.setShowMemberAddNewFormValue = this.setShowMemberAddNewFormValue.bind(this);

    this.hideMemberEditPopupAndRefreshGrid = this.hideMemberEditPopupAndRefreshGrid.bind(this);
    this.hideMemberEditPopup = this.hideMemberEditPopup.bind(this);
    this.hideMemberAddNewPopupAndRefreshGrid = this.hideMemberAddNewPopupAndRefreshGrid.bind(this);
    this.hideMemberAddNewPopup = this.hideMemberAddNewPopup.bind(this);
  }

  setShowMemberAddNewFormValue(value) {
    this.setState({
      showMemberAddNewForm: value,
    });
  }

  setShowMemberEditFormValue(value, memberkey, memberAddNewMode) {
    const { rowData } = this.props;

    if (!memberAddNewMode) {
      this.setState({ showLoadingPanel: true });
      getMemberById(memberkey).then(
        (result) => {
          this.setState({
            showMemberEditForm: value,
            rowMemberData: result,
            showLoadingPanel: false,
            memberAddNewMode,
          });
        },
      );
    } else {
      this.setState({
        showMemberEditForm: value,
        rowMemberData: { EmailAddresses: [], PhoneNumbers: [], Schools: [{ SchoolId: rowData.Id, Primary: true }] },
        memberAddNewMode,
      });
    }
  }

  hideMemberEditPopupAndRefreshGrid() {
    this.hideMemberEditPopup();
    this.refs.editSchoolForm.refreshGrids();
  }

  hideMemberEditPopup() {
    this.setState({
      showMemberEditForm: false,
    });
  }

  hideMemberAddNewPopupAndRefreshGrid() {
    this.hideMemberAddNewPopup();
    this.refs.editSchoolForm.refreshGrids();
  }

  hideMemberAddNewPopup() {
    this.setState({
      showMemberAddNewForm: false,
    });
  }

  renderMemberEditPopUp(isEditFormShown) {
    const { rowMemberData, memberAddNewMode } = this.state;
    if (isEditFormShown) {
      const { showMemberEditForm } = this.state;
      return (
        <EditMemberPopup
          showMemberEditForm={showMemberEditForm}
          rowMemberData={rowMemberData}
          memberAddNewMode={memberAddNewMode}
          hideMemberEditPopup={this.hideMemberEditPopup}
          hideMemberEditPopupAndRefreshGrid={this.hideMemberEditPopupAndRefreshGrid}
        />
      );
    }
  }

  renderMemberAddNewPopUp(isEditFormShown) {
    if (isEditFormShown) {
      const { showMemberAddNewForm } = this.state;
      const { rowData } = this.props;
      return (
        <AddMemberPopup
          showAddMemberPopup={showMemberAddNewForm}
          setShowMemberAddNewFormValue={this.setShowMemberAddNewFormValue}
          schoolId={rowData.Id}
          hideMemberAddNewPopupAndRefreshGrid={this.hideMemberAddNewPopupAndRefreshGrid}
          setShowMemberEditFormValue={this.setShowMemberEditFormValue}
        />
      );
    }
  }

  render() {
    const {
      showMemberEditForm,
      showMemberAddNewForm,
      memberAddNewMode,
    } = this.state;

    const {
      showEditForm,
      title,
      rowData,
      showHideSchoolEditForm,
      refreshMembersGrid,
    } = this.props;

    return (
      <>
        {this.renderMemberEditPopUp(showMemberEditForm)}
        {this.renderMemberAddNewPopUp(showMemberAddNewForm)}
        <Popup
          visible={showEditForm}
          onHiding={(e) => {
            e.cancel = true;
            this.refs.editSchoolForm.cancelData();
          }}
          {...appConst.defaultPopupOptions}
          title={title}
        >
          <EditSchoolForm
            ref="editSchoolForm"
            rowData={rowData}
            ridingsData={this.ridingsData}
            provincesData={this.provincesData}
            setShowMemberEditFormValue={this.setShowMemberEditFormValue}
            setShowMemberAddNewFormValue={this.setShowMemberAddNewFormValue}
            showHideSchoolEditForm={showHideSchoolEditForm}
            refreshSchoolGrid={refreshMembersGrid}
            addNewMode={memberAddNewMode}
          />
        </Popup>
      </>
    );
  }
}
export default EditSchoolPopup;
