import React from 'react';
import {
    DataGrid, Column, Editing, Scrolling, Selection, Lookup
} from 'devextreme-react/data-grid';
import
calcGridHeight
    from '../../../helpers/ui';
import { CreateAuthorizedDataSource } from '../../../helpers/data'
import Tabs from 'devextreme-react/tabs';
import Form, { GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import { CreateAuthorizedStore } from '../../../helpers/data'
import { appConst } from '../../../AppConst';

const rolesUrl = `${window.env.apiEndpoint}/api/v1/Roles`;
const permissionRolesUrl = `${window.env.apiEndpoint}/api/v1/PermissionRoles`;


const rolesData = CreateAuthorizedStore(
    { loadUrl: `${rolesUrl}/Get` }, null, "Id"
)

const tabs = [
    {
        id: 0,
        text: 'Permission',
        icon: 'key',
    },
    {
        id: 1,
        text: 'Roles',
        icon: 'group',
    },
];

class EditPermissionForm extends React.Component {
    constructor(props) {
        super(props);

        this.windowResized = this.windowResized.bind(this);
        this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);
        const { permissionId } = this.props;
        this.state = {
            selectedTabIndex: 0,
            permissionId,
        };
        this.permissionRolesDataSource = this.permissionRolesDataSource.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.windowResized);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResized);
        if (this.dataSource) {
            this.dataSource.dispose();
        }
    }

    windowResized() {
        this.setGridHeight();
    }

    onTabsSelectionChanged(args) {
        if (args.name === 'selectedIndex') {
            this.setState({
                selectedTabIndex: args.value,
            });
            this.setGridHeight();
        }
    }

    permissionRolesDataSource() {
        const { permissionId } = this.state;

        return CreateAuthorizedDataSource(
            {
                'loadUrl': `${permissionRolesUrl}/GetPermissionRoles`,
                'insertUrl': `${permissionRolesUrl}/PostRolePermission`,
                'updateUrl': `${permissionRolesUrl}/PutRolePermission`,
                'deleteUrl': `${permissionRolesUrl}/DeleteRolePermission`
            },
            { 'load': { 'permissionId': permissionId }, 'insert': { 'permissionId': permissionId } },
            ["RoleId", "PermissionId"], false);
    }

    setGridHeight() {
        this.rolesGrid.instance.option('height', calcGridHeight('rolesGrid'));
    }

    render() {
        const { selectedTabIndex, permissionId } = this.state;
        const { rowData } = this.props;
        return (
            <>
                <Tabs
                    dataSource={tabs}
                    selectedIndex={this.state.selectedTabIndex}
                    onOptionChanged={this.onTabsSelectionChanged}
                />
                <div style={{ display: (selectedTabIndex === 0) ? 'block' : 'none' }}>
                    <Form
                        ref={(ref) => { this.permissionForm = ref; }}
                        formData={rowData}
                        {...appConst.defaultFormOptions}
                    >
                        <GroupItem caption=" " colCount={2}>
                            <SimpleItem editorType="dxTextBox" dataField="Name" editorOptions={{ readOnly: true }}>
                                <Label text="Name" />
                            </SimpleItem>
                            <SimpleItem editorType="dxTextBox" dataField="Description" editorOptions={{ readOnly: true }}>
                                <Label text="Description" />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </div>
                <div style={{ display: (selectedTabIndex === 1) ? 'block' : 'none' }}>
                    <DataGrid
                        id="rolesGrid"
                        key={["RoleId", "PermissionId"]}
                        ref={(ref) => { this.rolesGrid = ref; }}
                        dataSource={this.permissionRolesDataSource()}
                        {...appConst.defaultGridOptions}
                        onInitNewRow={(e) => { e.data.PermissionId = permissionId }}
                    >
                        <Selection mode="multiple" />
                        <Scrolling
                            mode="virtual"
                            rowRenderingMode="virtual"
                        />
                        <Editing
                            mode="row"
                            allowAdding
                            allowDeleting
                            allowUpdating
                        />
                        <Column dataField="RoleId">
                            <Lookup dataSource={rolesData} valueExpr="Id" displayExpr="Name" />
                        </Column>
                    </DataGrid>
                </div>
            </>
        );
    }
}

export default EditPermissionForm;
