import React from 'react';
import Tooltip from "devextreme-react/tooltip"
import { appConst } from '../AppConst';
import { custom } from 'devextreme/ui/dialog';
import { NameIdentifier } from '../helpers/miscellaneous';
import moment from 'moment';

function calcGridHeight(gridId) {
  var grid = document.getElementById(gridId);
  if (null == grid) return 0;
  var containerOffset = offset(document.getElementsByClassName("content")[0]);
  return Math.max(window.innerHeight - containerOffset.top - document.getElementById(gridId).offsetTop, 50) - 25;
}

function offset(el) {
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft, height: rect.height, width: rect.width }
}

export function addMemberBaseViewColumns(e) {
  let cols = [...appConst.memberBaseViewColumns];
  //We add only the columns of base view that are not already added.
  for (let c of cols) {
    const found = e.find(cl => cl.dataField === c.dataField);
    if (!found) {
      e.push(c);
      c.allowEditing = false;
    }
  }
}

export function initMemberBaseViewColumns(e) {
  var columns = e.component.option("columns");
  let cols = [...appConst.memberBaseViewColumns];
  //We add only the columns of base view that are not already added.
  for (let c of cols) {
    const found = columns.find(cl => cl.dataField === c.dataField);
    if (!found) {
      columns.push(c);
      c.allowEditing = false;
    }
  }
  e.component.option("columns", columns);
}

export default calcGridHeight;

export function setPreferredPhoneNumber(index, formRef) {
  let formData = formRef.option('formData');
  let phoneNumbers = formData.PhoneNumbers;
  if (phoneNumbers[index].Preferred) {
    for (var i = 0; i < phoneNumbers.length; i++) {
      if (i !== index)
        phoneNumbers[i].Preferred = false;
    }
    formData.PhoneNumbers = phoneNumbers;
    formRef.option('formData', formData);
  }
}

export function setPreferredEmailAddress(index, formRef) {
  let formData = formRef.option('formData');
  let emailAddresses = formData.EmailAddresses;
  if (emailAddresses[index].Preferred) {
    for (var i = 0; i < emailAddresses.length; i++) {
      if (i !== index)
        emailAddresses[i].Preferred = false;
    }
    formData.EmailAddresses = emailAddresses;
    formRef.option('formData', formData);
  }
}

function createConfirmationSaveDialog(saveData, hideEditPopup) {
  let myDialog = custom({
    title: appConst.confirmCancelDialogTitle,
    messageHtml: "<b>Data has been changed.  Do you want to save?</b>",
    buttons: [{
      text: "Yes",
      onClick: (e) => {
        saveData(true);
      }
    },
    {
      text: "No",
      onClick: (e) => {
        hideEditPopup();
      }
    },
    {
      text: "Cancel",
      onClick: (e) => {
      }
    }
    ]
  });
  return myDialog;
}

export function cancelEditFormData(oldData, currentData, saveData, hideEditPopup) {
  if (oldData !== currentData) {
    let myDialog = createConfirmationSaveDialog(saveData, hideEditPopup);
    myDialog.show();
  }
  else {
    hideEditPopup();
  }
}

export function changePersonEditFormTitle(addNewMode, popup, formData, formScrollView) {
  if (addNewMode && '' === popup.option('title')) {
    popup.option('title', '(New Member)');
  } else {
    let preferedName = formData.PreferredFirstName;
    let firstName = formData.FirstName;
    let lastName = formData.LastName;
    var tmp = formScrollView.scrollTop();
    let title = NameIdentifier(preferedName, firstName, lastName);
    popup.option('title', title);
    formScrollView.scrollTo(tmp)
  }
}

export function setDateInlineEditing(dataFields, e) {
  if (dataFields.includes(e.dataField) && e.parentType === "dataRow") {
    e.editorOptions = { ...e.editorOptions, ...appConst.defaultSelectDateBoxOptions }
  }
}

export function setDateTimeInlineEditing(dataFields, e) {
  if (dataFields.includes(e.dataField) && e.parentType === "dataRow") {
    e.editorOptions = { ...e.editorOptions, ...appConst.defaultSelectDateTimeBoxOptions }
  }
}

export function renderPreferredFirstNameGridCell(page, data) {
  return (
    <>
      <div id={`div${data.data.Id}`}
        onMouseEnter={() => {
          if (data.data.PreferredFirstName !== data.data.FirstName) {
            page[`tooltipRef${data.data.Id}`].instance.option('visible', true);
          }
        }}
        onMouseLeave={() => {
          page[`tooltipRef${data.data.Id}`].instance.option('visible', false);
        }}
      >{data.data.PreferredFirstName}
      </div>
      <Tooltip
        ref={ref => page[`tooltipRef${data.data.Id}`] = ref}
        target={`#div${data.data.Id}`}
        position='left'
      >
        <div>{data.data.FirstName}</div>
      </Tooltip>
    </>
  )
}

export function updateAddressFields(addressObj, currentState, provinces) {
   const province = provinces && provinces.find(p => p.Text === addressObj.province);
   addressObj.provinceId = province && province.Value;

  const newState = { ...currentState };
  newState.Address1 = addressObj.address;
  newState.City = addressObj.city;
  newState.ProvinceId = addressObj.provinceId;
  newState.PostalCode = addressObj.postalCode;
  newState.AddressVerified = true;
  return newState;
}

export function setFocusOnEditor(form, dataField) {
  const editor = form.getEditor(dataField)
  if (typeof (editor) !== 'undefined')
    editor.focus();
}

export function setFocusOnFirstInput(formEl) {
  setTimeout(() => {
    const firstInput = formEl.querySelector('select, textarea, input:not([type="hidden"]');
    firstInput && firstInput.focus && firstInput.focus();
  }, 100);
}

function iSAddressVerified(data, storedAddress) {
  if (storedAddress.addressVerified) {
    if (data.City.toUpperCase() === storedAddress.city.toUpperCase()
      && data.ProvinceId === storedAddress.provinceId
      && data.PostalCode.toUpperCase() === storedAddress.postalCode.toUpperCase()
      && data.Address1.toUpperCase() === storedAddress.address1.toUpperCase()
    ) {
      return true
    } else {
      return false
    }
  } else {
    return false;
  }
}

export function compareAddresses(e, data, storedAddress, setAddressVerified) {
  if (e.component.NAME==="dxTextBox") {
    e.component.option("value", e.value.toUpperCase());
  }
  if (typeof e.event !== 'undefined') {
    if (iSAddressVerified(data, storedAddress)) {
      setAddressVerified(true);
    } else {
      setAddressVerified(false);
    }
  }
}

export function getStoredAddress(data) {
  const obj= {
    addressVerified: data.AddressVerified,
    city: data.City && data.City.toUpperCase(),
    provinceId: data.ProvinceId,
    postalCode: data.PostalCode && data.PostalCode.toUpperCase(),
    address1: data.Address1 && data.Address1.toUpperCase(),
  }
  return obj;
}

export function setStoredAddress(addressObj) {
  const obj={
    addressVerified: true,
    city: addressObj.city,
    provinceId: addressObj.provinceId,
    postalCode: addressObj.postalCode,
    address1: addressObj.address,
  }
  return obj;
}

export function convertToTZ(date) {
  if (date != null) {
    const dt = moment.utc(date);
    const cdt = new Date(dt).toLocaleString("en-CA", {
      timeZone: window.env.timeZone,
      timeStyle: "short",
      hour12: true,
      dateStyle: "short",
    });
    return new Date(cdt.toUpperCase().replaceAll(".","").replace(",",""));
  }
}