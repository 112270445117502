import React from 'react';
import Form, {
  GroupItem, SimpleItem, Label, EmptyItem,
} from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';
import { appConst } from '../../AppConst';

class FileDialogPopup extends React.Component {
  uploadFileOkButtonOptions = {
    text: 'Ok',
    icon: 'add',
    width: '150',
    onClick: () => {
      const {
        setReplaceValue, uploadRef, onFileUploaded, file, gridRef,
      } = this.props;
      const newFileName = this.fileUploadForm.instance.getEditor('NewFileName').option('value');
      const newFileNameOptionsValue = this.fileUploadForm.instance.getEditor('NewFileNameOptions').option('value');
      const op = this.uploadFileRadioGroupOptions().indexOf(newFileNameOptionsValue);
      let newFile;
      if (op === 0) {
        newFile = new File([file], newFileName);
        setReplaceValue(false);
      } else {
        newFile = file;
        setReplaceValue(true);
      }
      this.props.uploadRef.onFileUploaded = () => {
        onFileUploaded(gridRef, uploadRef, newFileName);
      };
      uploadRef._changeValue([newFile]);
      uploadRef._uploadFiles();
    },
  }

  uploadFileCancelButtonOptions = {
    text: 'Cancel',
    icon: 'revert',
    width: '150',
    onClick: () => {
      const { uploadRef, hideFileDialogPopup } = this.props;
      uploadRef.reset();
      hideFileDialogPopup();
    },
  }

  uploadFileRadioGroupOptions = () => ["Rename the file I'm uploading", `Replace the existing file "${this.props.newFileName}" created on ${this.props.fileCreationDate}`]

  render() {
    return (
      <Popup
        ref={(ref) => { this.fileDialogPopup = ref; }}
        visible={this.props.showFileNameDialog}
        {...appConst.defaultPopupOptions}
        height="auto"
        width={window.innerWidth * 0.6}
        showTitle={false}
      >
        <span>
          This member already has an attachment named "
          {this.props.newFileName}
          ". What do you want to do?
        </span>
        <Form ref={(ref) => { this.fileUploadForm = ref; }}>
          <GroupItem caption=" " colCount={2}>
            <SimpleItem
              editorType="dxRadioGroup"
              dataField="NewFileNameOptions"
              editorOptions={{
                items: this.uploadFileRadioGroupOptions(),
                value: this.uploadFileRadioGroupOptions()[0],
                onValueChanged: (e) => {
                  const op = this.uploadFileRadioGroupOptions().indexOf(e.value);
                  if (op === 0) { this.fileUploadForm && this.fileUploadForm.instance.getEditor('NewFileName').option('disabled', false); } else { this.fileUploadForm && this.fileUploadForm.instance.getEditor('NewFileName').option('disabled', true); }
                },
              }}
            />
            <SimpleItem editorType="dxTextBox" dataField="NewFileName" editorOptions={{ value: this.props.newFileName, disabled: false }}>
              <Label text="New File Name" />
            </SimpleItem>
            <EmptyItem />
            <GroupItem caption=" " colCount={2} colSpan={3}>
              <SimpleItem
                itemType="button"
                horizontalAlignment="center"
                verticalAlignment="top"
                buttonOptions={this.uploadFileOkButtonOptions}
              />
              <SimpleItem
                itemType="button"
                horizontalAlignment="center"
                verticalAlignment="top"
                buttonOptions={this.uploadFileCancelButtonOptions}
              />
            </GroupItem>
          </GroupItem>
        </Form>
      </Popup>
    );
  }
}

export default FileDialogPopup;
