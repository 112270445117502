import React from 'react';
import './SchoolYears.scss';
import {
  DataGrid, Column, Editing, Scrolling, Selection, SearchPanel
} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import CurrentSwitch from '../../../components/current-switch/current-switch';
import EditSchoolYear from './EditSchoolYear';
import
calcGridHeight,
{ setDateInlineEditing }
  from '../../../helpers/ui';
import { CreateAuthorizedDataSource } from '../../../helpers/data';
import { appConst } from '../../../AppConst';
import { LoadPanel } from 'devextreme-react/load-panel';

const URL = `${window.env.apiEndpoint}/api/v1/SchoolYears`;

class SchoolYears extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showEditPopup: false,
      rowData: {},
    };

    this.toolbarItemRender = this.toolbarItemRender.bind(this);
    this.windowResized = this.windowResized.bind(this);
    this.handleAddEditRow = this.handleAddEditRow.bind(this);

    this.dataSource = new CreateAuthorizedDataSource({
      loadUrl: `${URL}/Get`,
      insertUrl: `${URL}/Post`,
      updateUrl: `${URL}/Put`,
      deleteUrl: `${URL}/Delete`,
    }, null, 'Id');

    this.schoolYearsDataSource = new CreateAuthorizedDataSource({
      loadUrl: `${URL}/Get`,
    }, null, 'Id', false);
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResized);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResized);
    if (this.dataSource) {
      this.dataSource.dispose();
    }
  }

  toolbarItemRender() {
    return (
      <CurrentSwitch grid={this.schoolYearsGrid} />
    );
  }

  windowResized() {
    this.schoolYearsGrid.instance.option('height', calcGridHeight('schoolYearsGrid'));
  }

  onEditorPreparing = (e) => {
    setDateInlineEditing(['StartDate', 'EndDate'], e);
  }

  handleAddEditRow(showEditPopup, data, addNewMode) {
    this.setState({
      showEditPopup: showEditPopup,
      rowData: data,
      addNewMode: addNewMode,
    });
  }

  render() {
    const { showEditPopup, rowData, addNewMode } = this.state;
    return (
      <>
        <EditSchoolYear
          addNewMode={addNewMode}
          rowData={rowData}
          visible={showEditPopup}
          hidePopup={
            () => {
              this.setState({ showEditPopup: false })
            }
          }
          refreshGrid={
            () => {
              this.schoolYearsGrid.instance.refresh();
            }
          }
        />
        <div className="title">
          <span>School Years</span>
        </div>
        <LoadPanel
          visible={this.state.showLoadingPanel}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
        <DataGrid
          id="schoolYearsGrid"
          ref={(ref) => { this.schoolYearsGrid = ref; }}
          dataSource={this.dataSource}
          {...appConst.defaultAdminGridOptions}
          onToolbarPreparing={
            (e) => {
              e.toolbarOptions.items.unshift(
                {
                  location: 'before',
                  template: 'switchCurrent',
                },
                {
                  location: 'after',
                  widget: 'dxButton',
                  options: {
                    icon: 'add',
                    onClick: () => {
                      this.handleAddEditRow(true, {}, true);
                    },
                  },
                },
              );
            }
          }
          height={() => calcGridHeight('schoolYearsGrid')}
          onEditorPreparing={this.onEditorPreparing}
          onRowDblClick={(e) => {
            this.handleAddEditRow(true, e.data, false);
          }}
        >
          <Selection mode="multiple" />
          <SearchPanel
            visible
            width={240}
            placeholder="Search..."
          />
          <Scrolling
            mode="virtual"
            rowRenderingMode="virtual"
          />
          <Editing
            mode="row"
            allowAdding={false}
            allowDeleting
            allowUpdating
          />
          <Column
            dataField="Current"
            filterValue
            dataType="boolean"
          />
          <Column
            dataField="StartDate"
            dataType="date"
            format={appConst.dateDisplayFormat}
            sortIndex={0}
            sortOrder="desc"
          />
          <Column
            dataField="EndDate"
            dataType="date"
            format={appConst.dateDisplayFormat}
          />
          <Column
            dataField="Description"
            dataType="string"
            allowEditing={false}
          />
          <Column
            type="buttons"
            width={110}
            buttons={['delete',
              {
                icon: 'edit',
                hint: 'Edit school year',
                onClick: (e) => {
                  this.handleAddEditRow(true, e.row.data, false);
                }
              },
            ]}
          />
          <Template name="switchCurrent" render={this.toolbarItemRender} />
        </DataGrid>
      </>
    );
  }
}
export default SchoolYears;
