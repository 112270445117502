import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { TextBox } from 'devextreme-react/text-box';
import { FileUploader } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import { SetAuthorizationHeaderAsync } from '../../helpers/data';

const gridLayoutsUrl = `${window.env.apiEndpoint}/api/v1/GridLayouts`;

class ImportGridLayoutPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      layoutName: '',
    };
    this.layoutNameOnValueChanged = this.layoutNameOnValueChanged.bind(this);
  }

  layoutNameOnValueChanged(e) {
    this.setState({
      layoutName: e.value,
    });
  }

  render() {
    const {
      showImportGridLayout,
      showHideImportGridLayout,
      layoutKey,
      loadLayouts,
      applyLayout,
    } = this.props;
    const {
      layoutName,
    } = this.state;
    return (
      <Popup
        visible={showImportGridLayout}
        maxWidth={500}
        height="auto"
        showTitle
        title="Upload Grid Layout"
        position="center"
        showCloseButton
        onHiding={(e) => {
          this.props.showHideImportGridLayout(false);
        }}
      >
        <div className="form">
          <div className="dx-fieldset">
            <div className="dx-field">
              <div className="dx-field-label">Layout Name:</div>
              <TextBox
                className="dx-field-value"
                onValueChanged={this.layoutNameOnValueChanged}
                value={layoutName}
              />
            </div>
            <div className="dx-field">
              <div className="dx-field-label">Layout File:</div>
              <FileUploader
                name="file"
                className="dx-field-value"
                accept="application/txt"
                uploadMode="instantly"
                uploadUrl={`${gridLayoutsUrl}/UploadLayout?key=${layoutKey}&name=${layoutName}`}
                onInitialized={async (e) => {
                  const o = {};
                  await SetAuthorizationHeaderAsync(o);
                  e.component.option('uploadHeaders', o.headers);
                }}
                onUploaded={(e) => {
                  this.setState({
                    layoutName: '',
                  });
                  const layoutId = parseInt(e.request.responseText);
                  loadLayouts().then(
                    (result) => {
                      applyLayout(layoutId);
                    },
                  );
                  e.component.reset();
                  showHideImportGridLayout(false);
                }}
                onUploadError={(e) => {
                  e.component.reset();
                  notify({ message: e.error.responseText, shading: true }, 'error');
                }}
              />
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

export default ImportGridLayoutPopup;
