import React from 'react';
import './EditSchoolForm.scss';
import Tabs from 'devextreme-react/tabs';
import Form, {
  GroupItem,
  SimpleItem,
  EmptyItem,
  Label,
  RequiredRule,
  PatternRule,
  Item,
} from 'devextreme-react/form';

import {
  DataGrid,
  Column,
  Editing,
  Selection,
  SearchPanel,
  ColumnChooser,
  Grouping,
  GroupPanel,
  FilterPanel,
  FilterRow,
  HeaderFilter,
  SortByGroupSummaryInfo,
  Button,
  Paging,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';

import Tooltip from 'devextreme-react/tooltip';
import notify from 'devextreme/ui/notify';
import { appConst } from '../../AppConst';
import { strings } from '../../locale';
import {
  CreateAuthorizedDataSource,
  CreateAuthorizedStore,
  createNewCall,
} from '../../helpers/data';
import calcGridHeight, {
  addMemberBaseViewColumns,
  initMemberBaseViewColumns,
  cancelEditFormData,
  updateAddressFields,
  setFocusOnEditor,
  compareAddresses,
  getStoredAddress,
  setStoredAddress,
} from '../../helpers/ui';

import AppContext from '../../AppContext';
import GridLayout from '../../components/grid-layout-component/GridLayout';
import AddressAutoComplete from '../../components/address-auto-complete/AddressAutoComplete';

const URL = `${window.env.apiEndpoint}/api/v1/Schools`;

const tabs = [
  {
    id: 0,
    text: 'School Information',
    icon: 'fas fa-building',
  },
  {
    id: 1,
    text: 'Staff List',
    icon: 'group',
  },
  {
    id: 2,
    text: `${strings.cor}`,
    icon: 'user',
  },
];

class EditSchoolForm extends React.Component {
  constructor(props) {
    super(props);
    const { rowData } = this.props;
    this.state = {
      selectedTabIndex: 0,
      showMemberEditForm: false,
      currentRowData: JSON.stringify(rowData),
      rowData: rowData,
      city: rowData.City,
      province: rowData.Province,
      storedAddress: getStoredAddress(rowData), 
    };

    this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);

    this.membersDataSource = CreateAuthorizedDataSource(
      {
        loadUrl: `${URL}/GetAssociatedMembersBySchool`,
      }, { load: { schoolId: this.props.rowData.Id } }, 'SchoolAssociationId', false,
    );

    this.csrsDataSource = CreateAuthorizedDataSource(
      {
        loadUrl: `${URL}/GetCSRsBySchool`,
      }, { load: { schoolId: this.props.rowData.Id } }, 'Id', false,
    );

    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.saveData = this.saveData.bind(this);
    this.cancelData = this.cancelData.bind(this);
    this.setGridHeights = this.setGridHeights.bind(this);
    this.callIconClick = this.callIconClick.bind(this);
    this.updateAddressFields = this.updateAddressFields.bind(this);
    this.setAddressVerified = this.setAddressVerified.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setGridHeights);
  }

  onTabsSelectionChanged(args) {
    const { addNewMode } = this.props;
    if (args.name === 'selectedIndex') {
      if (addNewMode && args.value > 0) {
        notify('School is not saved yet.');
      } else {
        this.setState({ selectedTabIndex: args.value });
        this.setGridHeights();
      }
      if (args.value === 0) {
        setFocusOnEditor(this.form.instance, "Name");
      }
    }
  }

  saveButtonOptions = {
    text: 'Save',
    width: 120,
    onClick: (e) => {
      this.saveData();
    },
  }

  cancelData() {
    const { showHideSchoolEditForm } = this.props;
    const { currentRowData } = this.state;

    const editedData = JSON.stringify(this.form.instance.option('formData'));
    cancelEditFormData(currentRowData, editedData, this.saveData, () => { showHideSchoolEditForm(false); });
  }

  cancelButtonOptions = {
    text: 'Cancel',
    width: 120,
    onClick: (e) => {
      this.cancelData();
    },
  }

  editIconClick(key) {
    this.props.setShowMemberEditFormValue(true, key, false);
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        onClick: () => {
          this.props.setShowMemberAddNewFormValue(true, this.membersGrid.instance);
        },
      },
    });
  }

  saveData() {
    const { addNewMode, refreshSchoolGrid, showHideSchoolEditForm } = this.props;
    const { rowData } = this.state;
    const v = this.form.instance.validate();
    if (!v.isValid) return false;
    const data = rowData;
    const saveSchoolData = CreateAuthorizedStore(
      { updateUrl: `${URL}/Put`, insertUrl: `${URL}/Post` }, null, 'Id',
    );

    if (!addNewMode) {
      saveSchoolData.update(data.Id, data).then(
        (result) => {
          refreshSchoolGrid();
          showHideSchoolEditForm(false);
        },
        (error) => {
          notify({ message: error.message, shading: true }, 'error');
        },
      );
    } else {
      saveSchoolData.insert(data).then(
        (result) => {
          refreshSchoolGrid();
          showHideSchoolEditForm(false);
        },
        (error) => {
          notify({ message: error.message, shading: true }, 'error');
        },
      );
    }
    return false;
  }

  refreshCSRSummary() {
    const countData = CreateAuthorizedStore({ loadUrl: `${URL}/GetCSRStatistics` },
      { load: { schoolId: this.props.rowData.Id } }, null, 'Id');
    countData.load().then(
      (result) => {
        this.setState({
          totalMemberCount: result[0].TotalMemberCount,
          repsIdentified: result[0].RepsIdentified,
          repsEntitled: result[0].RepsEntitled,
        });
      },
    );
  }

  componentDidMount() {
    this.refreshCSRSummary();
    window.addEventListener('resize', this.setGridHeights);
  }

  refreshGrids() {
    this.membersGrid.instance.refresh();
    this.csrsGrid.instance.refresh();
    this.refreshCSRSummary();
  }

  callIconClick(e) {
    const { account, callsInProgress, updateContext } = this.context;
    createNewCall(callsInProgress, updateContext, account, e.row.data, 'Member').then(
      (result) => {

      },
      (error) => {
        notify({ message: error, shading: true }, 'error');
      },
    );
  }

  setGridHeights() {
    this.membersGrid.instance.option('height', calcGridHeight('membersGrid'));
    this.csrsGrid.instance.option('height', calcGridHeight('csrsGrid') - document.getElementsByClassName('csr-summary-container')[0].offsetHeight);
  }

  updateAddressFields(addressObj) {
    const { rowData } = this.state;
    this.setState({ rowData: updateAddressFields(addressObj, rowData) }, () => this.setState({ storedAddress: setStoredAddress(addressObj) }));
  }

  setAddressVerified(value) {
    const { rowData } = this.state;
    this.setState({
      rowData: { ...rowData, AddressVerified: value },
    });
  }

  render() {
    const {
      selectedTabIndex, totalMemberCount, repsEntitled, repsIdentified, rowData, city, storedAddress
    } = this.state;
    const { ridingsData } = this.props;
    return (
      <>
        <Tabs
          dataSource={tabs}
          selectedIndex={selectedTabIndex}
          onOptionChanged={this.onTabsSelectionChanged}
        />
        <div className={(selectedTabIndex === 0) ? 'tab-container-visible' : 'tab-container-invisible'}>
          <Form
            ref={(ref) => { this.form = ref; }}
            scrollingEnabled
            formData={rowData}
            {...appConst.defaultFormOptions}
          >
            <GroupItem caption=" " colCount={4}>
              <SimpleItem editorType="dxTextBox" dataField="Name">
                <Label text="Name" />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                editorType="dxCheckBox"
                dataField="Current"
              >
                <Label text="Current" />
              </SimpleItem>
              <SimpleItem
                editorType="dxTextBox"
                dataField="PhoneNumber"
                editorOptions={appConst.phoneEditorOptions}
              >
                <Label text="Phone Number" />
                <PatternRule
                  pattern={appConst.phone10DigitsPattern}
                  message={appConst.phoneEditorOptions.message}
                />
              </SimpleItem>
              <SimpleItem
                editorType="dxTextBox"
                dataField="FaxNumber"
                editorOptions={appConst.phoneEditorOptions}
              >
                <Label text="Fax Number" />
                <PatternRule
                  pattern={appConst.phone10DigitsPattern}
                  message={appConst.phoneEditorOptions.message}
                />
              </SimpleItem>
              <Item>
                <Label text="Address 1" />
                <AddressAutoComplete
                  autoCompleteTextBoxId={"schoolAddressAutoCompleteTextBoxId"}
                  updateAddressFields={this.updateAddressFields}
                  value={rowData && rowData.Address1}
                  onValueChanged={(value) => {
                    this.setState({ rowData: { ...rowData, Address1: value } })
                  }}
                  city={city}
                  province={"AB"}
                  addressVerified={rowData.AddressVerified}
                  setAddressVerified={this.setAddressVerified}
                />
              </Item>
              <SimpleItem editorType="dxTextBox" dataField="Address2">
                <Label text="Address 2" />
              </SimpleItem>
              <SimpleItem editorType="dxTextBox" dataField="City" editorOptions={{
                ...appConst.defaultAddressInput,
                onValueChanged: (e) => {
                  this.setState({ city: e.value }, () => compareAddresses(e, rowData, storedAddress, this.setAddressVerified));
                }
              }}>
                <Label text="City" />
              </SimpleItem>
              <SimpleItem editorType="dxTextBox" dataField="PostalCode" editorOptions={{
                ...appConst.defaultAddressInput,
                onValueChanged: (e) => {
                  compareAddresses(e, rowData, storedAddress, this.setAddressVerified);
                }
              }}>
                <Label text="Postal Code" />
                <PatternRule
                  pattern={appConst.postalCodePattern}
                  message={appConst.postalCodeValidationMessage}
                />
              </SimpleItem>
              <SimpleItem editorType="dxTextBox" dataField="EmailAddress">
                <Label text="Email Address" />
                <PatternRule
                  pattern={appConst.emailPattern}
                  message={appConst.emailValidationMessage}
                />
              </SimpleItem>
              <SimpleItem editorType="dxTextBox" dataField="RouteNumber">
                <Label text="Route" />
              </SimpleItem>
              <SimpleItem editorType="dxTextBox" dataField="Ward">
                <Label text="Ward" />
                <PatternRule
                  pattern={appConst.schoolWardPattern}
                  message={appConst.schoolWardValidationMessage}
                />
              </SimpleItem>
              <SimpleItem
                editorType="dxSelectBox"
                dataField="RidingId"
                editorOptions={{ dataSource: ridingsData, ...appConst.defaultSelectBoxOptions }}
              >
                <Label text="Riding" />
              </SimpleItem>
              <EmptyItem colSpan={4} />
              <EmptyItem colSpan={3} />
              <GroupItem
                caption=" "
                colCount={2}
                verticalAlignment="bottom"
              >
                <SimpleItem
                  itemType="button"
                  horizontalAlignment="right"
                  verticalAlignment="bottom"
                  buttonOptions={this.saveButtonOptions}
                />
                <SimpleItem
                  itemType="button"
                  horizontalAlignment="left"
                  verticalAlignment="bottom"
                  buttonOptions={this.cancelButtonOptions}
                />
              </GroupItem>
            </GroupItem>
          </Form>
        </div>
        <div className={(selectedTabIndex === 1) ? 'tab-container-visible' : 'tab-container-invisible'}>
          <GridLayout
            layoutKey="Members"
            gridName="MembersGrid"
            pageName="EditSchoolPage"
            gridRef={
              () => this.membersGrid.instance
            }
          />
          <DataGrid
            id="membersGrid"
            ref={(ref) => { this.membersGrid = ref; }}
            dataSource={this.membersDataSource}
            onToolbarPreparing={this.onToolbarPreparing}
            {...appConst.defaultGridOptions}
            onRowDblClick={(e) => {
              this.editIconClick(e.data.Id);
            }}
            customizeColumns={addMemberBaseViewColumns}
            onInitialized={(e) => { initMemberBaseViewColumns(e); }}
          >
            <Paging
              enabled
            />
            <Selection mode="multiple" />
            <SearchPanel
              visible
              width={240}
              placeholder="Search..."
            />
            <Editing
              mode="popup"
              allowAdding={false}
              allowDeleting
              allowUpdating
            />
            <ColumnChooser
              enabled
              mode="dragAndDrop"
            />
            <Grouping
              allowCollapsing
              autoExpandAll={false}
              contextMenuEnabled
              expandMode="rowClick"
            />
            <GroupPanel
              visible
              allowColumnDragging
            />
            <HeaderFilter
              visible
              allowSearch
            />
            <SortByGroupSummaryInfo
              summaryItem="count"
              sortOrder="desc"
            />
            <FilterRow visible />
            <FilterPanel visible />
            <Column dataField="Id" visible={false} />
            <Column
              dataField="PreferredFirstName"
              caption="Preferred First Name"
              dataType="string"
              sortOrder="asc"
              sortIndex="1"
            />
            <Column
              dataField="LastName"
              caption="Last Name"
              dataType="string"
              sortOrder="asc"
              sortIndex="0"
            />
            <Column dataField="EmailAddress" caption="Email Address" dataType="string" />
            <Column dataField="PhoneNumber" caption="Phone Number" dataType="string" />
            <Column dataField="Division" caption="Division" dataType="string" />
            <Column type="buttons">
              <Button name="callLog" hint="Call Log" onClick={this.callIconClick} icon="tel" />
              <Button name="edit" hint="Edit Member" onClick={(e) => this.editIconClick(e.row.data.Id)} />
            </Column>
            <Summary>
              <TotalItem
                column="PreferredFirstName"
                summaryType="count"
              />
            </Summary>
          </DataGrid>
          <Tooltip ref={(ref) => { this.tooltip = ref; }} closeOnOutsideClick position="left">
            <div>{this.state.currentFirstName}</div>
          </Tooltip>
        </div>
        <div className={(selectedTabIndex === 2) ? 'tab-container-visible' : 'tab-container-invisible'}>
          <GridLayout
            layoutKey="Members"
            gridName="csrsGrid"
            pageName="EditSchoolPage"
            gridRef={
              () => this.csrsGrid.instance
            }
          />
          <DataGrid
            id="csrsGrid"
            ref={(ref) => { this.csrsGrid = ref; }}
            dataSource={this.csrsDataSource}
            {...appConst.defaultGridOptions}
            customizeColumns={addMemberBaseViewColumns}
            onRowDblClick={(e) => {
              this.editIconClick(e.key);
            }}
            onInitialized={(e) => { initMemberBaseViewColumns(e); }}
          >
            <Selection mode="multiple" />
            <SearchPanel
              visible
              width={240}
              placeholder="Search..."
            />
            <Editing
              allowAdding={false}
              allowDeleting={false}
              allowUpdating
            />
            <ColumnChooser
              enabled
              mode="dragAndDrop"
            />
            <Grouping
              allowCollapsing
              autoExpandAll={false}
              contextMenuEnabled
              expandMode="rowClick"
            />
            <GroupPanel
              visible
              allowColumnDragging
            />
            <HeaderFilter
              visible
              allowSearch
            />
            <SortByGroupSummaryInfo
              summaryItem="count"
              sortOrder="desc"
            />
            <FilterRow visible />
            <FilterPanel visible />
            <Column dataField="Id" visible={false} />
            <Column
              dataField="PreferredFirstName"
              caption="Preferred First Name"
              type="string"
              sortOrder="asc"
              sortIndex="1"
            />
            <Column
              dataField="LastName"
              caption="Last Name"
              type="string"
              sortOrder="asc"
              sortIndex="0"
            />
            <Column dataField="EmailAddress" caption="Email Address" type="string" />
            <Column dataField="PhoneNumber" caption="Phone Number" type="string" />
            <Column dataField="Rep" caption="Rep" type="boolean" />
            <Column dataField="Alt" caption="Alt. Rep" type="boolean" />
            <Column dataField="Com" caption="Comm. Rep" type="boolean" />
            <Column type="buttons">
              <Button name="edit" hint="Edit Member" onClick={(e) => this.editIconClick(e.row.key)} />
            </Column>
          </DataGrid>
          <div className="csr-summary-container">
            <div className="csr-summary">
              <div>Total Staff Count:</div>
              <div>{totalMemberCount}</div>
            </div>
            <div className="csr-summary">
              <div>{strings.cor} Representatives Entitled:</div>
              <div>{repsEntitled}</div>
            </div>
            <div className="csr-summary">
              <div>{strings.cor} Representatives Identified:</div>
              <div><span className={(repsIdentified !== repsEntitled) ? 'csr-summary-repsidentified-red' : ''}>{repsIdentified}</span></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
EditSchoolForm.contextType = AppContext;

export default EditSchoolForm;
