import React from 'react';
import '../../pages.scss';
import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  Selection,
  SearchPanel,
  ColumnChooser,
  Grouping,
  GroupPanel,
  FilterPanel,
  FilterRow,
  HeaderFilter,
} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import { Popup } from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/load-panel';
import CurrentSwitch from '../../../components/current-switch/current-switch';
import calcGridHeight from '../../../helpers/ui';
import {
  CreateAuthorizedStore,
  CreateAuthorizedDataSource,
  loadMembersLookupDataAsync,
  disposeMembersLookupData,
} from '../../../helpers/data';
import { appConst } from '../../../AppConst';
import EditDesignationForm from './EditDesignationForm';
import EditMemberForm from '../../members/EditMemberForm';

const designationsUrl = `${window.env.apiEndpoint}/api/v1/Designations`;
const membersUrl = `${window.env.apiEndpoint}/api/v1/Members`;

class Designations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditForm: false,
      showMemberEditForm: false,
      showLoadingPanel: false,
      title: '',
    };

    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.toolbarItemRender = this.toolbarItemRender.bind(this);
    this.windowResized = this.windowResized.bind(this);
    this.editIconClick = this.editIconClick.bind(this);
    this.hideEditPopup = this.hideEditPopup.bind(this);
    this.refreshDesignationGrid = this.refreshDesignationGrid.bind(this);
    this.setShowMemberEditFormValue = this.setShowMemberEditFormValue.bind(this);
    this.hideMemberEditPopupAndRefreshGrid = this.hideMemberEditPopupAndRefreshGrid.bind(this);
    this.hideMemberEditPopup = this.hideMemberEditPopup.bind(this);
    this.showHideDesignationEditForm = this.showHideDesignationEditForm.bind(this);
    this.getMemberPopupRef = this.getMemberPopupRef.bind(this);

    this.dataSource = CreateAuthorizedDataSource(
      {
        loadUrl: `${designationsUrl}/Get`,
        insertUrl: `${designationsUrl}/Post`,
        updateUrl: `${designationsUrl}/Put`,
        deleteUrl: `${designationsUrl}/Delete`,
      },
      null, 'Id',
    );
  }

  toolbarItemRender() {
    return (
      <CurrentSwitch grid={this.designationsGrid} />
    );
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        template: 'switchCurrent',
        location: 'left',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: () => {
            this.setState({
              showEditForm: true,
              rowData: {},
              addNewMode: true,
              title: '(New Designation)',
            });
          },
        },
      },
    );
  }

  async componentDidMount() {
    window.addEventListener('resize', this.windowResized);
    this.setState({ membersLookupData: await loadMembersLookupDataAsync() });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResized);
    if (this.dataSource) {
      this.dataSource.dispose();
    }
    disposeMembersLookupData(this.state.membersLookupData);
  }

  windowResized() {
    this.designationsGrid.instance.option('height', calcGridHeight('designationsGrid'));
  }

  editIconClick(key, name) {
    const getDesignationByIdStore = CreateAuthorizedStore(
      { loadUrl: `${designationsUrl}/GetDesignationById` }, { load: { Id: key } },
    );
    getDesignationByIdStore.load().then(
      (result) => {
        this.setState({
          showEditForm: true,
          title: name,
          rowData: { ...result[0] },
          addNewMode: false,
        });
      },
    );
  }

  hideEditPopup() {
    this.setState({
      rowData: {},
      showEditForm: false,
    });
  }

  refreshDesignationGrid() {
    this.designationsGrid.instance.refresh();
  }

  hideMemberEditPopup() {
    this.setState({
      showMemberEditForm: false,
    });
  }

  setShowMemberEditFormValue(value, memberkey) {
    this.setState({ showLoadingPanel: true });
    const getMemberByIdStore = CreateAuthorizedStore(
      { loadUrl: `${membersUrl}/GetMemberById` }, { load: { Id: memberkey } },
    );
    getMemberByIdStore.load().then(
      (result) => {
        this.setState({
          showMemberEditForm: value,
          rowMemberData: result[0],
          showLoadingPanel: false,
        });
      },
    );
  }

  hideMemberEditPopupAndRefreshGrid() {
    this.hideMemberEditPopup();
    this.refs.editDesignationForm.refreshGrid();
  }

  getMemberPopupRef() {
    return this.editMemberFormPopup && this.editMemberFormPopup.instance;
  }

  renderMemberEditPopUp(isEditFormShown) {
    const { rowMemberData } = this.state;
    if (isEditFormShown) {
      const { showMemberEditForm, membersLookupData } = this.state;
      return (
        <Popup
          ref={(ref) => { this.editMemberFormPopup = ref; }}
          visible={showMemberEditForm}
          onHiding={(e) => {
            e.cancel = true;
            this.refs.editMemberForm && this.refs.editMemberForm.cancelData();
          }}
          {...appConst.defaultPopupOptions}
          onShown={(e) => { this.refs.editMemberForm.changeEditFormTitle(); }}
        >
          <EditMemberForm
            ref="editMemberForm"
            rowData={rowMemberData}
            hideEditPopupAndRefreshGrid={this.hideMemberEditPopupAndRefreshGrid}
            hideEditPopup={this.hideMemberEditPopup}
            salutationsData={membersLookupData.salutationsData}
            gendersData={membersLookupData.gendersData}
            pronounsData={membersLookupData.pronounsData}
            emailAddressTypesData={membersLookupData.emailAddressTypesData}
            phoneTypesData={membersLookupData.phoneTypesData}
            membershipTypesData={membersLookupData.membershipTypesData}
            employmentStatusesData={membersLookupData.employmentStatusesData}
            contractTypesData={membersLookupData.contractTypesData}
            designationsData={membersLookupData.designationsData}
            ridingsData={membersLookupData.ridingsData}
            addNewMode={false}
            getMemberPopupRef={this.getMemberPopupRef}
            provincesData={membersLookupData.provincesData}
          />
        </Popup>
      );
    }
  }

  showHideDesignationEditForm(value) {
    this.setState({
      showEditForm: value,
    });
  }

  render() {
    const {
      showEditForm,
      addNewMode, rowData, title, showMemberEditForm,
    } = this.state;
    return (
      <>
        <div className="title">
          <span>Designations</span>
        </div>
        <LoadPanel
          visible={this.state.showLoadingPanel}
          showIndicator
          shading
          showPane
          closeOnOutsideClick={false}
        />

        {showEditForm && (
          <>
            <Popup
              id="editDesignationPopup"
              visible
              onHiding={(e) => {
                e.cancel = true;
                this.refs.editDesignationForm && this.refs.editDesignationForm.cancelData();
              }}
              {...appConst.defaultPopupOptions}
              title={title}
            >
              <EditDesignationForm
                ref="editDesignationForm"
                addNewMode={addNewMode}
                rowData={rowData}
                setShowMemberEditFormValue={this.setShowMemberEditFormValue}
                showHideDesignationEditForm={this.showHideDesignationEditForm}
                refreshDesignationGrid={this.refreshDesignationGrid}
              />
            </Popup>
          </>
        )}

        {this.renderMemberEditPopUp(showMemberEditForm)}

        <DataGrid
          id="designationsGrid"
          ref={(ref) => { this.designationsGrid = ref; }}
          dataSource={this.dataSource}
          {...appConst.defaultGridOptions}
          onToolbarPreparing={this.onToolbarPreparing}
          height={() => calcGridHeight('designationsGrid')}
          onRowDblClick={(e) => {
            this.editIconClick(e.key, e.data.Description);
          }}
        >
          <Selection mode="multiple" />
          <SearchPanel
            visible
            width={240}
            placeholder="Search..."
          />
          <Scrolling
            mode="virtual"
            rowRenderingMode="virtual"
          />
          <Editing
            mode="row"
            allowAdding={false}
            allowDeleting
            allowUpdating
          />
          <Column
            dataField="Description"
            dataType="string"
            sortOrder="asc"
            sortIndex="1"
          />
          <Column
            dataField="Current"
            filterValue
            dataType="boolean"
            sortOrder="desc"
            sortIndex="0"
          />
          <Column
            dataField="Common"
            dataType="boolean"
            sortOrder="desc"
            sortIndex="2"
          />
          <Column
            type="buttons"
            width={110}
            buttons={['delete', {
              hint: 'Edit',
              icon: 'edit',
              onClick: (e) => {
                this.editIconClick(e.row.key, e.row.data.Description);
              },
            }]}
          />
          <Template name="switchCurrent" render={this.toolbarItemRender} />
          <ColumnChooser
            enabled
            mode="dragAndDrop"
          />
          <Grouping
            allowCollapsing
            autoExpandAll={false}
            contextMenuEnabled
            expandMode="rowClick"
          />
          <GroupPanel
            visible
            allowColumnDragging
          />
          <HeaderFilter
            visible
            allowSearch
          />
          <FilterRow visible />
          <FilterPanel visible />
        </DataGrid>
      </>
    );
  }
}

export default Designations;
