import React from 'react';
import {
  DataGrid, Column, Editing, Scrolling, Selection, SearchPanel
} from 'devextreme-react/data-grid';
import calcGridHeight from '../../../helpers/ui';
import './Roles.scss';
import { CreateAuthorizedDataSource } from '../../../helpers/data';
import { Popup } from 'devextreme-react/popup';
import EditRoleForm from './EditRoleForm';
import { appConst } from '../../../AppConst';


const rolesUrl = `${window.env.apiEndpoint}/api/v1/Roles`;

class Roles extends React.Component {
  constructor(props) {
    super(props);

    this.windowResized = this.windowResized.bind(this);
    this.dataSource = CreateAuthorizedDataSource(
      {
        'loadUrl': `${rolesUrl}/Get`,
        'updateUrl': `${rolesUrl}/Put`,
        'insertUrl': `${rolesUrl}/Post`,
        'deleteUrl': `${rolesUrl}/Delete`
      }, null, 'Id');

      this.state = {
        showEditForm: false,
        addNewMode: false,
      };
      this.editIconClick = this.editIconClick.bind(this);
      this.hideEditPopup = this.hideEditPopup.bind(this);
      this.refreshGrid = this.refreshGrid.bind(this);
      this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
      this.changeTitle = this.changeTitle.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResized);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResized);
    if (this.dataSource) {
      this.dataSource.dispose();
    }
  }

  windowResized() {
    this.rolesGrid.instance.option('height', calcGridHeight('rolesGrid'));
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: this.addIconClick.bind(this),
        },
      },
    );
  }

  editIconClick(data) {
      this.setState({
        showEditForm: true,
        roleId: data.Id,
        addNewMode: false,
        rowData: data,
        editFormTitle: data.Name
    });
  }

  addIconClick() {
      this.setState({
        showEditForm: true,
        addNewMode: true,
        roleId: 0,
        editFormTitle:''
      });
  }

  hideEditPopup() {
    this.setState({
      rowData: {},
      showEditForm: false,
    });
  }

  refreshGrid() {
    this.rolesGrid.instance.refresh();
  }

  changeTitle(title) {
    this.setState({
      editFormTitle: title
    });
  }

  render() {
    const { showEditForm, addNewMode, roleId, rowData, editFormTitle } = this.state;
    return (
      <>
        {showEditForm && (
          <Popup
            visible
            id="editRolePopup"
            onHidden={this.hideEditPopup}
            title={editFormTitle}
            {...appConst.defaultPopupOptions}
          >
            <EditRoleForm
              addNewMode={addNewMode}
              roleId={roleId}
              rowData={rowData}
              refreshGrid={this.refreshGrid}
              changeTitle={this.changeTitle}
            />
          </Popup>
        )}

        <div className="title">
          <span>{"Roles"}</span>
        </div>
        <DataGrid
          id="rolesGrid"
          key="Id"
          ref={(ref) => { this.rolesGrid = ref; }}
          dataSource={this.dataSource}
          {...appConst.defaultGridOptions}
          onRowDblClick={(e) => {
            this.editIconClick(e.data)
          }}
          onToolbarPreparing={this.onToolbarPreparing}
          height={() => calcGridHeight('rolesGrid')}
        >
          <Selection mode="multiple" />
          <SearchPanel
            visible
            width={240}
            placeholder="Search..."
          />
          <Scrolling
            mode="virtual"
            rowRenderingMode="virtual"
          />
          <Editing
            mode="row"
            allowAdding={false}
            allowDeleting
            allowUpdating
          />
          <Column
            dataField="Name"
            dataType="string"
            sortOrder="asc"
          />
          <Column dataField="Description" dataType="string" />
          <Column
            type="buttons"
            width={110}
            buttons={['delete', {
              hint: 'Edit',
              icon: 'edit',
              onClick: (e) => {
                this.editIconClick(e.row.data);
              },
            }]}
          />
        </DataGrid>
      </>
    );
  }
}
export default Roles;
