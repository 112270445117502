import React from 'react';
import {
  DataGrid, Column, Editing, Scrolling, Selection, SearchPanel
} from 'devextreme-react/data-grid';
import calcGridHeight from '../../../helpers/ui';
import './Permissions.scss';
import { CreateAuthorizedDataSource } from '../../../helpers/data';
import { Popup } from 'devextreme-react/popup';
import EditPermissionForm from './EditPermissionForm';
import { appConst } from '../../../AppConst';

const permissionsUrl = `${window.env.apiEndpoint}/api/v1/Permissions`;

class Permissions extends React.Component {
  constructor(props) {
    super(props);

    this.windowResized = this.windowResized.bind(this);
    this.dataSource = CreateAuthorizedDataSource(
      { 'loadUrl': `${permissionsUrl}/Get`}, null, 'Id');

    this.state = {
      showEditForm: false,
      editFormHeight: 0,
    };

    this.editIconClick = this.editIconClick.bind(this);
    this.hideEditPopup = this.hideEditPopup.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.changeTitle = this.changeTitle.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResized);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResized);
    if (this.dataSource) {
      this.dataSource.dispose();
    }
  }

  windowResized() {
    this.permissionsGrid.instance.option('height', calcGridHeight('permissionsGrid'));
  }

  editIconClick(data) {
      this.setState({
        showEditForm: true,
        permissionId: data.Id,
        rowData: data,
        editFormTitle: data.Name
    });
  }

  addIconClick() {
      this.setState({
        showEditForm: true,
        permissionId: 0,
        editFormData:''
      });
  }

  hideEditPopup() {
    this.setState({
      rowData: {},
      showEditForm: false,
    });
  }

  refreshGrid() {
    this.permissionsGrid.instance.refresh();
  }

  changeTitle(title) {
    this.setState({
      editFormTitle: title
    });
  }

  render() {
    const { showEditForm, permissionId, rowData, editFormTitle } = this.state;
    return (
      <>
        {showEditForm && (
          <Popup
            id="editPermissionPopup"
            visible
            onHidden={this.hideEditPopup}
            {...appConst.defaultPopupOptions}
            title={editFormTitle}
          >
            <EditPermissionForm
              permissionId={permissionId}
              rowData={rowData}
              refreshGrid={this.refreshGrid}
              changeTitle={this.changeTitle}
            />
          </Popup>
        )}
        <div className="title">
          <span>{"Permissions"}</span>
        </div>
        <DataGrid
          id="permissionsGrid"
          key="Id"
          ref={(ref) => { this.permissionsGrid = ref; }}
          dataSource={this.dataSource}
          {...appConst.defaultGridOptions}
          onRowDblClick={(e) => {
            this.editIconClick(e.data)
          }}
          height={() => calcGridHeight('permissionsGrid')}
        >
          <Selection mode="multiple" />
          <SearchPanel
            visible
            width={240}
            placeholder="Search..."
          />
          <Scrolling
            mode="virtual"
            rowRenderingMode="virtual"
          />
          <Editing
            mode="row"
            allowAdding={false}
            allowDeleting={false}
            allowUpdating={false}
          />
          <Column
            dataField="Name"
            dataType="string"
            sortOrder="asc"
          />
          <Column dataField="Description" dataType="string" />
          <Column
            type="buttons"
            width={110}
            buttons={['delete', {
              hint: 'Edit',
              icon: 'edit',
              onClick: (e) => {
                this.editIconClick(e.row.data);
              },
            }]}
          />
        </DataGrid>
      </>
    );
  }
}
export default Permissions;
