import React from 'react';
import './EditMemberForm.scss';
import '../pages.scss';
import Form, {
  GroupItem,
  SimpleItem,
  Label,
  RequiredRule,
  PatternRule,
  Item,
  EmptyItem
} from 'devextreme-react/form';
import { Accordion, FileUploader } from 'devextreme-react';
import { confirm } from 'devextreme/ui/dialog';
import DxForm from "devextreme/ui/form";
import {
  DataGrid,
  Column,
  Editing,
  Lookup,
  Paging,
} from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { DateBox } from 'devextreme-react/date-box';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import Tabs from 'devextreme-react/tabs';
import ScrollView from 'devextreme-react/scroll-view';
import PropTypes from 'prop-types';
import SelectBox from 'devextreme-react/select-box';
import SchoolTitle from './SchoolTitle';
import SchoolItem from './SchoolItem';
import { CreateAuthorizedDataSource, CreateAuthorizedStore, SetAuthorizationHeaderAsync, getMemberById } from '../../helpers/data';
import PersonCallLogGrid from '../../components/person-call-log-grid/PersonCallLogGrid';
import CommitteeGrid from '../../components/committee-grid/CommitteeGrid';
import { appConst } from '../../AppConst';
import calcGridHeight, {
  setPreferredPhoneNumber, setPreferredEmailAddress,
  cancelEditFormData,
  changePersonEditFormTitle,
  setDateInlineEditing,
  updateAddressFields,
  setFocusOnEditor,
  compareAddresses,
  getStoredAddress,
  setStoredAddress,
} from '../../helpers/ui';
import FileDialogPopup from './FileDialogPopup';
import AddressAutoComplete from '../../components/address-auto-complete/AddressAutoComplete';
import ExternalMailSystem from '../../components/external-mail-system/ExternalMailSystem';
import SaveAndCancelButtons from '../../components/save-and-cancel-buttons/SaveAndCancelButtons';
import moment from 'moment';

const URL = `${window.env.apiEndpoint}/api/v1/Members`;
const URL2 = `${window.env.apiEndpoint}/api/v1/MemberFunds`;

const fundTypesData = CreateAuthorizedDataSource({ loadUrl: `${window.env.apiEndpoint}/api/v1/FundTypes/FundTypesLookup` }, null, 'Value', null, 'Header');

const tabs = [
  {
    id: 0,
    text: "Member's Information",
    icon: 'user',
  },
  {
    id: 1,
    text: 'School Associations',
    icon: 'fas fa-building',
  },
  {
    id: 2,
    text: 'Committees',
    icon: 'group',
  },
  {
    id: 3,
    text: 'Attachments',
    icon: 'file',
  },
  {
    id: 4,
    text: 'Funding',
    icon: 'money',
  },
  {
    id: 5,
    text: 'Calls',
    icon: 'tel',
  },
];

class EditMemberForm extends React.Component {
  addPhoneButtonOptions = {
    icon: 'add',
    text: 'Add Phone',
    onClick: () => {
      const { rowData } = this.state;
      const tmpState = { ...rowData };
      tmpState.PhoneNumbers.push({ Phone: '', Preferred: (tmpState.PhoneNumbers && !tmpState.PhoneNumbers.length) });
      this.setState({ rowData: tmpState });
      setFocusOnEditor(this.form.instance, `PhoneNumbers[${tmpState.PhoneNumbers.length - 1}].Number`);
    },
  }

  addEmailButtonOptions = {
    icon: 'add',
    text: 'Add Email',
    onClick: () => {
      const { rowData } = this.state;
      const tmpState = { ...rowData };
      tmpState.EmailAddresses.push({ Address: '', Preferred: (tmpState.EmailAddresses && !tmpState.EmailAddresses.length) });
      this.setState({ rowData: tmpState });
      setFocusOnEditor(this.form.instance, `EmailAddresses[${tmpState.EmailAddresses.length - 1}].Address`);
    },
  }

  constructor(props) {
    super(props);

    // The school accordion title component.
    this.renderSchoolAccordionTitle = (itemData, index) => (
      <SchoolTitle
        dataForm={itemData}
        removeSchool={this.removeSchool}
        index={index}
      />
    );

    // The school accordion item component.
    this.renderSchoolAccordionItem = (itemData) => (
      <SchoolItem
        dataForm={itemData}
        removeCSR={this.removeCSR}
        addCSRRole={this.addCSRRole}
      />
    );

    // I store the rowData passed from the members view in the state.
    // or instead we can call an api to get data for the edited member.
    const { rowData, provincesData, addNewMode } = this.props;
    const province = provincesData && provincesData.find(p => p.Value === rowData.ProvinceId);
    const now = new Date();
    this.state = {
      rowData,
      oldRowData: JSON.stringify(rowData),
      Schools: rowData.Schools,
      selectedTabIndex: 0,
      showFileNameDialogForConfidential: false,
      showFileNameDialog: false,
      replaceFile: false,
      newFileName: null,
      city: rowData.City,
      province: province && province.Text,
      storedAddress: getStoredAddress(rowData),
      addNewMode: addNewMode,
      showLoadingPanel: false,
      needToRefreshTheGridOnCancel: false,
      showChangeEmploymentStatusConfirmPopup: false,
      previousEmploymentStatusId: 0,
      changedEmploymentStatusEffectiveDate: now,
    };

    this.removeSchool = this.removeSchool.bind(this);
    this.removeCSR = this.removeCSR.bind(this);
    this.addCSRRole = this.addCSRRole.bind(this);

    // this datasource is used to retrieve and delete confidential attachments from
    // azure storage blob.

    this
      .attachmentsGridOnRowRemoving = this
        .attachmentsGridOnRowRemoving.bind(this);
    this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);
    this.saveData = this.saveData.bind(this);
    this.cancelData = this.cancelData.bind(this);
    this.openAttachment = this.openAttachment.bind(this);

    this.changeEditFormTitle = this.changeEditFormTitle.bind(this);
    this.onFileUploaded = this.onFileUploaded.bind(this);
    this.setReplaceValue = this.setReplaceValue.bind(this);
    this.hideFileDialogPopup = this.hideFileDialogPopup.bind(this);
    this.selectBoxFundTypeColumnTemplate = this.selectBoxFundTypeColumnTemplate.bind(this);
    this.setGridHeights = this.setGridHeights.bind(this);
    this.updateAddressFields = this.updateAddressFields.bind(this);
    this.setAddressVerified = this.setAddressVerified.bind(this);
  }

  // this datasource is used to retrieve,update and insert member's fund
  fundsDataSource = () => {
    const { rowData: { Id } } = this.state;
    return CreateAuthorizedDataSource(
      {
        loadUrl: `${URL2}/GetFundsByMember`,
        insertUrl: `${URL2}/Post`,
        updateUrl: `${URL2}/Put`,
        deleteUrl: `${URL2}/Delete`,
      }, { load: { memberId: Id }, insert: { memberId: Id } }, 'Id', false,
    );
  }

  confidentialAttachmentDataSource = () => {
    const { Id } = this.state.rowData;
    return new DataSource({
      store: createStore({
        loadUrl: `${URL}/ListConfidentialAttachments?Id=${Id}`,
        key: 'Id',
        deleteUrl: `${URL}/DeleteConfidentialAttachment`,
        onBeforeSend: async (method, ajaxOptions) => {
          const { AttachmentName } = this.state;
          await SetAuthorizationHeaderAsync(ajaxOptions);

          if (method === 'delete' && AttachmentName) {
            ajaxOptions.data.fileName = AttachmentName;
            ajaxOptions.data.memberId = Id;
          }
        },
      }),
      paginate: false,
    });
  }

  // this datasource is used to retrieve and delete regular attachments from
  // azure storage blob.
  attachmentDataSource = () => {
    const { Id } = this.state.rowData;
    return new DataSource({
      store: createStore({
        loadUrl: `${URL}/ListAttachments?Id=${Id}`,
        key: 'Id',
        deleteUrl: `${URL}/DeleteAttachment`,
        onBeforeSend: async (method, ajaxOptions) => {
          await SetAuthorizationHeaderAsync(ajaxOptions);

          const { AttachmentName } = this.state;
          if (method === 'delete' && AttachmentName) {
            ajaxOptions.data.fileName = AttachmentName;
            ajaxOptions.data.memberId = Id;
          }
        },
      }),
      paginate: false,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setGridHeights);
  }

  componentDidMount() {
    window.addEventListener('resize', this.setGridHeights);
  }

  // this button options are used for the remove email button
  // the index points the order of remove button.
  removeEmailButtonOptions = (index) => ({
    icon: 'trash',
    onClick: () => {
      const result = confirm(appConst.confirmDialogContent, appConst.confirmDeleteDialogTitle);
      result.then((dialogResult) => {
        if (dialogResult) {
          const { rowData } = this.state;
          const tmpState = { ...rowData };
          const i = parseInt(index, 10);
          tmpState.EmailAddresses.splice(i, 1);
          this.setState({ rowData: tmpState });
        }
      });
    },
  })

  // this button options are used for the remove phone button
  // the index points the order of remove button.
  removePhoneButtonOptions = (index) => ({
    icon: 'trash',
    onClick: () => {
      const result = confirm(appConst.confirmDialogContent, appConst.confirmDeleteDialogTitle);
      result.then((dialogResult) => {
        if (dialogResult) {
          const { rowData } = this.state;
          const tmpState = { ...rowData };
          const i = parseInt(index, 10);
          tmpState.PhoneNumbers.splice(i, 1);
          this.setState({ rowData: tmpState });
        }
      });
    },
  })

  removeDesignationOptions = (index) => ({
    icon: 'trash',
    onClick: () => {
      const result = confirm(appConst.confirmDialogContent, appConst.confirmDeleteDialogTitle);
      result.then((dialogResult) => {
        if (dialogResult) {
          const { rowData } = this.state;
          const tmpState = { ...rowData };
          const i = parseInt(index, 10);
          tmpState.Designations.splice(i, 1);
          this.setState({ rowData: tmpState });
          if (tmpState.Designations.length === 0) { this.form.instance.repaint(); }
        }
      });
    },
  })

  addDesignationButtonOptions = {
    icon: 'add',
    text: 'Add Designation',
    onClick: () => {
      const { rowData } = this.state;
      const tmpState = { ...rowData };
      if (typeof tmpState.Designations === 'undefined') { tmpState.Designations = []; }
      tmpState.Designations.push({});
      this.setState({ rowData: tmpState });
      setFocusOnEditor(this.form.instance, `Designations[${tmpState.Designations.length - 1}].DesignationId`);
    },
  }

  addSchoolButtonOptions = {
    text: 'Add Associated School',
    onClick: () => {
      const { Schools } = this.state;
      const tmpState = [...Schools];
      tmpState.push({ SchoolName: 'New School' });
      this.setState({ Schools: tmpState });
      this.schoolsAccordion.instance.option('selectedIndex', tmpState.length - 1);
    },
  }

  onTabsSelectionChanged(args) {
    if (args.name === 'selectedIndex') {
      const { addNewMode } = this.state;
      if (addNewMode && args.value > 1) {
        notify('You are not allowed to add attachments, committees, funding or calls unless member is saved.');
      } else {
        this.setState({
          selectedTabIndex: args.value,
        });
        this.setGridHeights();
      }
      if (args.value === 0) {
        setFocusOnEditor(this.form.instance, "FirstName");
      }
    }
  }

  setGridHeights() {
    this.fundsGrid.instance.option('height', calcGridHeight('fundsGrid'));
    this.refs.personCallLogGrid.setGridHeight();
    this.refs.committeeGrid.setGridHeight();
  }

  attachmentsGridOnRowRemoving(e) {
    this.setState({ AttachmentName: e.data.Name });
  }

  setAddressVerified(value) {
    const { rowData } = this.state;
    this.setState({
      rowData: { ...rowData, AddressVerified: value },
    });
  }

  async getMemberByIdAsync(Id) {
    try {
      const member = await getMemberById(Id);
      return member;
    }
    catch (error) {
      notify({ message: error.message, shading: true }, 'error');
    }
  }

  async afterSave(closePopup, Id) {
    if (!closePopup) {
      const member = await this.getMemberByIdAsync(Id);
      if (typeof member === "undefined") return;
      this.setState({
        oldRowData: JSON.stringify(member),
        rowData: member,
        addNewMode: false,
        Schools: member.Schools,
        showLoadingPanel: false,
        needToRefreshTheGridOnCancel: true,
      }, () => { return });
    } else {
      const { hideEditPopupAndRefreshGrid } = this.props;
      hideEditPopupAndRefreshGrid();
    }
  }

  saveData(closePopup) {
    const { Schools, addNewMode, rowData, changedEmploymentStatusEffectiveDate } = this.state;

    const v = this.form.instance.validate();
    if (!v.isValid) return false;
    const schoolItems = this.schoolsAccordion.instance.element().querySelectorAll("#schoolForm");
    let validSchools = true;
    for (const element of schoolItems) {
      let f = DxForm.getInstance(element);
      if (typeof f !== "undefined") {
        if (!f.validate().isValid) {
          validSchools = false;
        }
      }
    }
    if (!validSchools) return;
    if (!closePopup)
      this.setState({ showLoadingPanel: true });
    const data = rowData;
    data.Schools = Schools;
    data.City = data.City && data.City.trim();
    data.Address1 = data.Address1 && data.Address1.trim();
    const saveMemberData = CreateAuthorizedStore(
      { updateUrl: `${URL}/Put`, insertUrl: `${URL}/Post` },
      { update: { effectiveDate: changedEmploymentStatusEffectiveDate && moment(changedEmploymentStatusEffectiveDate).format('YYYY-MM-DD') }, insert: { effectiveDate: changedEmploymentStatusEffectiveDate && moment(changedEmploymentStatusEffectiveDate).format('YYYY-MM-DD') } }, 'Id',
    );
    if (!addNewMode) {
      saveMemberData.update(data.Id, data).then(
        async (result) => {
          await this.afterSave(closePopup, data.Id, this);
        },
        (error) => {
          this.setState({ showLoadingPanel: false });
          notify({ message: error.message, shading: true }, 'error');
        },
      );
    } else {
      saveMemberData.insert(data).then(
        async (result) => {
          await this.afterSave(closePopup, result, this);
        },
        (error) => {
          this.setState({ showLoadingPanel: false });
          notify({ message: error.message, shading: true }, 'error');
        },
      );
    }
  }

  cancelData() {
    const { hideEditPopupAndRefreshGrid, hideEditPopup } = this.props;
    const { Schools, oldRowData, needToRefreshTheGridOnCancel } = this.state;
    const editedData = this.form.instance.option('formData');
    editedData.Schools = Schools;
    if (needToRefreshTheGridOnCancel)
      cancelEditFormData(oldRowData, JSON.stringify(editedData), this.saveData, hideEditPopupAndRefreshGrid);
    else
      cancelEditFormData(oldRowData, JSON.stringify(editedData), this.saveData, hideEditPopup);
  }

  async openAttachment(fileName) {
    window.open(`${window.env.documentViewerAppUri}/?fileName=${fileName}`, "_blank", "noopener noreferrer");
  }

  changeEditFormTitle() {
    if (this.form === null) return;
    const { addNewMode } = this.state;
    const popup = this.props.getMemberPopupRef();
    const formData = this.form.instance.option('formData');
    const formScrollView = this.formScrollView.instance;
    changePersonEditFormTitle(addNewMode, popup, formData, formScrollView);
  }

  onFileUploaded(gridRef, uploadRef, fileName) {
    gridRef.refresh();
    uploadRef.reset();
    notify(`File ${fileName} has been uploaded.`);
    this.setState({
      showFileNameDialogForConfidential: false, showFileNameDialog: false, newFileName: null, file: null, replaceFile: false, uploadRef: null,
    });
  }

  setReplaceValue(value) {
    this.setState({ replaceFile: value });
  }

  hideFileDialogPopup() {
    this.setState({
      showFileNameDialogForConfidential: false, showFileNameDialog: false, newFileName: null, file: null, replaceFile: false, uploadRef: null, gridRef: null,
    });
  }

  onSelectBoxFundTypeValueChanged(cell, e) {
    cell.setValue(e.value);
  }

  selectBoxFundTypeColumnTemplate(cell) {
    const onValueChanged = this.onSelectBoxFundTypeValueChanged.bind(this, cell);
    return (
      <SelectBox
        {...appConst.defaultSelectBoxOptions}
        defaultValue={cell.value}
        {...cell.column.lookup}
        onValueChanged={onValueChanged}
        itemRender={this.itemRender}
        grouped
        dataSource={fundTypesData}
      />
    );
  }

  onEditorPreparingFundsGrid(e) {
    setDateInlineEditing(['FundDate'], e);
  }

  updateAddressFields(addressObj) {
    const { provincesData } = this.props;
    const { rowData } = this.state;

    this.setState({ rowData: updateAddressFields(addressObj, rowData, provincesData) },
      () => this.setState({ storedAddress: setStoredAddress(addressObj) }));
  }

  toolbarButtons() {
    return <SaveAndCancelButtons saveData={this.saveData} cancelData={this.cancelData} />;
  }

 renderShowPopup() {
  return (
    <Popup
      visible={this.state.showChangeEmploymentStatusConfirmPopup}
      width="25%"
      height="25%"
      onHiding={() => { this.setState({ showChangeEmploymentStatusConfirmPopup: false }); }}
      title="Select effective date..."
      showCloseButton={false}
    >
      <div>
        <i>Changing the employment status to {this.form && this.form.instance && this.form.instance.getEditor("EmploymentStatusId").option("text")} will change other properties automatically</i>
      </div>
      <DateBox
        defaultValue={new Date()}
        type="date"
        {...appConst.defaultSelectDateBoxOptions}
        onValueChanged={(e) => {
          this.setState({ changedEmploymentStatusEffectiveDate: e.value });
        }}
        dateSerializationFormat="yyyy-MM-dd"
      />
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'20px'
      }} >
        <Button
          text='Ok'
          width={150}
          onClick={
            () => {
              if (!this.state.changedEmploymentStatusEffectiveDate) {
                notify({ message: "Effective date can't be empty", shading: true }, 'error');
              } else {
                this.setState({ showChangeEmploymentStatusConfirmPopup: false });
              }
            }
          }
        />
        <Button
          text='Cancel'
          width={150}
          onClick={
            () => {
              this.setState({ showChangeEmploymentStatusConfirmPopup: false });
              this.form.instance.getEditor("EmploymentStatusId").option("value", this.state.previousEmploymentStatusId);
              this.setState({ changedEmploymentStatusEffectiveDate: null});
            }
          }
        />
      </div>
    </Popup>)
}

  render() {
    const {
      membershipTypesData, employmentStatusesData, contractTypesData, phoneTypesData,
      emailAddressTypesData, salutationsData, gendersData, pronounsData, designationsData, ridingsData, provincesData
    } = this.props;
    const {
      selectedTabIndex, Schools, rowData, rowData: { Designations }, city, province, storedAddress, showLoadingPanel, addNewMode
    } = this.state;
    return (
      <>
        {this.renderShowPopup()}
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position="center"
          visible={showLoadingPanel}
        />
        <Tabs
          dataSource={tabs}
          selectedIndex={selectedTabIndex}
          onOptionChanged={this.onTabsSelectionChanged}
        />
        <br />
        <div className={(selectedTabIndex === 0) ? 'tab-form-container-visible' : 'tab-container-invisible'}>
          <ScrollView ref={(ref) => { this.formScrollView = ref; }}>
            <Form
              ref={(ref) => { this.form = ref; }}
              scrollingEnabled={false}
              formData={rowData}
              {...appConst.defaultFormOptions}
            >
              <GroupItem caption="Personal Information" colCount={4}>
                <SimpleItem
                  dataField="FirstName"
                  editorType="dxTextBox"
                  editorOptions={{
                    valueChangeEvent: 'keyup',
                    onValueChanged: (e) => {
                      this.changeEditFormTitle();
                    },
                  }}
                >
                  <RequiredRule message="First name is required" />
                </SimpleItem>
                <SimpleItem
                  dataField="LastName"
                  editorOptions={{
                    valueChangeEvent: "keyup",
                    onValueChanged: (e) => {
                      this.changeEditFormTitle();
                    },
                  }}
                />
                <SimpleItem
                  dataField="PreferredFirstName"
                  editorOptions={{
                    valueChangeEvent: "keyup",
                    onValueChanged: (e) => {
                      if (typeof e.event === "undefined" && e.value === null) return;
                      this.changeEditFormTitle();
                    },
                  }}
                />
                <SimpleItem dataField="PreviousLastName" />
                <SimpleItem dataField="PreviousFirstName" />
                <SimpleItem
                  editorType="dxSelectBox"
                  dataField="SalutationId"
                  editorOptions={{ dataSource: salutationsData, ...appConst.defaultSelectBoxOptions }}
                >
                  <Label text="Salutation" />
                </SimpleItem>
                <SimpleItem
                  editorType="dxSelectBox"
                  dataField="GenderId"
                  editorOptions={{ dataSource: gendersData, ...appConst.defaultSelectBoxOptions }}
                >
                  <Label text="Gender" />
                </SimpleItem>
                <SimpleItem
                  editorType="dxSelectBox"
                  dataField="PronounsId"
                  editorOptions={{ dataSource: pronounsData, ...appConst.defaultSelectBoxOptions }}
                >
                  <Label text="Pronouns" />
                </SimpleItem>
                <SimpleItem
                  editorType="dxDateBox"
                  dataField="BirthDate"
                  editorOptions={{ ...appConst.defaultSelectDateBoxOptions }}
                />
                <SimpleItem
                  editorType="dxDateBox"
                  dataField="DateVaccinationRecordVerified"
                  editorOptions={{ ...appConst.defaultSelectDateBoxOptions }}
                >
                  <Label text="Vaccination Verified Date" />
                </SimpleItem>
              </GroupItem>
              <GroupItem caption="Contact Information" colCount={6}>
                <Item colSpan={3}>
                  <Label text="Address1" />
                  <div style={{ position: 'relative', width: '100%' }}>
                    <AddressAutoComplete
                      autoCompleteTextBoxId={"autoCompleteTextBoxId"}
                      updateAddressFields={this.updateAddressFields}
                      value={rowData && rowData.Address1}
                      onValueChanged={(value) => {
                        this.setState({ rowData: { ...rowData, Address1: value } });
                      }}
                      city={city}
                      province={province}
                      addressVerified={rowData.AddressVerified}
                      setAddressVerified={this.setAddressVerified}
                    />
                  </div>
                </Item>
                <SimpleItem dataField="City" editorOptions={{
                  ...appConst.defaultAddressInput,
                  onValueChanged: (e) => {
                    if (typeof e.event === "undefined" && e.value === null) return;
                    this.setState({ city: e.value.trim() }, () => compareAddresses(e, rowData, storedAddress, this.setAddressVerified));
                  },
                }} />
                <SimpleItem
                  editorType="dxSelectBox"
                  dataField="ProvinceId"
                  editorOptions={{
                    dataSource: provincesData, ...appConst.defaultSelectBoxOptions,
                    grouped: false,
                    onValueChanged: (e) => {
                      if (typeof e.event === "undefined" && e.value === null) return;
                      this.setState({ province: e.component.option('text') }, () => compareAddresses(e, rowData, storedAddress, this.setAddressVerified));
                    },
                  }}
                >
                  <Label text="Province" />
                </SimpleItem>
                <SimpleItem dataField="PostalCode" editorType="dxTextBox"
                  editorOptions={{
                    ...appConst.defaultAddressInput,
                    onValueChanged: (e) => {
                      if (typeof e.event === "undefined" && e.value === null) return;
                      compareAddresses(e, rowData, storedAddress, this.setAddressVerified);
                    },
                  }}>
                  <PatternRule
                    pattern={appConst.postalCodePattern}
                    message={appConst.postalCodeValidationMessage}
                  />
                </SimpleItem>
                <SimpleItem
                  dataField="Ward"
                >
                  <Label
                    text="Ward"
                  />
                  <PatternRule
                    pattern={appConst.schoolWardPattern}
                    message={appConst.schoolWardValidationMessage}
                  />
                </SimpleItem>
                <SimpleItem
                  editorType="dxSelectBox"
                  dataField="RidingId"
                  editorOptions={
                    {
                      dataSource: ridingsData,
                      ...appConst.defaultSelectBoxOptions,
                    }
                  }
                >
                  <Label text="Riding" />
                </SimpleItem>
                <EmptyItem />
                <EmptyItem />
              </GroupItem>
              <GroupItem name="EmailsAndPhones" colCount={2}>
                <GroupItem name="Emails" colCount={1}>
                  {rowData.EmailAddresses && rowData.EmailAddresses.map((email, index) => (
                    <GroupItem key={`EmailGroup ${index + 1}`} colCount={10}>
                      <SimpleItem
                        key={`Emails${email.Id}`}
                        colSpan={4}
                        dataField={`EmailAddresses[${index}].Address`}
                      >
                        <Label text={`Email ${index + 1}`} />
                        <RequiredRule />
                        <PatternRule
                          pattern={appConst.emailPattern}
                          message={appConst.emailValidationMessage}
                        />
                      </SimpleItem>
                      <SimpleItem
                        key={`PreferredEmails${email.Id}`}
                        dataField={`EmailAddresses[${index}].Preferred`}
                        editorType="dxSwitch"
                        verticalAlignment="bottom"
                        editorOptions={{
                          onValueChanged: () => {
                            this.form
                              && setPreferredEmailAddress(index, this.form.instance);
                          },
                        }}
                      >
                        <Label text="Preferred" />
                      </SimpleItem>
                      <SimpleItem
                        key={`EmailTypes${email.Id}`}
                        colSpan={2}
                        editorType="dxSelectBox"
                        dataField={`EmailAddresses[${index}].EmailTypeId`}
                        editorOptions={
                          {
                            dataSource: emailAddressTypesData,
                            ...appConst.defaultSelectBoxOptions,
                            grouped: false,
                          }
                        }
                      >
                        <Label text={`Email Type ${index + 1}`} />
                      </SimpleItem>
                      <SimpleItem colSpan={2}>
                        <ExternalMailSystem
                          statusMessage={email.StatusMessage} hasError={email.HasError}
                          addNewMode={addNewMode} entityId={email.EmailAddressId} entityType="EmailAddress" />
                      </SimpleItem>
                      <SimpleItem
                        key={`EmailRemoveButton${email.Id}`}
                        itemType="button"
                        horizontalAlignment="left"
                        verticalAlignment="bottom"
                        buttonOptions={{ ...this.removeEmailButtonOptions(`${index}`) }}
                      />
                    </GroupItem>
                  ))}
                  <SimpleItem
                    itemType="button"
                    horizontalAlignment="left"
                    buttonOptions={this.addEmailButtonOptions}
                  />
                </GroupItem>
                <GroupItem name="Phones" colCount={1}>
                  {rowData.PhoneNumbers && rowData.PhoneNumbers.map((phone, index) => (
                    <GroupItem key={`phoneGroup ${index + 1}`} colCount={6}>
                      <SimpleItem
                        key={`Phones${phone.Id}`}
                        colSpan={2}
                        dataField={`PhoneNumbers[${index}].Number`}
                        editorOptions={appConst.phoneEditorOptions}
                      >
                        <Label text={`Phone ${index + 1}`} />
                        <RequiredRule />
                        <PatternRule
                          pattern={appConst.phone10DigitsPattern}
                          message={appConst.phoneEditorOptions.message}
                        />
                      </SimpleItem>
                      <SimpleItem
                        key={`PreferredPhones${phone.Id}`}
                        dataField={`PhoneNumbers[${index}].Preferred`}
                        editorType="dxSwitch"
                        verticalAlignment="bottom"
                        editorOptions={{
                          onValueChanged: () => {
                            this.form
                              && setPreferredPhoneNumber(index, this.form.instance);
                          },
                        }}
                      >
                        <Label text="Preferred" />
                      </SimpleItem>
                      <SimpleItem
                        key={`PhoneTypes${phone.Id}`}
                        colSpan={2}
                        editorType="dxSelectBox"
                        dataField={`PhoneNumbers[${index}].PhoneTypeId`}
                        editorOptions={
                          {
                            dataSource: phoneTypesData,
                            ...appConst.defaultSelectBoxOptions,
                            grouped: false,
                          }
                        }
                      >
                        <Label text={`Phone Type ${index + 1}`} />
                      </SimpleItem>
                      <SimpleItem
                        key={`PhoneRemoveButton${phone.Id}`}
                        itemType="button"
                        horizontalAlignment="left"
                        verticalAlignment="bottom"
                        buttonOptions={this.removePhoneButtonOptions(`${index}`)}
                      />
                    </GroupItem>
                  ))}
                  <SimpleItem
                    itemType="button"
                    horizontalAlignment="left"
                    buttonOptions={this.addPhoneButtonOptions}
                  />
                </GroupItem>
              </GroupItem>
              <GroupItem caption="Membership and Employment Information" colCount={5}>
                <SimpleItem
                  editorType="dxDateBox"
                  dataField="StartDate"
                  editorOptions={{ ...appConst.defaultSelectDateBoxOptions }}
                >
                  <Label text="Start Date" />
                </SimpleItem>
                <SimpleItem editorType="dxSelectBox" dataField="MembershipTypeId" editorOptions={{ dataSource: membershipTypesData, ...appConst.defaultSelectBoxOptions }}>
                  <Label text="Membership Type" />
                </SimpleItem>
                <SimpleItem editorType="dxSelectBox" dataField="EmploymentStatusId" editorOptions={{
                  dataSource: employmentStatusesData,
                  ...appConst.defaultSelectBoxOptions,
                  onValueChanged: (e) => {
                    if (typeof e.event === 'undefined') return;

                    const selectedItem = e.component.option("selectedItem");
                    if (selectedItem.Confirm) {
                      this.setState({ showChangeEmploymentStatusConfirmPopup: true, previousEmploymentStatusId: e.previousValue })
                    }
                  }
                }}>
                  <Label text="Employment Status" />
                </SimpleItem>
                <SimpleItem editorType="dxSelectBox" dataField="ContractTypeId" editorOptions={{
                  dataSource: contractTypesData,
                  ...appConst.defaultSelectBoxOptions,
                }}>
                  <Label text="Contract Type" />
                </SimpleItem>
                <SimpleItem editorType="dxTextBox" dataField="CertificateNumber">
                  <Label text="CertificateNumber" />
                </SimpleItem>
                <SimpleItem
                  editorType="dxDateBox"
                  dataField="DateInducted"
                  editorOptions={{ ...appConst.defaultSelectDateBoxOptions }}
                >
                  <Label text="Date Inducted" />
                </SimpleItem>
                <SimpleItem
                  editorType="dxTextBox"
                  dataField="EmployeeId"
                >
                  <Label text="Employee ID" />
                </SimpleItem>
                <SimpleItem
                  editorType="dxTextBox"
                  dataField="EpsId"
                >
                  <Label text="EPS ID" />
                </SimpleItem>
                <SimpleItem
                  editorType="dxTextBox"
                  dataField="ImportId"
                  editorOptions={{ readOnly: true }}
                >
                  <Label text="Import Id" />
                </SimpleItem>
              </GroupItem>
              <GroupItem name="Designations" colCount={1} caption="Designations">
                {Designations && Designations.map((designation, index) => (
                  <GroupItem key={`DesignationGroup ${index + 1}`} colCount={8}>
                    <SimpleItem
                      key={`Designation${designation.Id}`}
                      editorType="dxSelectBox"
                      dataField={`Designations[${index}].DesignationId`}
                      editorOptions={{
                        dataSource: designationsData,
                        ...appConst.defaultSelectBoxOptions,
                        grouped: true,
                      }}
                    >
                      <Label text={`Designation ${index + 1}`} />
                      <RequiredRule message="Designation is required" />
                    </SimpleItem>
                    <SimpleItem
                      key={`DesignationStartDate${designation.Id}`}
                      editorType="dxDateBox"
                      editorOptions={{ ...appConst.defaultSelectDateBoxOptions }}
                      dataField={`Designations[${index}].StartDate`}
                    >
                      <Label text="Start Date" />
                    </SimpleItem>
                    <SimpleItem
                      key={`DesignationEndDate${designation.Id}`}
                      editorType="dxDateBox"
                      editorOptions={{ ...appConst.defaultSelectDateBoxOptions }}
                      dataField={`Designations[${index}].EndDate`}
                    >
                      <Label text="End Date" />
                    </SimpleItem>
                    <SimpleItem
                      colSpan={3}
                      key={`DesignationNotes${designation.Id}`}
                      editorType="dxTextBox"
                      dataField={`Designations[${index}].Notes`}
                    >
                      <Label text="Notes" />
                    </SimpleItem>
                    <SimpleItem
                      key={`DesignationRemoveButton${designation.Id}`}
                      itemType="button"
                      horizontalAlignment="left"
                      verticalAlignment="bottom"
                      buttonOptions={this.removeDesignationOptions(`${index}`)}
                    />
                  </GroupItem>
                ))}
              </GroupItem>
              <SimpleItem
                itemType="button"
                horizontalAlignment="left"
                buttonOptions={this.addDesignationButtonOptions}
              />
            </Form>
          </ScrollView>
          {this.toolbarButtons()}
        </div>
        <div className={(selectedTabIndex === 1) ? 'tab-form-container-visible' : 'tab-container-invisible'}>
          <ScrollView>
            <Form>
              <GroupItem name="Schools" colCount={1}>
                <Accordion
                  ref={(ref) => { this.schoolsAccordion = ref; }}
                  name="schoolsAccordion"
                  dataSource={Schools}
                  itemRender={this.renderSchoolAccordionItem}
                  itemTitleRender={this.renderSchoolAccordionTitle}
                  multiple={true}
                />
              </GroupItem>
              <GroupItem>
                <SimpleItem
                  itemType="button"
                  horizontalAlignment="left"
                  buttonOptions={this.addSchoolButtonOptions}
                />
              </GroupItem>
            </Form>
          </ScrollView>
          {this.toolbarButtons()}
        </div>
        <div className={(selectedTabIndex === 3) ? 'tab-form-container-visible' : 'tab-container-invisible'}>
          <FileDialogPopup
            showFileNameDialog={this.state.showFileNameDialogForConfidential}
            gridRef={this.confidentialAttachmentsGrid && this.confidentialAttachmentsGrid.instance}
            uploadRef={this.confidentialAttachmentUploadFile && this.confidentialAttachmentUploadFile.instance}
            newFileName={this.state.newFileName}
            fileCreationDate={this.state.fileCreationDate}
            file={this.state.file}
            onFileUploaded={this.onFileUploaded}
            setReplaceValue={this.setReplaceValue}
            hideFileDialogPopup={this.hideFileDialogPopup}
          />
          <FileDialogPopup
            showFileNameDialog={this.state.showFileNameDialog}
            gridRef={this.attachmentsGrid && this.attachmentsGrid.instance}
            uploadRef={this.attachmentUploadFile && this.attachmentUploadFile.instance}
            newFileName={this.state.newFileName}
            fileCreationDate={this.state.fileCreationDate}
            file={this.state.file}
            onFileUploaded={this.onFileUploaded}
            setReplaceValue={this.setReplaceValue}
            hideFileDialogPopup={this.hideFileDialogPopup}
          />
          <ScrollView className="tab-scrolling">
            <div className="attachment-type-title">Confidential Attachments</div>
            <FileUploader
              ref={(ref) => { this.confidentialAttachmentUploadFile = ref; }}
              name="file"
              accept={appConst.acceptFileToUpload}
              uploadUrl={`${URL}/UploadConfidentialAttachment?Id=${rowData.Id}&replace=${this.state.replaceFile}`}
              uploadMode="instantly"
              onInitialized={async (e) => {
                const o = {};
                await SetAuthorizationHeaderAsync(o);
                e.component.option('uploadHeaders', o.headers);
              }}
              onUploaded={(e) => {
                this.onFileUploaded(this.confidentialAttachmentsGrid.instance, this.confidentialAttachmentUploadFile.instance, e.file.name);
              }}
              onValueChanged={(e) => {
                if (e.value.length > 0) {
                  this.setState({
                    newFileName: e.value[0].name,
                    file: e.value[0],
                  });
                }
              }}
              onUploadError={(e) => {
                if (e.error.responseText.includes('FileExists')) {
                  const cd = e.error.responseText.split('|')[1];
                  this.setState({ showFileNameDialogForConfidential: true, fileCreationDate: cd });
                  e.request.abort();
                  e.component.reset();
                }
              }}
            />
            <DataGrid
              id="confidentialAttachmentsGrid"
              ref={(ref) => { this.confidentialAttachmentsGrid = ref; }}
              dataSource={this.confidentialAttachmentDataSource()}
              {...appConst.defaultAttachmentGridOptions}
              onRowRemoving={this.attachmentsGridOnRowRemoving}
              onRowDblClick={(e) => {
                this.openAttachment(e.data.Name);
              }}
            >
              <Paging
                enabled={false}
              />
              <Editing
                mode="Row"
                allowDeleting
              />
              <Column
                dataField="Name"
                dataType="string"
                cellRender={(data) => (
                  <a href={`${window.env.documentViewerAppUri}/?fileName=${data.row.data.Name}`} target="_blank" rel="noreferrer noopener">
                    {data.row.data.FileName}
                  </a>
                )}
              />
              <Column dataField="DateUploaded" dataType="datetime" dateDisplayFormat={appConst.dateAndTimeDisplayFormat} />
              <Column dataField="Size" dataType="Number" />
            </DataGrid>
            <div className="attachment-type-title">Regular Attachments</div>
            <FileUploader
              ref={(ref) => { this.attachmentUploadFile = ref; }}
              name="file"
              accept={appConst.acceptFileToUpload}
              uploadUrl={`${URL}/UploadAttachment?Id=${rowData.Id}&replace=${this.state.replaceFile}`}
              uploadMode="instantly"
              onInitialized={async (e) => {
                const o = {};
                await SetAuthorizationHeaderAsync(o);
                e.component.option('uploadHeaders', o.headers);
              }}
              onUploaded={(e) => {
                this.onFileUploaded(this.attachmentsGrid.instance, this.attachmentUploadFile.instance, e.file.name);
              }}
              onValueChanged={(e) => {
                if (e.value.length > 0) {
                  this.setState({
                    newFileName: e.value[0].name,
                    file: e.value[0],
                  });
                }
              }}
              onUploadError={(e) => {
                if (e.error.responseText.includes('FileExists')) {
                  const cd = e.error.responseText.split('|')[1];
                  this.setState({ showFileNameDialog: true, fileCreationDate: cd });
                  e.request.abort();
                  e.component.reset();
                }
              }}
            />
            <DataGrid
              id="attachmentsGrid"
              ref={(ref) => { this.attachmentsGrid = ref; }}
              dataSource={this.attachmentDataSource()}
              {...appConst.defaultAttachmentGridOptions}
              onRowRemoving={this.attachmentsGridOnRowRemoving}
              onRowDblClick={(e) => {
                this.openAttachment(e.data.Name);
              }}
            >
              <Paging
                enabled={false}
              />
              <Editing
                mode="Row"
                allowDeleting
              />
              <Column
                dataField="Name"
                dataType="string"
                cellRender={(data) => (
                  <a href={`${window.env.documentViewerAppUri}/?fileName=${data.row.data.Name}`} target="_blank" rel="noreferrer noopener">
                    {data.row.data.FileName}
                  </a>
                )}
              />
              <Column dataField="DateUploaded" dataType="datetime" dateDisplayFormat={appConst.dateAndTimeDisplayFormat} />
              <Column dataField="Size" dataType="Number" />
            </DataGrid>
          </ScrollView>
        </div>
        <div className={(selectedTabIndex === 2) ? 'tab-form-container-visible' : 'tab-container-invisible'}>
          <CommitteeGrid
            personId={rowData.Id}
            ref="committeeGrid"
          />
        </div>
        <div className={(selectedTabIndex === 4) ? 'tab-form-container-visible' : 'tab-container-invisible'}>
          <DataGrid
            id="fundsGrid"
            ref={(ref) => { this.fundsGrid = ref; }}
            dataSource={this.fundsDataSource()}
            {...appConst.defaultGridOptions}
            onRowRemoving={this.attachmentsGridOnRowRemoving}
            onEditorPreparing={this.onEditorPreparingFundsGrid}
          >
            {/* ToDo we should figure out a way to set the height of the grid
                            * and re-enable the virtual scrolling.
                        <Scrolling
                            mode="virtual"
                            rowRenderingMode="virtual" /> */}
            <Editing
              mode="row"
              allowAdding
              allowDeleting
              allowUpdating
            />
            <Column
              dataField="FundDate"
              dataType="date"
              caption="Fund Date"
              format={appConst.dateDisplayFormat}
              sortOrder="desc"
              sortIndex="0"
            />
            <Column dataField="FundTypeId" dataType="number" caption="Type" editCellRender={this.selectBoxFundTypeColumnTemplate}>
              <Lookup dataSource={fundTypesData.store()} valueExpr="Value" displayExpr="Text" />
            </Column>
            <Column dataField="FundAmount" dataType="number" caption="Amount" format={appConst.currencyFormat} />
          </DataGrid>
        </div>
        <div className={(selectedTabIndex === 5) ? 'tab-form-container-visible' : 'tab-container-invisible'}>
          <PersonCallLogGrid
            ref="personCallLogGrid"
            Id={rowData.Id}
          />
        </div>
      </>
    );
  }

  // This function is to remove the school when trash button is clicked.
  removeSchool(index) {
    const result = confirm(appConst.confirmDialogContent, appConst.confirmDeleteDialogTitle);
    result.then((dialogResult) => {
      if (dialogResult) {
        const { Schools } = this.state;
        const tmpState = [...Schools];
        tmpState.splice(index, 1);
        this.setState({ Schools: tmpState });
      }
    });
  }

  // To remove the CSR role of a member.
  removeCSR(index) {
    // Confirm dialog.
    const result = confirm(appConst.confirmDialogContent, appConst.confirmDeleteDialogTitle);
    result.then((dialogResult) => {
      if (dialogResult) {
        const selectedIndex = this.schoolsAccordion.instance.option('selectedIndex');
        const { Schools } = this.state;
        const tmp = [...Schools];
        tmp[selectedIndex].CSRRoleMembers.splice(index, 1);
        this.setState({ Schools: tmp });
      }
    });
  }

  // To add a new CSR role.
  addCSRRole() {
    const selectedIndex = this.schoolsAccordion.instance.option('selectedIndex');
    const { Schools } = this.state;
    const tmpState = [...Schools];
    // We create a new CSRRoleMembers array when the school is new.
    if (typeof (tmpState[selectedIndex].CSRRoleMembers) === 'undefined') {
      tmpState[selectedIndex].CSRRoleMembers = [];
    }
    tmpState[selectedIndex].CSRRoleMembers.push({});
    this.setState({ Schools: tmpState });
  }
}

EditMemberForm.propTypes = {
  gendersData: PropTypes.object.isRequired,
  pronounsData: PropTypes.object.isRequired,
  salutationsData: PropTypes.object.isRequired,
  designationsData: PropTypes.object.isRequired,
  emailAddressTypesData: PropTypes.array.isRequired,
  phoneTypesData: PropTypes.array.isRequired,
  contractTypesData: PropTypes.object.isRequired,
  employmentStatusesData: PropTypes.object.isRequired,
  membershipTypesData: PropTypes.object.isRequired,
  rowData: PropTypes.object.isRequired,
  addNewMode: PropTypes.bool.isRequired,
  hideEditPopupAndRefreshGrid: PropTypes.func.isRequired,
  hideEditPopup: PropTypes.func.isRequired,
};
export default EditMemberForm;
