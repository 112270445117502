import React from 'react';
import Form, {
  GroupItem,
  SimpleItem,
  Label,
  RequiredRule,
} from 'devextreme-react/form';
import PropTypes from 'prop-types';
import { CreateAuthorizedDataSource } from '../../helpers/data';
import { appConst } from '../../AppConst';
import { setFocusOnEditor, setFocusOnFirstInput } from '../../helpers/ui';
import { strings } from '../../locale';

class SchoolItem extends React.Component {
  addCSRRoleButtonOptions = {
    text: 'Add COR Roles',
    onClick: () => {
      const { addCSRRole } = this.props;
      addCSRRole();
    },
  }

  constructor(props) {
    super(props);
    this.removeCSRRoleButtonOptions = this.removeCSRRoleButtonOptions.bind(this);

    this.schoolsData = CreateAuthorizedDataSource(
      { loadUrl: `${window.env.apiEndpoint}/api/v1/Schools/SchoolsLookup` },
      null,
      'Value',
      null,
      'Header',
      'Text',
    );

    this.divisonsData = CreateAuthorizedDataSource(
      { loadUrl: `${window.env.apiEndpoint}/api/v1/Divisions/DivisionsLookup` },
      null,
      'Value',
      false,
      'Header',
    );

    this.CSRsData = CreateAuthorizedDataSource(
      { loadUrl: `${window.env.apiEndpoint}/api/v1/CSRRoles/CSRRolesLookup` },
      null,
      'Value',
      false,
      'Header',
    );
  }

  componentWillUnmount() {
    if (this.schoolsData) {
      this.schoolsData.dispose();
    }

    if (this.divisonsData) {
      this.divisonsData.dispose();
    }

    if (this.CSRsData) {
      this.CSRsData.dispose();
    }
  }

  removeCSRRoleButtonOptions = (index) => ({
    icon: 'trash',
    onClick: () => {
      const { removeCSR } = this.props;
      removeCSR(index);
    },
  })

  render() {
    const { dataForm } = this.props;
    return (
      <Form
        id="schoolForm"
        formData={dataForm}
        onContentReady={
          (e) => {
            if (dataForm.CSRRoleMembers) {
              setFocusOnEditor(e.component, `CSRRoleMembers[${dataForm.CSRRoleMembers.length - 1}].RoleId`);
            } else {
              setFocusOnFirstInput(e.component.element());
            }
          }
        }
      >
        <GroupItem caption="" colCount={1}>
          <SimpleItem editorType="dxCheckBox" dataField="Primary" />
        </GroupItem>
        <GroupItem caption="" colCount={4}>
          <SimpleItem
            editorType="dxSelectBox"
            dataField="SchoolId"
            editorOptions={{ dataSource: this.schoolsData, ...appConst.defaultSelectBoxOptions }}
          >
            <Label text="School" />
            <RequiredRule message="School is required." />
          </SimpleItem>
          <SimpleItem
            editorType="dxSelectBox"
            dataField="DivisionId"
            editorOptions={{ dataSource: this.divisonsData, ...appConst.defaultSelectBoxOptions }}
          >
            <Label text="Division" />
          </SimpleItem>
          <SimpleItem
            editorType="dxDateBox"
            dataField="StartDate"
            editorOptions={{ ...appConst.defaultSelectDateBoxOptions }}
          >
            <Label text="Start Date" />
          </SimpleItem>
          <SimpleItem
            editorType="dxDateBox"
            dataField="EndDate"
            editorOptions={{ ...appConst.defaultSelectDateBoxOptions }}
          >
            <Label text="End Date" />
          </SimpleItem>
          <SimpleItem editorType="dxTextBox" dataField="Id" visible={false} />
        </GroupItem>
        <GroupItem name="CSRRoles" caption={`${strings.cor} Roles`}>
          {dataForm.CSRRoleMembers && dataForm.CSRRoleMembers.map((csrRole, index) => (
            <GroupItem colCount={4} key={`CSRRole${index}`}>
              <SimpleItem
                key={`CSRRoleId${index}`}
                editorType="dxSelectBox"
                dataField={`CSRRoleMembers[${index}].RoleId`}
                editorOptions={{ dataSource: this.CSRsData, ...appConst.defaultSelectBoxOptions }}
              >
                <Label text="Role" />
                <RequiredRule message="COR role is required" />
              </SimpleItem>
              <SimpleItem
                key={`CSRRoleStartDate${index}`}
                editorType="dxDateBox"
                editorOptions={{ ...appConst.defaultSelectDateBoxOptions }}
                dataField={`CSRRoleMembers[${index}].StartDate`}
              >
                <Label text="Start Date" />
              </SimpleItem>
              <SimpleItem
                key={`CSRRoleEndDate${index}`}
                editorType="dxDateBox"
                editorOptions={{ ...appConst.defaultSelectDateBoxOptions }}
                dataField={`CSRRoleMembers[${index}].EndDate`}
              >
                <Label text="End Date" />
              </SimpleItem>
              <SimpleItem
                itemType="button"
                key={`RemoveCSR${index}`}
                horizontalAlignment="left"
                verticalAlignment="bottom"
                buttonOptions={this.removeCSRRoleButtonOptions(`${index}`)}
              />
            </GroupItem>
          ))}
        </GroupItem>
        <GroupItem colCount={1}>
          <SimpleItem
            itemType="button"
            horizontalAlignment="left"
            buttonOptions={this.addCSRRoleButtonOptions}
          />
        </GroupItem>
      </Form>
    );
  }
}

SchoolItem.propTypes = {
  addCSRRole: PropTypes.func.isRequired,
  removeCSR: PropTypes.func.isRequired,
  dataForm: PropTypes.object.isRequired,
};
export default SchoolItem;
