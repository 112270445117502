import React from 'react';
import { Autocomplete } from 'devextreme-react/autocomplete';

export default function (props) {
  return (
    <Autocomplete
      dataSource={props.dataSource}
      valueExpr="FullName"
      searchExpr={['PreferredFirstName', 'FirstName', 'LastName', 'FullName']}
      maxItemCount={250}
      itemRender={props.itemRender}
      placeholder="Type Preferred Name, First Name or Last Name..."
      onItemClick={(e) => {
        props.setId(e.component.option("selectedItem").Id);
      }}
    />);
}