import React, { useState, useEffect, Fragment } from 'react';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { Form, GroupItem, SimpleItem, EmptyItem, RequiredRule } from 'devextreme-react/form';
import Tabs from 'devextreme-react/tabs';
import notify from 'devextreme/ui/notify';
import { appConst } from '../../../AppConst';
import SaveAndCancelButtons from '../../../components/save-and-cancel-buttons/SaveAndCancelButtons';
import {
  DataGrid,
  Column,
  Editing,
  Lookup,
  HeaderFilter,
  Selection,
  SearchPanel,
} from 'devextreme-react/data-grid';
import { CreateAuthorizedDataSource, CreateAuthorizedStore } from '../../../helpers/data';
import { SelectBox } from 'devextreme-react/select-box';
import DxForm from "devextreme/ui/form";
import { LoadPanel } from 'devextreme-react/load-panel';

const tabs = [
  {
    id: 0,
    text: `School Year`,
    icon: 'fas fa-calendar',
  },
  {
    id: 1,
    text: 'Distribution Lists',
    icon: 'fas fa-mail-bulk',
  },
];

export default function (props) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [formData, setFormData] = useState({});
  const [showCopyDialog, setShowCopyDialog] = useState(false);
  const [selectedCommittees, setSelectedCommittees] = useState([]);
  const [copyToSchoolYearId, setCopyToSchoolYearId] = useState(0);
  const [copyToSchoolYearText, setCopyToSchoolYearText] = useState();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [committeesToConfirm, setCommitteesToConfirm] = useState([]);
  const [showLoadPanel, setShowLoadPanel] = useState(false);
  const [addNewMode, setAddNewMode] = useState();

  const { visible, hidePopup, rowData, refreshGrid } = props;

  useEffect(() => {
    setFormData(rowData);
    setAddNewMode(props.addNewMode);
  }, [rowData, props.addNewMode])

  const committeeDistributionListData = CreateAuthorizedDataSource(
    {
      loadUrl: `${window.env.apiEndpoint}/api/v1/SchoolYears/GetCommitteeDistributionListForSchoolYear`,
      deleteUrl: `${window.env.apiEndpoint}/api/v1/CommitteeMemberships/DeleteCommitteeDistributionList`
    },
    {
      load: { schoolYearId: rowData.Id },
    },
    'Id', false,
  );

  const emailAddressTypesData = CreateAuthorizedDataSource(
    { loadUrl: `${window.env.apiEndpoint}/api/v1/Members/EmailAddressTypesLookup` },
    null,
    'Value', false,
  );

  function saveData(closePopup) {
    const formEl = document.getElementById("editSchoolYearForm");
    const form = DxForm.getInstance(formEl);
    const v = form.validate();
    if (!v.isValid) return false;

    const URL = `${window.env.apiEndpoint}/api/v1/SchoolYears`;
    const store = new CreateAuthorizedStore({
      insertUrl: `${URL}/Post`,
      updateUrl: `${URL}/Put`,
    }, null, 'Id');

    if (addNewMode) {
      store.insert(formData).then(
        (schoolYear) => {
          setAddNewMode(false);
          setFormData(schoolYear);
          refreshGrid();
          if (closePopup) hidePopup();
        },
        (error) => {
          notify({ message: error.message, shading: true }, 'error');
        }
      );
    } else {
      store.update(formData.Id, formData).then(
        (response) => {
          if (closePopup) hidePopup();
        },
        (error) => {
          notify({ message: error.message, shading: true }, 'error');
        }
      );
    }
  }
  const copyButtonOptions = {
    text: "Copy...",
    type: "normal",
    icon: "copy",
    disabled: addNewMode,
    onClick: function () {
      setShowCopyDialog(true);
    }
  };

  const URL = `${window.env.apiEndpoint}/api/v1/SchoolYears`;
  const committeesUrl = `${window.env.apiEndpoint}/api/v1/CommitteeMemberships`;

  function renderCopyDialog() {
    return (
      <Fragment>
        <span>
          Copy committee memberships from the
          {' '}
          {formData.Description}
          {' '}
          school year to...
        </span>
        <SelectBox
          dataSource={showCopyDialog ? new CreateAuthorizedDataSource({ loadUrl: `${URL}/Get`, }, null, 'Id', false) : []}
          valueExpr= 'Id'
          displayExpr= 'Description'
          value= {copyToSchoolYearId}
          onValueChanged={(e) => {
            setCopyToSchoolYearId(e.component.option('value'));
            setCopyToSchoolYearText(e.component.option('text'));
          }}
        /> 
        <div style={{ marginTop: "10px", marginBottom: "0px" }}>
          <span>Select one or more committees to copy the membership for. Selected committees: {selectedCommittees && selectedCommittees.length}</span>
        </div>
        <DataGrid
          id="committeesGrid"
          dataSource={showCopyDialog ? new CreateAuthorizedDataSource({ loadUrl: `${committeesUrl}/GetCommittees`, }, null, 'Id', false) : []}
          {...appConst.defaultCommitteeCopyGridOptions}
          height="75%"
          onSelectionChanged={(e) => {
            setSelectedCommittees(e.component.getSelectedRowKeys());
          }}
        >
          <Selection mode="multiple" />
          <SearchPanel
            visible={true}
            width={240}
          />
          <Editing
            mode="row"
            allowAdding={false}
            allowDeleting={false}
            allowUpdating={false}
          />
          <Column
            dataField="Description"
            dataType="string"
            caption="Committee"
          />
        </DataGrid>
        <div className="copyDialogButtons">
          <Button
            style={{ margin: "auto" }}
            text="Copy"
            onClick={
              () => {
                if (copyToSchoolYearId <= 0) {
                  notify("Please select a school year copy destination before you proceed.", "error");
                } else {
                  if (selectedCommittees.length === 0) {
                    notify("Please select committee(s) before you proceed.", "error");
                  } else {
                    const ds = CreateAuthorizedStore(
                      { loadUrl: `${URL}/GetMembershipsForASetOfCommittees` },
                      { load: { Ids: JSON.stringify(selectedCommittees), schoolYearId: copyToSchoolYearId } }
                    );
                    ds.load().then(
                      (result) => {
                        if (result.length > 0) {
                          setShowConfirmationDialog(true);
                          setCommitteesToConfirm(result);
                        } else {
                          handleProceed();
                        }
                      }
                    );
                  }
                }
              }
            }
          />
          <Button
            style={{ margin: "auto" }}
            text="Cancel"
            onClick={
              () => {
                setShowCopyDialog(false);
              }
            }
          />
        </div>
      </Fragment>
    );
  }

  function handleProceed() {
    const dataCopyStore = new CreateAuthorizedStore({
      updateUrl: `${URL}/CopySchoolYear`,
    }, { update: { fromSchoolYearId: formData.Id, toSchoolYearId: copyToSchoolYearId, Ids: JSON.stringify(selectedCommittees) } }, 'Id')

    setShowLoadPanel(true);
    dataCopyStore.update().then(
      (result) => {
        let count = '0';
        if (typeof result !== 'undefined') {
          count = result;
        }
        setShowLoadPanel(false);
        notify({ message: `${count} membership records have been copied.`, shading: true }, 'success');
        setShowConfirmationDialog(false);
        setShowCopyDialog(false);
      },
      (error) => {
        setShowLoadPanel(false);
        notify({ message: error.message, shading: true }, 'error');
      },
    );
  }

  function renderConfirmationDialog() {
    return (
      <>
        <DataGrid
          id="confirmationGrid"
          dataSource={committeesToConfirm}
          {...appConst.defaultCommitteeCopyGridOptions}
          height="90%"
        >
          <Selection mode="none" />
          <SearchPanel
            visible={false}
          />
          <Editing
            mode="row"
            allowAdding={false}
            allowDeleting={false}
            allowUpdating={false}
          />
          <Column
            dataField="Description"
            dataType="string"
            caption="Committee"
          />
          <Column
            dataField="Count"
            dataType="number"
            alignment="left"
            caption="Members"
          />
        </DataGrid>
        <div className="copyDialogButtons">
          <Button
            style={{ margin: "auto" }}
            text="Proceed"
            onClick={
              (e) => {
                handleProceed();
              }
            }
          />
          <Button
            style={{ margin: "auto" }}
            text="Cancel"
            onClick={
              () => {
                setShowConfirmationDialog(false);
              }}
          />
        </div>
      </>);
  }

  return (
    <Popup
      visible={visible}
      title={addNewMode ? "New School Year" : formData.Description }
      onHiding={
        (e) => {
          setSelectedTabIndex(0);
          hidePopup();
        }
      }
    >
      <LoadPanel
        visible={showLoadPanel}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={false}
      />
      <Tabs
        dataSource={tabs}
        selectedIndex={selectedTabIndex}
        onOptionChanged={
          (args) => {
            if (args.name === 'selectedIndex') {
              if (addNewMode && args.value > 0) {
                notify(`School year is not saved yet.`);
              } else {
                setSelectedTabIndex(args.value);
              }
            }
          }
        }
      />
      <div className={(selectedTabIndex === 0) ? 'tab-form-container-visible' : 'tab-container-invisible'}>
        <Form
          formData={formData}
          id="editSchoolYearForm"
        >
          <GroupItem caption="" colCount={3}>
            <SimpleItem
              dataField="StartDate"
              editorType="dxDateBox"
              editorOptions={{ ...appConst.defaultSelectDateBoxOptions }}
            >
              <RequiredRule message="Start date is required" />
            </SimpleItem>
            <SimpleItem
              dataField="EndDate"
              editorType="dxDateBox"
              editorOptions={{ ...appConst.defaultSelectDateBoxOptions }}
            >
              <RequiredRule message="End date is required" />
            </SimpleItem>
            <SimpleItem
                itemType="button"
                horizontalAlignment="left"
                verticalAlignment="bottom"
                buttonOptions={copyButtonOptions}
            />
            <SimpleItem
              dataField="Current"
              editorType="dxCheckBox"
            >
            </SimpleItem>
            <EmptyItem />
            <EmptyItem />
          </GroupItem>
          <GroupItem colCount={1}>
            <SimpleItem>
              <SaveAndCancelButtons saveData={saveData} cancelData={() => hidePopup()} />
            </SimpleItem>
          </GroupItem>
        </Form>
      </div>
      <div className={(selectedTabIndex === 1) ? 'tab-form-container-visible' : 'tab-container-invisible'}>
        <DataGrid
          dataSource={(selectedTabIndex === 1) ? committeeDistributionListData : null}
          {...appConst.defaultGridOptions}
          height="100%"
        >
          <HeaderFilter
            visible
            allowSearch
          />
          <Editing
            mode="row"
            allowAdding={false}
            allowDeleting={true}
            allowUpdating={false}
          />
          <Column
            dataField="EmailAddressTypeId"
            dataType="number"
            caption="Email Address Type"
          >
            <Lookup
              dataSource={(selectedTabIndex === 1) ? emailAddressTypesData.store() : null}
              valueExpr="Value"
              displayExpr="Text"
            />
          </Column>
          <Column
            dataField="Name"
            dataType="text"
            caption="Name"
          />
          <Column
            dataField="EmailAddress"
            dataType="text"
            caption="Email Address"
          >
          </Column>
          <Column
            dataField="AllowMembersToSendEmail"
            dataType="boolean"
          >
          </Column>
          <Column
            dataField="StatusMessage"
          />
        </DataGrid>
      </div>
      <Popup
        width={window.innerWidth / 2}
        showTitle
        title="Copy Committee Memberships"
        dragEnabled={false}
        closeOnOutsideClick={false}
        visible={showCopyDialog}
        onHiding={
          () => {
            setCopyToSchoolYearId(0);
            setSelectedCommittees([]);
            setShowCopyDialog(false);
          }
        }
      >
        {renderCopyDialog()}
      </Popup>
      <Popup
        width={window.innerWidth / 2}
        showTitle
        title={`The following committees have memberships in the ${copyToSchoolYearText} school year.`}
        dragEnabled={false}
        closeOnOutsideClick={false}
        visible={showConfirmationDialog}
        contentRender={renderConfirmationDialog}
        onHiding={
          () => {
            setShowConfirmationDialog(false);
          }
        }
      />
    </Popup>
  );
}