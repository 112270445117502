import React from 'react';
import { Popup } from 'devextreme-react/popup';
import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  Selection,
  SearchPanel,
  ColumnChooser,
  Grouping,
  GroupPanel,
  FilterPanel,
  FilterRow,
  HeaderFilter,
} from 'devextreme-react/data-grid';
import 'whatwg-fetch';
import notify from 'devextreme/ui/notify';
import EditNonMemberForm from './EditNonMemberForm';
import calcGridHeight, { convertToTZ } from '../../helpers/ui';
import AppContext from '../../AppContext';
import {
  CreateAuthorizedDataSource,
  CreateAuthorizedStore,
  createNewCall,
  loadMembersLookupDataAsync,
  disposeMembersLookupData,
} from '../../helpers/data';
import { NameIdentifier } from '../../helpers/miscellaneous';
import { appConst } from '../../AppConst';
import GridLayout from '../../components/grid-layout-component/GridLayout';

const URL = `${window.env.apiEndpoint}/api/v1/NonMembers`;

class NonMembers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditForm: false,
      addNewMode: false,
      editFormTitle: '',
    };

    this.editIconClick = this.editIconClick.bind(this);
    this.callIconClick = this.callIconClick.bind(this);

    this.hideEditPopup = this.hideEditPopup.bind(this);
    this.hideEditPopupAndRefreshGrid = this.hideEditPopupAndRefreshGrid.bind(this);
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.windowResized = this.windowResized.bind(this);
    this.getNonMemberPopupRef = this.getNonMemberPopupRef.bind(this);

    this.dataSource = CreateAuthorizedDataSource(
      {
        loadUrl: `${URL}/Get`,
        deleteUrl: `${URL}/Delete`,
      }, { load: { timeZone: window.env.timeZone } }, 'Id',
    );

    this.salutationsData = CreateAuthorizedDataSource({ loadUrl: `${window.env.apiEndpoint}/api/v1/Salutations/SalutationsLookup` }, null, 'Value', null, 'Header');
    this.pronounsData = CreateAuthorizedDataSource({ loadUrl: `${window.env.apiEndpoint}/api/v1/Pronouns/PronounsLookup` }, null, 'Value', null, 'Header');

    this.emailAddressTypesData = CreateAuthorizedDataSource({ loadUrl: `${URL}/EmailAddressTypesLookup` }, null, 'Value');
    this.phoneTypesData = CreateAuthorizedDataSource({ loadUrl: `${URL}/PhoneNumberTypesLookup` }, null, 'Value');
  }

  async componentDidMount() {
    window.addEventListener('resize', this.windowResized);
    this.setState({ membersLookupData: await loadMembersLookupDataAsync() });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResized);

    if (this.dataSource) {
      this.dataSource.dispose();
    }
    disposeMembersLookupData(this.state.membersLookupData);
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        onClick: () => {
          this.setState({
            showEditForm: true,
            rowData: { EmailAddresses: [], PhoneNumbers: [] },
            addNewMode: true,
            editFormTitle: '(New Non-Member)',
          });
        },
      },
    });
  }

  hideEditPopup() {
    this.setState({
      rowData: {},
      showEditForm: false,
    });
  }

  hideEditPopupAndRefreshGrid() {
    this.hideEditPopup();
    this.nonMembersGrid.instance.refresh()
      .then(() => {
        // Refresh succeeds
      })
      .catch(() => {
        // Error refesh the grid.
      });
  }

  editIconClick(key) {
    const getMemberByIdStore = CreateAuthorizedStore(
      { loadUrl: `${URL}/GetNonMemberById` }, { load: { Id: key } },
    );
    getMemberByIdStore.load().then(
      (result) => {
        this.setState({
          showEditForm: true,
          rowData: { ...result[0] },
          addNewMode: false,
          editFormTitle: NameIdentifier(result[0].PreferredFirstName, result[0].FirstName, result[0].LastName),
        });
      },
    );
  }

  callIconClick(e) {
    const { account, callsInProgress, updateContext } = this.context;
    createNewCall(callsInProgress, updateContext, account, e.row.data, 'Non-Member').then(
      (result) => {

      },
      (error) => {
        notify({ message: error, shading: true }, 'error');
      },
    );
  }

  windowResized() {
    this.nonMembersGrid.instance.option('height', calcGridHeight('NonMembersGrid'));
  }

  getNonMemberPopupRef() {
    return this.editNonMemberFormPopup && this.editNonMemberFormPopup.instance;
  }

  renderPopUp(isEditFormShown) {
    if (isEditFormShown) {
      const { showEditForm, addNewMode, rowData, membersLookupData } = this.state;
      return (
        <Popup
          ref={(ref) => { this.editNonMemberFormPopup = ref; }}
          visible={showEditForm}
          {...appConst.defaultPopupOptions}
          className="editMemberPopup"
          onHiding={(e) => { e.cancel = true; this.refs.editNonMemberForm.cancelData(); }}
          onShown={(e) => { this.refs.editNonMemberForm.changeEditFormTitle(); }}
        >
          <EditNonMemberForm
            ref="editNonMemberForm"
            rowData={rowData}
            hideEditPopupAndRefreshGrid={this.hideEditPopupAndRefreshGrid}
            hideEditPopup={this.hideEditPopup}
            salutationsData={membersLookupData.salutationsData}
            gendersData={membersLookupData.gendersData}
            pronounsData={membersLookupData.pronounsData}
            schoolsData={this.schoolsData}
            emailAddressTypesData={membersLookupData.emailAddressTypesData}
            phoneTypesData={membersLookupData.phoneTypesData}
            addNewMode={addNewMode}
            membershipTypesData={membersLookupData.membershipTypesData}
            employmentStatusesData={membersLookupData.employmentStatusesData}
            contractTypesData={membersLookupData.contractTypesData}
            designationsData={membersLookupData.designationsData}
            getNonMemberPopupRef={this.getNonMemberPopupRef}
            provincesData={membersLookupData.provincesData}
          />
        </Popup>
      );
    }

    return (
      <></>
    );
  }

  render() {
    const { showEditForm } = this.state;
    return (
      <>
        {this.renderPopUp(showEditForm)}
        <div className="title">
          <span>Non-Members</span>
          <GridLayout
            layoutKey="NonMembers"
            gridName="nonMembersGrid"
            pageName="NonMembersPage"
            gridRef={
              () => this.nonMembersGrid.instance
            }
          />
        </div>
        <DataGrid
          id="nonMembersGrid"
          ref={(ref) => { this.nonMembersGrid = ref; }}
          dataSource={this.dataSource}
          {...appConst.defaultGridOptions}
          onToolbarPreparing={this.onToolbarPreparing}
          height={() => calcGridHeight('nonMembersGrid')}
          onRowDblClick={(e) => {
            this.editIconClick(e.key);
          }}
        >
          <Scrolling mode="virtual" rowRenderingMode="virtual" />
          <Selection mode="multiple" />
          <SearchPanel
            visible
            width={240}
            placeholder="Search..."
          />
          <Editing
            mode="popup"
            allowAdding={false}
            allowDeleting
            allowUpdating
          />
          <ColumnChooser
            enabled
            mode="dragAndDrop"
          />
          <Grouping
            allowCollapsing
            autoExpandAll={false}
            contextMenuEnabled
            expandMode="rowClick"
          />
          <GroupPanel
            visible
            allowColumnDragging
          />
          <HeaderFilter
            visible
            allowSearch
          />
          <FilterRow visible />
          <FilterPanel visible />
          <Column
            dataField="PreferredFirstName"
            caption="Preferred First Name"
            sortOrder="asc"
            sortIndex="1"
          />
          <Column
            dataField="LastName"
            caption="Last Name"
            sortOrder="asc"
            sortIndex="0"
          />
          <Column dataField="FirstName" caption="First Name" visible={false} />
          <Column dataField="EmailAddress" caption="Email Address" visible={false} />
          <Column dataField="PreferredEmailType" caption="Preferred Email Type" visible={false} />
          <Column dataField="PhoneNumber" caption="Phone Number" visible={false} />
          <Column dataField="PreferredPhoneType" caption="Preferred Phone Type" visible={false} />
          <Column dataField="Company" caption="Company" visible={false} />
          <Column dataField="Position" caption="Position" visible={false} />
          <Column dataField="Id" visible={false} />
          <Column dataField="FullName" visible={false} />
          <Column dataField="AddressVerified" visible={false} />
          <Column
            dataField="DateVaccinationRecordVerified"
            dataType="datetime"
            caption="Vaccination Verified Date"
            format={appConst.dateDisplayFormat}
            visible={false}
          />
          <Column dataField="HomePhoneNumber" visible={false} />
          <Column dataField="WorkPhoneNumber" visible={false} />
          <Column dataField="MobilePhoneNumber" visible={false} />
          <Column dataField="LastUpdated" dataType="datetime" visible={false} format={appConst.dateAndTimeDisplayFormat}
            calculateCellValue={(data) => {
              return convertToTZ(data.LastUpdated);
            }
          }
          />
          <Column
            type="buttons"
            width={110}
            buttons={[{
              hint: 'Call log',
              icon: 'tel',
              onClick: this.callIconClick,
            },
            {
              hint: 'Edit',
              icon: 'edit',
              onClick: (e) => { this.editIconClick(e.row.key); },
            }, 'delete',
            ]}
          />
        </DataGrid>
      </>
    );
  }
}
NonMembers.contextType = AppContext;
export default NonMembers;
