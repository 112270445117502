import React from 'react';
import {
    DataGrid, Column, Editing, Scrolling, Selection, Lookup, PatternRule
} from 'devextreme-react/data-grid';
import
calcGridHeight from '../../../helpers/ui';
import { CreateAuthorizedDataSource } from '../../../helpers/data'
import { createStore } from 'devextreme-aspnet-data-nojquery';
import Tabs from 'devextreme-react/tabs';
import notify from 'devextreme/ui/notify';
import Form, { GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import { CreateAuthorizedStore, SetAuthorizationHeaderAsync } from '../../../helpers/data'
import { appConst } from '../../../AppConst';

const usersUrl = `${window.env.apiEndpoint}/api/v1/Users`;
const roleUsersUrl = `${window.env.apiEndpoint}/api/v1/UserRoles`;
const rolesUrl = `${window.env.apiEndpoint}/api/v1/Roles`;

const rolesData = CreateAuthorizedStore(
    { loadUrl: `${rolesUrl}/Get` }, null, "Id"
)

const tabs = [
    {
        id: 0,
        text: 'User',
        icon: 'user',
    },
    {
        id: 1,
        text: 'Roles',
        icon: 'group',
    },
];

class EditUserForm extends React.Component {
    constructor(props) {
        super(props);

        this.windowResized = this.windowResized.bind(this);
        this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);
        const { addNewMode, userId } = this.props;
        this.state = {
            selectedTabIndex: 0,
            addNewMode,
            userId,
        };
        this.userRolesDataSource = this.userRolesDataSource.bind(this);
        this.setGridHeight = this.setGridHeight.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.windowResized);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResized);
        if (this.dataSource) {
            this.dataSource.dispose();
        }
    }

    windowResized() {
        this.setGridHeight();
    }

    onTabsSelectionChanged(args) {
        if (args.name === 'selectedIndex') {
            const { addNewMode } = this.state;
            if (!addNewMode) {
                this.setState({
                    selectedTabIndex: args.value,
                });
                this.setGridHeight();
            } else {
                notify('You are not allowed to add roles unless user is saved.');
            }
        }
    }

    saveUserButtonOptions = {
        text: 'Save',
        onClick: () => {
            const { addNewMode } = this.state;
            if (addNewMode) {
                this.userStore.insert().then(
                    (result) => {
                        notify('User has been saved. You can add roles.');
                        this.props.changeTitle(this.userForm.instance.option('formData').Name);
                    }
                );
            } else {
                this.userStore.update().then(
                    (result) => {
                        this.props.changeTitle(this.userForm.instance.option('formData').Name);
                    }
                )
            }
        },
    }

    userStore = createStore({
        updateUrl: `${usersUrl}/Put`,
        insertUrl: `${usersUrl}/Post`,
        key: 'Id',
        onBeforeSend: async (method, ajaxOptions) => {
            await SetAuthorizationHeaderAsync(ajaxOptions);

            if (method === 'update' || method === 'insert') {
                const data = this.userForm.instance.option('formData');
                const jd = JSON.stringify(data);
                ajaxOptions.data.values = jd;
                const { userId } = this.state;
                ajaxOptions.data.key = userId;
            }
        },
        onInserted: (values) => {
            notify('User is saved. You can start adding roles.');
            this.setState({ userId: values, addNewMode: false });
            const { refreshGrid } = this.props;
            refreshGrid();
        },
        onUpdated: () => {
            const { refreshGrid } = this.props;
            refreshGrid();
        },
    });

    userRolesDataSource() {
        return CreateAuthorizedDataSource(
            {
                'loadUrl': `${roleUsersUrl}/GetUserRoles`,
                'insertUrl': `${roleUsersUrl}/PostUserRole`,
                'updateUrl': `${roleUsersUrl}/PutUserRole`,
                'deleteUrl': `${roleUsersUrl}/DeleteUserRole`
            }, { 'load': { 'userId': this.state.userId }, 'insert': { 'userId': this.state.userId } },
            ["UserId", "RoleId"], false);
    }

    setGridHeight() {
        this.rolesGrid.instance.option('height', calcGridHeight('rolesGrid'));
    }

    render() {
        const { selectedTabIndex } = this.state;
        const { rowData, addNewMode } = this.props;
        return (
            <>
                <Tabs
                    dataSource={tabs}
                    selectedIndex={this.state.selectedTabIndex}
                    onOptionChanged={this.onTabsSelectionChanged}
                />
                <div style={{ display: (selectedTabIndex === 0) ? 'block' : 'none' }}>
                    <Form
                        {...appConst.defaultFormOptions}
                        ref={(ref) => { this.userForm = ref; }}
                        formData={rowData}>
                        <GroupItem caption=" " colCount={2}>
                            <SimpleItem editorType="dxTextBox" dataField="Username">
                                <Label text="User Name" />
                            </SimpleItem>
                            <SimpleItem editorType="dxTextBox" dataField="Name">
                                <Label text="Name" />
                            </SimpleItem>
                            <SimpleItem editorType="dxTextBox" dataField="EmailAddress">
                                <Label text="Email Address" />
                                <PatternRule
                                    pattern={appConst.emailPattern}
                                    message={appConst.emailValidationMessage}
                                />
                            </SimpleItem>
                            <SimpleItem editorType="dxCheckBox" dataField="Enabled" editorOptions={{ onContentReady: (e) => { if (addNewMode) e.component.option('value', true); } }}>
                                <Label text="Enabled" />
                            </SimpleItem>
                        </GroupItem>
                        <SimpleItem
                            itemType="button"
                            horizontalAlignment="left"
                            buttonOptions={this.saveUserButtonOptions}
                        />
                    </Form>
                </div>
                <div style={{ display: (selectedTabIndex === 1) ? 'block' : 'none' }}>
                    <DataGrid
                        id="rolesGrid"
                        key={["RoleId", "UserId"]}
                        ref={(ref) => { this.rolesGrid = ref; }}
                        dataSource={this.userRolesDataSource()}
                        {...appConst.defaultGridOptions}
                        onInitNewRow={(e) => { e.data.UserId = this.state.userId }}
                    >
                        <Selection mode="multiple" />
                        <Scrolling
                            mode="virtual"
                            rowRenderingMode="virtual"
                        />
                        <Editing
                            mode="row"
                            allowAdding
                            allowDeleting
                            allowUpdating
                        />
                        <Column dataField="RoleId" caption="Role">
                            <Lookup dataSource={rolesData} valueExpr="Id" displayExpr="Name" />
                        </Column>
                    </DataGrid>
                </div>
            </>
        );
    }
}

export default EditUserForm;
