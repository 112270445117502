import React from 'react';
import Button from 'devextreme-react/button';
import PropTypes from 'prop-types';

class SchoolTitle extends React.Component {
    removeSchoolButtonOptions = {
      icon: 'trash',
      onClick: () => {
        const { removeSchool } = this.props;
        removeSchool();
      },
    }

    render() {
        const { dataForm: { SchoolName }, removeSchool, index } = this.props;
        return (
            <div style={{ display: 'flex' }}>
                <h5 style={{ margin:0 }}>{SchoolName}</h5>
                <Button
                    style={{ marginLeft: 'auto', marginRight:'25px', marginTop:'0px'}}
                    icon='trash'
                    onClick={() => removeSchool(index)}
                />
            </div>
        );
    }
}
SchoolTitle.propTypes = {
  removeSchool: PropTypes.func.isRequired,
  dataForm: PropTypes.object.isRequired,
};
export default SchoolTitle;
