import React from 'react';
import { Button } from 'devextreme-react/button';

export default function (props) {
  const { saveData, cancelData } = props;
  return (
    <div style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
      <Button
        text='Save & Close'
        width={150}
        onClick={
          () => {
            saveData(true);
          }
        }
      />
      <Button
        text='Save'
        width={150}
        onClick={
          () => {
            saveData(false);
          }
        }
      />
      <Button
        text='Cancel'
        width={150}
        onClick={
          () => {
            cancelData();
          }
        }
      />
    </div>
  );
}