import React, { useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import { SetAuthorizationHeaderAsync } from '../../helpers/data';
import { FileUploader } from 'devextreme-react';
import TextBox from 'devextreme-react/text-box';
import { LoadPanel } from 'devextreme-react/load-panel';

const URL = `${window.env.apiEndpoint}/api/v1/Members`;
export default function (props) {
  const [importId, setImportId] = useState();
  const [errorLog, setErrorLog] = useState();
  const [showLoadPanel, setShowLoadPanel] = useState(false);

  return (
    <Popup
      visible={props.visible}
      onHiding={() => props.setShowImportForm(false)}
      height="75%"
      width="50%"
      title="Import sub-teachers"
      onShown={() => setErrorLog('')}
    >
      <div>
        <LoadPanel
          visible={showLoadPanel}
          message="Please, wait..."
        />
        <div className="dx-fieldset">
          <div className="dx-field">
            <div className="dx-field-label">Import Identifier:</div>
            <div className="dx-field-value">
              <TextBox
                showClearButton={true}
                onValueChanged={(e) => {
                  setImportId(e.value);
                }}
              />
            </div>
          </div>
          <div className="dx-field">
            <div className="dx-field-label">File To Upload:</div>
            <div className="dx-field-value">
              <FileUploader
                name="file"
                accept={".xlsx"}
                uploadUrl={(typeof importId === 'undefined') ? `${URL}/ImportSubs` : `${URL}/ImportSubs?importId=${importId}`}
                uploadMode="instantly"
                onInitialized={async (e) => {
                  const o = {};
                  await SetAuthorizationHeaderAsync(o);
                  e.component.option('uploadHeaders', o.headers);
                }}
                onUploaded={(e) => {
                  setShowLoadPanel(false);
                  alert('Import Done...');
                  props.setShowImportForm(false);
                }}
                onValueChanged={(e) => {
                  if (e.value.length > 0) {
                    setShowLoadPanel(true);
                  }
                }}
                onUploadError={(e) => {
                  setShowLoadPanel(false);
                  setErrorLog(e.error.responseText);
                  e.request.abort();
                  e.component.reset();
                }}
              />
            </div>
          </div>
        </div>
        <p>The file now has only four columns and they should have headers: First Name, Last Name, Email Address and EPS ID.</p>
        <p> The import will begin automatically once the file is uploaded.</p>
        <div style={{ overflowY: 'scroll', height: '200px', whiteSpace : 'pre-line' }}>{errorLog && errorLog}</div>
      </div>
    </Popup>
  )
}