import React from "react";
import Button from "devextreme-react/button";
import "./login-form.scss";
import { appConst } from "../../AppConst";
import { Fragment } from "react";
import AppContext from "../../AppContext"

class LoginForm extends React.Component {
  render() {
    return (
      <Fragment>
        <div className={"login-header"}>
          <img className={"logo"} alt="logo" src="/logo.svg" />
          <div className={"title"}>{appConst.appName}</div>
          <div className={"release"}>Release {appConst.release}</div>
          <div>Sign In using your Microsoft account</div>
          <Button
            type={"default"}
            text={"Sign In"}
            onClick={this.onLoginClick}
            width={"100%"}
          />
        </div>
      </Fragment>
    );
  }

  onLoginClick = args => {
    this.props.context.onSignIn();
  };
}

export default props => (
  <AppContext.Consumer>
    {(context) => {
      return <LoginForm {...props} context={context} />
    }}
  </AppContext.Consumer>
)
