import React from 'react';
import {
  DataGrid, Column, Editing, Selection, SearchPanel,
} from 'devextreme-react/data-grid';
import calcGridHeight from '../../../helpers/ui';
import { CreateAuthorizedDataSource } from '../../../helpers/data';
import { appConst } from '../../../AppConst';

const URL = `${window.env.apiEndpoint}/api/v1/Provinces`;

class Provinces extends React.Component {
  constructor(props) {
    super(props);
    this.windowResized = this.windowResized.bind(this);

    this.dataSource = CreateAuthorizedDataSource({
      loadUrl: `${URL}/Get`,
    }, null, 'Id');
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResized);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResized);
    if (this.dataSource) {
      this.dataSource.dispose();
    }
  }

  windowResized() {
    this.grid.instance.option('height', calcGridHeight('provincesgrid'));
  }

  render() {
    return (
      <DataGrid
        id="provincesgrid"
        dataSource={this.dataSource}
        {...appConst.defaultGridOptions}
        height={() => calcGridHeight('provincesgrid')}
      >
        <Selection mode="multiple" />
        <SearchPanel
          visible
          width={240}
          placeholder="Search..."
        />
        <Editing
          mode="row"
          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}
        />
        <Column
          dataField="Code"
          dataType="string"
          sortOrder="asc"
          sortIndex="0"
        />
        <Column
          dataField="Name"
          dataType="string"
        />
      </DataGrid>
    );
  }
}

export default Provinces;
