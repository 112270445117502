import React from 'react';
import PropTypes from 'prop-types';
import {
  DataGrid, Column, Editing, Scrolling, Selection, SearchPanel, Grouping, GroupPanel,
} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import CurrentSwitch from '../current-switch/current-switch';
import calcGridHeight from '../../helpers/ui';
import AppContext from '../../AppContext';
import './AdminComponent.scss';
import { CreateAuthorizedDataSource } from '../../helpers/data';
import { appConst } from '../../AppConst';

const URL = `${window.env.apiEndpoint}/api/v1`;

class AdminComponent extends React.Component {
  constructor(props) {
    super(props);

    this.toolbarItemRender = this.toolbarItemRender.bind(this);
    this.windowResized = this.windowResized.bind(this);
    const { componentName } = this.props;
    this.dataSource = CreateAuthorizedDataSource(
      {
        loadUrl: `${URL}/${componentName}/Get`,
        updateUrl: `${URL}/${componentName}/Put`,
        deleteUrl: `${URL}/${componentName}/Delete`,
        insertUrl: `${URL}/${componentName}/Post`,
      }, null, 'Id',
    );
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResized);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResized);
    if (this.dataSource) {
      this.dataSource.dispose();
    }
  }

  static onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'switchCurrent',
      },
    );
  }

  toolbarItemRender() {
    return (
      <CurrentSwitch grid={this.grid} />
    );
  }

  windowResized() {
    this.grid.instance.option('height', calcGridHeight('grid'));
  }

  render() {
    const { title } = this.props;

    return (
      <>
        <div className="title">
          <span>{title}</span>
        </div>
        <DataGrid
          id="grid"
          ref={(ref) => { this.grid = ref; }}
          dataSource={this.dataSource}
          {...appConst.defaultAdminGridOptions}
          onToolbarPreparing={AdminComponent.onToolbarPreparing}
          height={() => calcGridHeight('grid')}
        >
          <Selection mode="multiple" />
          <SearchPanel
            visible
            width={240}
            placeholder="Search..."
          />
          <Scrolling
            mode="virtual"
            rowRenderingMode="virtual"
          />
          <Editing
            mode="row"
            allowAdding
            allowDeleting
            allowUpdating
          />
          <Grouping contextMenuEnabled />
          <GroupPanel visible />
          <Column
            dataField="Description"
            dataType="string"
            sortOrder="asc"
            sortIndex="1"
          />
          <Column
            dataField="Current"
            filterValue
            dataType="boolean"
            sortOrder="desc"
            sortIndex="0"
          />
          <Template name="switchCurrent" render={this.toolbarItemRender} />
        </DataGrid>
      </>
    );
  }
}

AdminComponent.propTypes = {
  componentName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

AdminComponent.contextType = AppContext;
export default AdminComponent;
