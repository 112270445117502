import React, { useState, } from 'react';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import notify from 'devextreme/ui/notify';
import List from 'devextreme-react/list';
import TB from 'devextreme/ui/text_box';
import { useAddressVerificationApiService } from '../../services';
import './address-auto-complete.scss';

const debounce = require('debounce');
export default function (props) {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { getAutoCompleteAddresses, getAddressDetails } = useAddressVerificationApiService();
  const { updateAddressFields, city, province, setAddressVerified } = props;

  async function handleChange(e) {
    if (typeof e.event !== 'undefined'
      || typeof process.env.JEST_WORKER_ID !== 'undefined') {
      props.onValueChanged(e.value);
      if (props.addressVerified) setAddressVerified(false);
      if (e.value === '') {
        setItems([]);
      } else {
        setSearchTerm(e.value);
        debounce(await handleSubmit(), 250);
      }
    }
  }

  async function handleSelectionAsync(place_id) {
    try {
      const details = await getAddressDetails(place_id, searchTerm, city, province);
      updateAddressFields({
        address: details.address,
        city: details.city,
        postalCode:details.postal_code,
        province: details.province,
      });
    } catch (error) {
      notify('Error in getting the address.', 'error', 2000);
    }
  }

  async function handleSubmit() {
    let txtEl = document.getElementById(props.autoCompleteTextBoxId);
    let txt = TB.getInstance(txtEl);
    //brad
    //const data = await getAutoCompleteAddresses(props.value);
    //setShowVerifiedAddressIcon(false);
    try {
      const data = await getAutoCompleteAddresses(txt.option('value'), city, province);
      setItems(data);
    } catch (error) {
      notify('Error in getting the addresses.', 'error', 2000);
    }
  }

  function itemRender(item) {
    return (<div className="address-list-item">
      {item.description}
    </div>);
  }

  const { autoCompleteTextBoxId, addressVerified } = props;
  return (
    <div className="address-autocomplete-container-wrapper">
      <div className="address-autocomplete-container">
        <TextBox
          id={autoCompleteTextBoxId}
          showClearButton
          valueChangeEvent="keyup"
          value={props.value}
          onValueChanged={
            async (e) => {
              await handleChange(e)
            }
          }
          onKeyDown={
            (e) => {
              if (e.event.key === "Escape") {
                setItems([]);
              }
              if (e.event.key === "Tab") {
                //ToDO: move the focus to the list when we press tab on the text box.
              }
            }
          }
          inputAttr={{ 'style': "text-transform: uppercase" }}
        >
          <TextBoxButton
            name="addressVerification"
            location="after"
            options={{
              hint: "Address Verified",
              icon: "check",
              type: "default",
              elementAttr: {
              class: addressVerified ? "showVerifiedAddressIcon" : "hideVerifiedAddressIcon"
             }
            }} />
          </TextBox>
        <div className="address-autocomplete-container-address-list">
          <List
            showScrollbar={"always"}
            height={Math.min(300, 35 * (items.length + 1))}
            keyExpr="place_id"
            dataSource={items}
            visible={items.length > 0}
            itemRender={itemRender}
            selectionMode="single"
            onSelectionChanged={
              async (e) => {
                setItems([]);
                if (e.addedItems.length === 0) return;
                await handleSelectionAsync(e.addedItems[0].place_id);
              }
            }
          />
        </div>
      </div>
    </div>
  );
}