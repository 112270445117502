import React from 'react';
import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  Selection,
  SearchPanel,
  EmailRule
} from 'devextreme-react/data-grid';
import calcGridHeight from '../../../helpers/ui';
import './Users.scss';
import { CreateAuthorizedDataSource } from '../../../helpers/data';
import { Popup } from 'devextreme-react/popup';
import EditUserForm from './EditUserForm';
import { appConst } from '../../../AppConst';


const usersUrl = `${window.env.apiEndpoint}/api/v1/Users`;

class Users extends React.Component {
  constructor(props) {
    super(props);

    this.windowResized = this.windowResized.bind(this);
    this.dataSource = CreateAuthorizedDataSource(
      {
        'loadUrl': `${usersUrl}/Get`,
        'updateUrl': `${usersUrl}/Put`,
        'insertUrl': `${usersUrl}/Post`,
        'deleteUrl': `${usersUrl}/Delete`
      }, null, 'Id');

    this.state = {
      showEditForm: false,
      addNewMode: false,
    };
    this.editIconClick = this.editIconClick.bind(this);
    this.hideEditPopup = this.hideEditPopup.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.changeTitle = this.changeTitle.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResized);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResized);
    if (this.dataSource) {
      this.dataSource.dispose();
    }
  }

  windowResized() {
    this.usersGrid.instance.option('height', calcGridHeight('usersGrid'));
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: this.addIconClick.bind(this),
        },
      },
    );
  }

  editIconClick(data) {
    this.setState({
      showEditForm: true,
      userId: data.Id,
      addNewMode: false,
      rowData: data,
      editFormTitle: data.Name
    });
  }

  addIconClick() {
    this.setState({
      showEditForm: true,
      addNewMode: true,
      userId: 0,
      editFormTitle: ''
    });
  }

  hideEditPopup() {
    this.setState({
      rowData: {},
      showEditForm: false,
    });
  }

  refreshGrid() {
    this.usersGrid.instance.refresh();
  }

  changeTitle(title) {
    this.setState({
      editFormTitle: title
    });
  }

  render() {
    const { showEditForm, addNewMode, userId, rowData, editFormTitle } = this.state;
    return (
      <>
        {showEditForm && (
          <Popup
            id="editUserPopup"
            visible
            onHidden={this.hideEditPopup}
            title={editFormTitle}
            {...appConst.defaultPopupOptions}
          >
            <EditUserForm
              addNewMode={addNewMode}
              userId={userId}
              rowData={rowData}
              refreshGrid={this.refreshGrid}
              changeTitle={this.changeTitle}
            />
          </Popup>
        )}
        <div className="title">
          <span>{"Users"}</span>
        </div>
        <DataGrid
          id="usersGrid"
          key="Id"
          ref={(ref) => { this.usersGrid = ref; }}
          dataSource={this.dataSource}
          {...appConst.defaultGridOptions}
          onRowDblClick={(e) => {
            this.editIconClick(e.data)
          }}
          onToolbarPreparing={this.onToolbarPreparing}
          height={() => calcGridHeight('usersGrid')}
        >
          <Selection mode="multiple" />
          <SearchPanel
            visible
            width={240}
            placeholder="Search..."
          />
          <Scrolling
            mode="virtual"
            rowRenderingMode="virtual"
          />
          <Editing
            mode="row"
            allowAdding={false}
            allowDeleting
            allowUpdating
          />
          <Column
            dataField="Username"
            dataType="string"
            sortOrder="asc"
            sortIndex="1"
          />
          <Column dataField="Name" dataType="string" />
          <Column dataField="EmailAddress" dataType="string">
            <EmailRule />
          </Column>
          <Column
            dataField="Enabled"
            dataType="boolean"
            sortOrder="desc"
            sortIndex="0"
          />
          <Column
            type="buttons"
            width={110}
            buttons={[
              'delete',
              {
                hint: 'Edit',
                icon: 'edit',
                onClick: (e) => {
                  this.editIconClick(e.row.data);
                },
              }
            ]}
          />
        </DataGrid>
      </>
    );
  }
}
export default Users;
