import React from 'react';
import './CallActions.scss';
import AdminComponent from '../../../components/admin-component/AdminComponent';

export default () => (
  <AdminComponent
    componentName="CallLogActions"
    title="Call Log Actions"
  />
);
