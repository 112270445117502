import React from 'react';
import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  Selection,
  SearchPanel,
  FilterPanel,
  FilterRow,
  ColumnChooser,
  Grouping,
  GroupPanel,
  HeaderFilter,
} from 'devextreme-react/data-grid';
import calcGridHeight, { convertToTZ } from '../../../helpers/ui';
import { CreateAuthorizedDataSource } from '../../../helpers/data';
import { appConst } from '../../../AppConst';

const auditUrl = `${window.env.apiEndpoint}/api/v1/AuditLogs`;

class AuditLogs extends React.Component {
  constructor(props) {
    super(props);

    this.windowResized = this.windowResized.bind(this);
    this.dataSource = CreateAuthorizedDataSource(
      {
        loadUrl: `${auditUrl}/Get`,
      },
      {
        load: { timeZone: window.env.timeZone },
      }, 'Id',
    );
    this.auditLogsGridRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResized);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResized);
    if (this.dataSource) {
      this.dataSource.dispose();
    }
  }

  windowResized() {
    this.auditLogsGridRef.current.instance.option('height', calcGridHeight('auditLogsGrid'));
  }

  calculateCellValue(data) {
    return data.Message.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

  render() {
    return (
      <DataGrid
        ref={this.auditLogsGridRef}
        id="auditLogsGrid"
        key="Id"
        dataSource={this.dataSource}
        {...appConst.defaultGridOptions}
        onRowDblClick={(e) => {
          this.editIconClick(e.data);
        }}
        onToolbarPreparing={this.onToolbarPreparing}
        height={() => calcGridHeight('auditLogsGrid')}
        customizeExportData={
          (cols, rows) => {
            rows.forEach((row) => {
              const rowValues = row.values;
              rowValues[2] = rowValues[2].replaceAll('<br>', '\r\n');
            });
          }
        }
      >
        <Selection mode="multiple" />
        <SearchPanel
          visible
          width={240}
          placeholder="Search..."
        />
        <Scrolling
          mode="virtual"
          rowRenderingMode="virtual"
        />
        <Editing
          mode="row"
          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}
        />
        <ColumnChooser
          enabled
          mode="dragAndDrop"
        />
        <Grouping
          allowCollapsing
          autoExpandAll={false}
          contextMenuEnabled
          expandMode="rowClick"
        />
        <GroupPanel
          visible
          allowColumnDragging
        />
        <HeaderFilter
          visible
          allowSearch
        />
        <FilterRow
          visible
        />
        <FilterPanel
          visible
        />
        <Column
          dataField="Date"
          dataType="datetime"
          sortOrder="desc"
          format={appConst.dateAndTimeDisplayFormat}
          calculateCellValue={
            (data) => {
              return convertToTZ(data.Date);
            }
          }
        />
        <Column
          dataField="User"
          dataType="string"
        />
        <Column
          dataField="Message"
          dataType="string"
          calculateCellValue={this.calculateCellValue}
          encodeHtml={false}
          allowHeaderFiltering={false}
        />
        <Column
          dataField="ChangeType"
          dataType="string"
        />
      </DataGrid>
    );
  }
}
export default AuditLogs;
