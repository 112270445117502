import React from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import './CallLogPopup.scss';
import { custom } from 'devextreme/ui/dialog';
import Form, { GroupItem, SimpleItem, Label, EmptyItem } from 'devextreme-react/form';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Position } from 'devextreme-react/lookup';
import notify from 'devextreme/ui/notify';
import PropTypes from 'prop-types';
import AppContext from '../../AppContext';
import { CreateAuthorizedStore, SetAuthorizationHeaderAsync, CreateAuthorizedDataSource } from '../../helpers/data';
import { appConst } from '../../AppConst';
import { NameIdentifier } from '../../helpers/miscellaneous';
import { confirm } from 'devextreme/ui/dialog';

const URL = `${window.env.apiEndpoint}/api/v1/CallLogs`;

class CallLogPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subCategoriesData: [],
      categoryId: 0,
      autoSave: false,
      changed: false,
    };
   
    this.minimizeButtonOptions = {
      icon: 'upload',
      onClick: () => {
        this.setState({ autoSave: false }, async () => {
          const { Id } = this.props;
          const { currentCallLog } = this.state;

          try {
            await this.currentCallLog.update(Id, currentCallLog);
            const { callsInProgress, updateContext } = this.context;
            const index = callsInProgress.findIndex((cl) => cl.Id === Id);
            callsInProgress[index].Open = false;
            updateContext({ callsInProgress });
          } catch (error) {
            notify({ message: error.message, shading: true }, 'error');
          }
        });
      },
    };

    this.currentCallLog = createStore({
      loadUrl: `${URL}/GetCallLog`,
      updateUrl: `${URL}/Put`,
      deleteUrl: `${URL}/Delete_CallInProgress`,
      key: 'Id',
      onBeforeSend: async (method, ajaxOptions) => {
        const { Id } = this.props;
        const { autoSave } = this.state;

        await SetAuthorizationHeaderAsync(ajaxOptions);

        if (method === 'load') {
          ajaxOptions.data.key = Id;
          ajaxOptions.data.timeZone = window.env.timeZone;
        }
        if (method === 'update') {
          ajaxOptions.data.autoSave = autoSave;
          ajaxOptions.data.timeZone = window.env.timeZone;
        }
      },
    });

    this.closeButtonOptions = {
      icon: 'close',
      onClick: () => {
        const myDialog = custom({
          showTitle: false,
          messageHtml: '<p>The system has auto-saved the information you have recorded so far about this call.</p><ul><li>If you\'re finished recording the call, save it.  You will see it on the Call Logs screen if you need to change it later.</li><li>If you\'re still working on the call, you can keep it in progress.  It will appear in the "Calls in Progress" menu</li><li>If you don\'t want to keep this record at all, you may delete it.</li></ul>',
          buttons: [
            {
              text: 'Save',
              onClick: () => {
                this.saveButtonOptions.onClick();
              },
            },
            {
              text: 'Keep In Progress',
              onClick: () => {
                this.setState({ autoSave: false }, async () => {
                  try {
                    await this.currentCallLog.update(this.props.Id, this.state.currentCallLog);
                    this.keepCall();
                  } catch (error) {
                    notify({ message: error.message, shading: true }, 'error');
                  }
                });
              },
            },
            {
              text: 'Delete',
              onClick: () => {
                const { Id } = this.props;
                this.currentCallLog.remove(Id).then(
                  () => { this.discardCall(); },
                  (error) => { notify({ message: error.message, shading: true }, 'error'); },
                );
              },
            }],
        });
        myDialog.show().then(() => {
        });
      },
    };

    this.subCategoriesData = createStore({
      loadUrl: `${window.env.apiEndpoint}/api/v1/CallLogSubCategories/CallLogSubCategoriesLookup`,
      key: 'Value',
      onBeforeSend: async (method, ajaxOptions) => {
        await SetAuthorizationHeaderAsync(ajaxOptions);

        const { categoryId } = this.state;
        if (method === 'load') {
          ajaxOptions.data.categoryId = categoryId;
        }
      },
    });

    this.usersData = CreateAuthorizedStore({ loadUrl: `${window.env.apiEndpoint}/api/v1/Users/Get` }, null, 'Id');

    this.discardButtonOptions = {
      text: 'Discard',
      onClick: async () => {
        const confirmResult = await confirm("<i>Delete current call log</i>", "Delete");
        if (confirmResult) {
          const { Id } = this.props;
          this.currentCallLog.remove(Id).then(
            () => { this.discardCall(); },
            (error) => { notify({ message: error.message, shading: true }, 'error'); },
          );
        }
      },
    };

    this.saveButtonOptions = {
      text: 'Save',
      onClick: () => {
        const { Id } = this.props;
        const { currentCallLog } = this.state;
        currentCallLog.InProgress = false;
        this.setState({ autoSave: false }, () =>
          this.currentCallLog.update(Id, currentCallLog).then(
            () => { this.discardCall(); },
          )
        );
      },
    };

    this.keepCall = this.keepCall.bind(this);
    this.categoryChanged = this.categoryChanged.bind(this);
    this.discardCall = this.discardCall.bind(this);
    this.autoSave = this.autoSave.bind(this);
    this.selectBoxChanged = this.selectBoxChanged.bind(this);

    this.categoriesData = CreateAuthorizedDataSource({ loadUrl: `${window.env.apiEndpoint}/api/v1/CallLogCategories/CallLogCategoriesLookup` }, null, 'Value', null, 'Header');
    this.actionsData = CreateAuthorizedDataSource({ loadUrl: `${window.env.apiEndpoint}/api/v1/CallLogActions/CallLogActionsLookup` }, null, 'Value', null, 'Header');
  }

  discardCall() {
    const { Id } = this.props;
    const { callsInProgress, updateContext } = this.context;
    const tmp = callsInProgress.filter((cl) => cl.Id !== Id);
    updateContext({ callsInProgress: tmp });
  }

  keepCall() {
    const { callsInProgress, updateContext } = this.context;
    const { Id } = this.props;
    const index = callsInProgress.findIndex((cl) => cl.Id === Id);
    callsInProgress[index].Open = false;
    updateContext({ callsInProgress });
  }

  categoryChanged(e) {
    if (e.value !== e.previousValue
      && !this.state.changed
      && (typeof e.event !== 'undefined')) {
      const categoryId = e.value;
      this.setState({
        categoryId,
        changed: true,
      }, () => {
        ;
        this.subCategoriesData.load().then(
          (data) => {
            this.setState({ subCategoriesData: data });
          },
        );
      });
    }
  }

  autoSave() {
    const { changed, currentCallLog } = this.state;
    const { Id } = this.props;
    if (changed) {
      this.setState({ autoSave: true }, async () => {
        try {
          await this.currentCallLog.update(Id, currentCallLog);
          this.setState({ changed: false });
        } catch (error) {
          notify({ message: error.message, shading: true }, 'error');
        }
      });
    }
  }

  selectBoxChanged(e) {
    if (e.value !== e.previousValue
      && !this.state.changed
      && (typeof e.event !== 'undefined')) {
      setTimeout(() => {
        this.setState({
          changed: true,
        });
      }, 0);
    }
  }

  componentDidMount() {
    this.currentCallLog.load().then(
      async (data) => {
        let confirmResult;
        if (data[0].AutoSave) {
          confirmResult = await confirm("<i>This call record is not saved yet. Retrieve from auto save?</i>", "Auto Save");
          if (confirmResult) {
            data[0].Details = data[0].AutoSaveDetails;
            data[0].UserId = data[0].AutoSaveUserId;
            data[0].CallDate = data[0].AutoSaveCallDate;
            data[0].CallLogCategoryId = data[0].AutoSaveCallLogCategoryId;
            data[0].CallLogSubcategoryId = data[0].AutoSaveCallLogSubcategoryId;
            data[0].CallLogActionId = data[0].AutoSaveCallLogActionId;
          }
          try {
            await this.currentCallLog.update(this.props.Id, data[0]);
          } catch (error) {
            notify({ message: error.message, shading: true }, 'error');
          }
        }
        this.setState({
          currentCallLog: data[0],
          categoryId: data[0].CallLogCategoryId,
        }, () => {
          this.subCategoriesData.load().then(
            (data) => {
              this.setState({ subCategoriesData: data });
            },
          );
          this.timer = setInterval(() => { this.autoSave(); }, 5000);
        });
      },
      (error) => {
        notify({ message: error.message, shading: true }, 'error');
      },
    );
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  render() {
    const { offset, refreshCallLogDropDownButton } = this.props;
    const { currentCallLog, subCategoriesData } = this.state;
    return (
      <Popup
        visible
        width="40%"
        dragEnabled
        closeOnOutsideClick={false}
        showTitle
        shading={false}
        showCloseButton={false}
        animation={null}
        onShowing={refreshCallLogDropDownButton}
      >
        <Position offset={offset} />
        <ToolbarItem
          text={currentCallLog && NameIdentifier(currentCallLog.PreferredFirstName, currentCallLog.FirstName, currentCallLog.LastName)}
          location="before"
        />
        <ToolbarItem
          widget="dxButton"
          location="after"
          options={this.minimizeButtonOptions}
        />
        <ToolbarItem
          widget="dxButton"
          location="after"
          options={this.closeButtonOptions}
        />
        <Form
          scrollingEnabled
          formData={currentCallLog}
          {...appConst.defaultFormOptions}
        >
          <GroupItem showCaption={false} colCount={2}>
            <SimpleItem
              editorType="dxDateBox"
              dataField="CallDate"
              editorOptions={{
                ...appConst.defaultSelectDateTimeBoxOptions,
                onValueChanged: this.selectBoxChanged,
              }}
            >
              <Label text="Call Date" />
            </SimpleItem>
            <SimpleItem
              editorType="dxSelectBox"
              dataField="UserId"
              editorOptions={{
                dataSource: this.usersData,
                ...appConst.defaultSelectBoxOptions,
                valueExpr: 'Id',
                displayExpr: 'Name',
                onValueChanged: this.selectBoxChanged,
                grouped: false,
              }}
            >
              <Label text="User" />
            </SimpleItem>
            <SimpleItem
              editorType="dxSelectBox"
              dataField="CallLogCategoryId"
              editorOptions={{
                dataSource: this.categoriesData,
                ...appConst.defaultSelectBoxOptions,
                onValueChanged: this.categoryChanged,
                grouped: true,
              }}
            >
              <Label text="Category" />
            </SimpleItem>
            <SimpleItem
              editorType="dxSelectBox"
              dataField="CallLogSubcategoryId"
              editorOptions={{
                dataSource: subCategoriesData,
                ...appConst.defaultSelectBoxOptions,
                onValueChanged: this.selectBoxChanged,
                grouped: false,
              }}
            >
              <Label text="Sub-Category" />
            </SimpleItem>
            <SimpleItem
              editorType="dxSelectBox"
              dataField="CallLogActionId"
              editorOptions={{
                dataSource: this.actionsData,
                ...appConst.defaultSelectBoxOptions,
                valueChangeEvent: 'input',
                onValueChanged: this.selectBoxChanged,
              }}
            >
              <Label text="Action" />
            </SimpleItem>
            <EmptyItem />
            <SimpleItem
              editorType="dxTextArea"
              dataField="Details"
              colSpan={2}
              editorOptions={{
                height: '8em',
                onValueChanged: this.selectBoxChanged,
                valueChangeEvent: 'keyup',
              }}
            >
              <Label text="Details" />
            </SimpleItem>
            <EmptyItem />
            <GroupItem showCaption={false} colCount={2}>
              <SimpleItem
                itemType="button"
                horizontalAlignment="left"
                buttonOptions={this.saveButtonOptions}
              />
              <SimpleItem
                itemType="button"
                horizontalAlignment="left"
                buttonOptions={this.discardButtonOptions}
              />
            </GroupItem>
          </GroupItem>
        </Form>
      </Popup>
    );
  }
}
CallLogPopup.contextType = AppContext;
CallLogPopup.propTypes = {
  Id: PropTypes.number.isRequired,
  FirstName: PropTypes.string.isRequired,
  offset: PropTypes.string.isRequired,
  refreshCallLogDropDownButton: PropTypes.func.isRequired,
};
export default CallLogPopup;
