import React from 'react';
import Form, { GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';
import { NameAutoComplete } from '../../components';
import { setFocusOnFirstInput } from '../../helpers/ui';
import notify from 'devextreme/ui/notify';
import { CreateAuthorizedDataSource, CreateAuthorizedStore } from '../../helpers/data';

import { appConst } from '../../AppConst';

const URL = `${window.env.apiEndpoint}/api/v1/Members`;
const URL1 = `${window.env.apiEndpoint}/api/v1/Schools`;

class AddMemberPopup extends React.Component {
  constructor(props) {
    super(props);
    this.membersLookupData = CreateAuthorizedDataSource({ loadUrl: `${URL}/Get` }, { load: { timeZone: window.env.timeZone } }, 'Id',null,null,['FullName']);
    this.state = {
      memberId: 0,
      divisionId: 0,
    };
    this.divisionsLookupData = CreateAuthorizedDataSource({
      loadUrl: `${window.env.apiEndpoint}/api/v1/Divisions/DivisionsLookup`,
    },
    null,
    'Value',
    null,
      'Header');
    this.setMemberId = this.setMemberId.bind(this);
  }

  addMemberButtonOptions = {
    width: '100%',
    icon: 'save',
    text: 'Save',
    hint: 'Create a new school association',
    onClick: () => {
      const { memberId, dateSelected, divisionId } = this.state;
      const addMemberStore = CreateAuthorizedStore(
        { insertUrl: `${URL1}/AddMemberToSchool` },
        {
          insert: { values: JSON.stringify({ MemberId: memberId, StartDate: dateSelected, DivisionId: divisionId }), schoolId: this.props.schoolId },
        }, 'Id',
      );

      addMemberStore.insert().then(
        (result) => {
          this.setState({
            memberId: 0,
          });
          this.props.hideMemberAddNewPopupAndRefreshGrid();
        },
        (error) => { notify({ message: error.message, shading: true }, 'error'); },
      );
    },
  }

  cancelMemberButtonOptions = {
    width: '100%',
    icon: 'revert',
    text: 'Cancel',
    onClick: () => {
      this.setState({
        memberId: 0,
      });
      this.props.setShowMemberAddNewFormValue(false, 0, true);
    },
  }

  createMemberButtonOptions = {
    width: '100%',
    icon: 'revert',
    text: 'Create...',
    hint: 'Create a new member with a school accossiation.',
    onClick: () => {
      this.props.setShowMemberEditFormValue(true, 0, true);
      this.setState({
        memberId: 0,
      });
    },
  }

  componentWillUnmount() {
    this.membersLookupData && this.membersLookupData.dispose();
    this.divisionsLookupData && this.divisionsLookupData.dispose();
  }

  setMemberId(memberId) {
    this.setState({ memberId: memberId })
  }

  render() {
    return (
      <Popup
        showTitle={false}
        visible={this.props.showAddMemberPopup}
        resizeEnabled={false}
        height="auto"
        onShown={(e) => {
          setFocusOnFirstInput(e.component.content().parentNode);
        }}
        dragEnabled
        minHeight={300}
      >
        <Form
          Id="addMemberForm"
          ref={(ref) => { this.addMemberForm = ref; }}
          scrollingEnabled
        >
          <GroupItem caption="Add a member to current school..." colCount={3}>
            <SimpleItem
              dataField="FullName"
              colSpan={3}
            >
              <Label text="Find Member" />
              <NameAutoComplete
                dataSource={this.membersLookupData}
                setId={this.setMemberId}
              />
            </SimpleItem>
            <SimpleItem
              colSpan={3}
              editorType="dxSelectBox"
              editorOptions={{
                dataSource: this.divisionsLookupData,
                ...appConst.defaultSelectBoxOptions,
                onValueChanged: (e) => { this.setState({ divisionId: e.value }); },
              }}
            >
              <Label text="Division" />
            </SimpleItem>
            <SimpleItem
              colSpan={3}
              dataField="StartDate"
              editorType="dxDateBox"
              editorOptions={{
                type: 'date',
                ...appConst.defaultSelectDateBoxOptions,
                onValueChanged: (e) => {
                  this.setState({ dateSelected: e.value });
                },
              }}
            />
            <SimpleItem
              itemType="button"
              horizontalAlignment="right"
              buttonOptions={this.addMemberButtonOptions}
            />
            <SimpleItem
              itemType="button"
              horizontalAlignment="left"
              buttonOptions={this.cancelMemberButtonOptions}
            />
            <SimpleItem
              itemType="button"
              horizontalAlignment="left"
              buttonOptions={this.createMemberButtonOptions}
            />
          </GroupItem>
        </Form>
      </Popup>
    );
  }
}
export default AddMemberPopup;
