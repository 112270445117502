import React from 'react';
import { CustomRule } from 'devextreme-react/form';
import { appConst } from '../../AppConst';


export default function () {
  return (   
      <CustomRule
        validationCallback={(e) => {
          if ('' === e.value) return true;
          const reg = new RegExp(appConst.emailAddressPatternPattern);
          if (reg.test(e.value)) {
            return true;
          } else {
            e.rule.message = `"${e.value}" is not a valid email address pattern. Only characters allowed in an email address are valid in the email address pattern.`
            return false;
          }
        }}
      />
  );
}