import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import '@fortawesome/fontawesome-free/css/all.css';
import React, { Component } from 'react';
import AppProvider from "./AppProvider";
import AppContext from "./AppContext";
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { appConst } from './AppConst';
import { navigation } from './app-navigation';
import routes from './app-routes';
import './App.scss';
import './dx-styles.scss';
import { LoginForm } from './components';
import {
    SideNavOuterToolbar as SideNavBarLayout,
    SingleCard
} from './layouts';
import { sizes, subscribe, unsubscribe } from './utils/media-query';
import DevExtremeDataGrid from 'devextreme/ui/data_grid';

DevExtremeDataGrid.registerModule("columnChooserSorting", {
    extenders: {
        controllers: {
            columns: {
                getChooserColumns: function (loadAllColumns) {
                    var result = this.callBase(loadAllColumns);

                    result.sort(function (column1, column2) {
                        return column1.caption.localeCompare(column2.caption);
                    });

                    return result;
                }
            }
        }
    }
});

const LoginContainer = ({ logIn }) => <LoginForm onLoginClick={logIn} />;

const NotAuthPage = (props) => (
    <SingleCard>
        <Route render={() => <LoginContainer {...props} />} />
    </SingleCard>
);

const AuthPage = (props) => (
  <SideNavBarLayout menuItems={navigation} title={appConst.appName + " v" + appConst.release} {...props}>
        <Switch>
            {routes.map(item => (
              <Route
                exact
                key={item.path}
                path={item.path}
                component={item.component}
                />
            ))}
            <Redirect to={'/home'} />
        </Switch>
        {//<Footer>
            //</Footer>
        }
    </SideNavBarLayout>
);

class App extends Component {
    static contextType = AppContext;

    constructor(props) {
      super(props);
      this.state = {
        screenSizeClass: this.getScreenSizeClass()
      };
      this.userMenuItems = [
            {
                text: 'Profile',
                icon: 'user'
            },
            {
                text: 'Logout',
                icon: 'runner',
                onClick: this.logOut
            }
        ];
    }

    componentDidMount() {
        subscribe(this.screenSizeChanged);
    }

    componentWillUnmount() {
        unsubscribe(this.screenSizeChanged);
    }
    
    render() {
      const { loggedIn } = this.context;
      return (
        <div className={`app ${this.state.screenSizeClass}`}>
          <Router>{loggedIn ? <AuthPage userMenuItems={this.userMenuItems} /> : <NotAuthPage logIn={this.logIn} />}</Router>
        </div>
        );
    }

    getScreenSizeClass() {
        const screenSizes = sizes();
        return Object.keys(screenSizes).filter(cl => screenSizes[cl]).join(' ');
    }

    screenSizeChanged = () => {
        this.setState({
            screenSizeClass: this.getScreenSizeClass()
        });
    }

    logIn = () => {
        this.context.onSignIn(true);
    };

    logOut = () => {
        this.context.onSignOut();
    };
}

export default AppProvider(App);
