import React from 'react';
import './Schools.scss';
import '../pages.scss';
import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  Selection,
  SearchPanel,
  Grouping,
  GroupPanel,
  Button,
  HeaderFilter,
  ColumnChooser,
  FilterRow,
} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import { Popup } from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/load-panel';
import CurrentSwitch from '../../components/current-switch/current-switch';
import calcGridHeight from '../../helpers/ui';
import {
  CreateAuthorizedStore,
  CreateAuthorizedDataSource,
  loadMembersLookupDataAsync,
  disposeMembersLookupData,
} from '../../helpers/data';
import { appConst } from '../../AppConst';
import EditSchoolForm from './EditSchoolForm';
import EditMemberForm from '../members/EditMemberForm';
import AddMemberPopup from './AddMemberPopup';
import GridLayout from '../../components/grid-layout-component/GridLayout';

const schoolsUrl = `${window.env.apiEndpoint}/api/v1/Schools`;
const membersUrl = `${window.env.apiEndpoint}/api/v1/Members`;

class Schools extends React.Component {
  constructor(props) {
    super(props);

    this.toolbarItemRender = this.toolbarItemRender.bind(this);
    this.windowResized = this.windowResized.bind(this);

    this.dataSource = CreateAuthorizedDataSource(
      {
        loadUrl: `${schoolsUrl}/Get`,
        insertUrl: `${schoolsUrl}/Post`,
        updateUrl: `${schoolsUrl}/Put`,
        deleteUrl: `${schoolsUrl}/Delete`,
      }, null, 'Id',
    );

    this.state = {
      title: '',
      showEditForm: false,
      showMemberEditForm: false,
      showMemberAddNewForm: false,
      showLoadingPanel: false,
      addNewMode: false,
      memberAddNewMode: false,
    };

    this.editIconClick = this.editIconClick.bind(this);
    this.hideEditPopup = this.hideEditPopup.bind(this);
    this.hideMemberEditPopup = this.hideMemberEditPopup.bind(this);
    this.setShowMemberEditFormValue = this.setShowMemberEditFormValue.bind(this);
    this.hideMemberEditPopupAndRefreshGrid = this.hideMemberEditPopupAndRefreshGrid.bind(this);
    this.setShowMemberAddNewFormValue = this.setShowMemberAddNewFormValue.bind(this);
    this.hideEditPopupAndRefreshGrid = this.hideEditPopupAndRefreshGrid.bind(this);
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.hideMemberAddNewPopupAndRefreshGrid = this.hideMemberAddNewPopupAndRefreshGrid.bind(this);
    this.showHideSchoolEditForm = this.showHideSchoolEditForm.bind(this);
    this.refreshSchoolGrid = this.refreshSchoolGrid.bind(this);
    this.getMemberPopupRef = this.getMemberPopupRef.bind(this);
  }

  async componentDidMount() {
    window.addEventListener('resize', this.windowResized);
    this.setState({ membersLookupData: await loadMembersLookupDataAsync() });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResized);
    disposeMembersLookupData(this.state.membersLookupData);
  }

  windowResized() {
    this.schoolsGrid.instance.option('height', calcGridHeight('schoolsGrid'));
  }

  toolbarItemRender() {
    return (
      <CurrentSwitch grid={this.schoolsGrid} />
    );
  }

  editIconClick(key, name) {
    const getSchoolByIdStore = CreateAuthorizedStore(
      { loadUrl: `${schoolsUrl}/GetSchoolById` }, { load: { Id: key } },
    );
    getSchoolByIdStore.load().then(
      (result) => {
        this.setState({
          showEditForm: true,
          title: name,
          rowData: { ...result[0] },
          addNewMode: false,
        });
      },
    );
  }

  hideEditPopup() {
    this.setState({
      showEditForm: false,
    });
  }

  hideMemberEditPopup() {
    this.setState({
      showMemberEditForm: false,
    });
    this.schoolsGrid.instance.option('loadPanel.enabled', false);
  }

  hideMemberAddNewPopup() {
    this.setState({
      showMemberAddNewForm: false,
    });
  }

  hideMemberEditPopupAndRefreshGrid() {
    this.hideMemberEditPopup();
    this.refs.editSchoolForm.refreshGrids();
    this.refreshSchoolGrid();
  }

  hideEditPopupAndRefreshGrid(refresh) {
    this.hideEditPopup();
    if (refresh) { this.schoolsGrid.instance.refresh(); }
  }

  setShowMemberEditFormValue(value, memberkey, memberAddNewMode) {
    if (!memberAddNewMode) {
      this.setState({ showLoadingPanel: true });
      const getMemberByIdStore = CreateAuthorizedStore(
        { loadUrl: `${membersUrl}/GetMemberById` }, { load: { Id: memberkey } },
      );
      // TODO: I see here a performance issue. The member edit popup form needs to wait
      // till we fetch the record from API. I do not know if load async would help.
      getMemberByIdStore.load().then(
        (result) => {
          this.setState({
            showMemberEditForm: value,
            rowMemberData: result[0],
            showLoadingPanel: false,
            memberAddNewMode,
          });
        },
      );
    } else {
      this.setState({
        showMemberEditForm: value,
        rowMemberData: { EmailAddresses: [], PhoneNumbers: [], Schools: [{ SchoolId: this.state.rowData.Id, Primary: true }] },
        memberAddNewMode,
      });
    }
  }

  setShowMemberAddNewFormValue(value) {
    this.setState({
      showMemberAddNewForm: value,
    });
  }

  hideMemberAddNewPopupAndRefreshGrid() {
    this.hideMemberAddNewPopup();
    this.refs.editSchoolForm.refreshGrids();
  }

  showHideSchoolEditForm(value) {
    this.setState({
      showEditForm: value,
    });
  }

  refreshSchoolGrid() {
    this.schoolsGrid.instance.refresh().then(
      (e) => {
        this.schoolsGrid.instance.option('loadPanel.enabled', true);
      },
    );
  }

  renderEditPopup(isEditFormShown) {
    const { showEditForm, addNewMode, membersLookupData } = this.state;
    if (isEditFormShown) {
      return (
        <Popup
          visible={showEditForm}
          onHiding={(e) => {
            e.cancel = true;
            this.refs.editSchoolForm.cancelData();
          }}
          {...appConst.defaultPopupOptions}
          title={this.state.title}
        >
          <EditSchoolForm
            ref="editSchoolForm"
            rowData={this.state.rowData}
            ridingsData={membersLookupData.ridingsData}
            provincesData={membersLookupData.provincesData}
            setShowMemberEditFormValue={this.setShowMemberEditFormValue}
            setShowMemberAddNewFormValue={this.setShowMemberAddNewFormValue}
            addNewMode={addNewMode}
            showHideSchoolEditForm={this.showHideSchoolEditForm}
            refreshSchoolGrid={this.refreshSchoolGrid}
          />
        </Popup>
      );
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        onClick: () => {
          this.setState({
            showEditForm: true,
            rowData: { Current: true },
            addNewMode: true,
            title: '(New School)',
          });
        },
      },
    });
  }

  getMemberPopupRef() {
    return this.editMemberFormPopup && this.editMemberFormPopup.instance;
  }

  renderMemberEditPopUp(isEditFormShown) {
    const { rowMemberData, membersLookupData } = this.state;
    if (isEditFormShown) {
      const { showMemberEditForm, memberAddNewMode } = this.state;
      return (
        <Popup
          ref={(ref) => { this.editMemberFormPopup = ref; }}
          visible={showMemberEditForm}
          onHiding={(e) => {
            e.cancel = true;
            this.refs.editMemberForm && this.refs.editMemberForm.cancelData();
          }}
          onShown={(e) => { this.refs.editMemberForm.changeEditFormTitle(); }}
          {...appConst.defaultPopupOptions}
        >
          <EditMemberForm
            ref="editMemberForm"
            rowData={rowMemberData}
            hideEditPopupAndRefreshGrid={this.hideMemberEditPopupAndRefreshGrid}
            hideEditPopup={this.hideMemberEditPopup}
            salutationsData={membersLookupData.salutationsData}
            gendersData={membersLookupData.gendersData}
            pronounsData={membersLookupData.pronounsData}
            emailAddressTypesData={membersLookupData.emailAddressTypesData}
            phoneTypesData={membersLookupData.phoneTypesData}
            membershipTypesData={membersLookupData.membershipTypesData}
            employmentStatusesData={membersLookupData.employmentStatusesData}
            contractTypesData={membersLookupData.contractTypesData}
            ridingsData={membersLookupData.ridingsData}
            designationsData={membersLookupData.designationsData}
            addNewMode={memberAddNewMode}
            getMemberPopupRef={this.getMemberPopupRef}
            provincesData={membersLookupData.provincesData}
          />
        </Popup>
      );
    }
  }

  renderMemberAddNewPopUp(isEditFormShown) {
    if (isEditFormShown) {
      const { showMemberAddNewForm } = this.state;
      return (
        <AddMemberPopup
          showAddMemberPopup={showMemberAddNewForm}
          setShowMemberAddNewFormValue={this.setShowMemberAddNewFormValue}
          schoolId={this.state.rowData.Id}
          hideMemberAddNewPopupAndRefreshGrid={this.hideMemberAddNewPopupAndRefreshGrid}
          setShowMemberEditFormValue={this.setShowMemberEditFormValue}
        />
      );
    }
  }

  render() {
    const { showEditForm, showMemberEditForm, showMemberAddNewForm } = this.state;
    return (
      <>
        <div className="title">
          <span>Schools</span>
          <GridLayout
            layoutKey="Schools"
            gridName="schoolsGrid"
            pageName="SchoolsPage"
            gridRef={
              () => this.schoolsGrid.instance
            }
          />
        </div>
        <LoadPanel
          visible={this.state.showLoadingPanel}
          showIndicator
          shading
          showPane
          closeOnOutsideClick={false}
        />
        {this.renderEditPopup(showEditForm)}
        {this.renderMemberEditPopUp(showMemberEditForm)}
        {this.renderMemberAddNewPopUp(showMemberAddNewForm)}
        <DataGrid
          id="schoolsGrid"
          ref={(ref) => { this.schoolsGrid = ref; }}
          dataSource={this.dataSource}
          {...appConst.defaultGridOptions}
          height={() => calcGridHeight('schoolsGrid')}
          onToolbarPreparing={this.onToolbarPreparing}
          onRowDblClick={(e) => {
            this.editIconClick(e.key, e.data.Name);
          }}
        >
          <Selection mode="multiple" />
          <SearchPanel
            visible
            width={240}
            placeholder="Search..."
          />
          <Scrolling
            mode="virtual"
            rowRenderingMode="virtual"
          />
          <Editing
            mode="popup"
            allowAdding={false}
            allowDeleting
            allowUpdating
          >
            <Popup showTitle title={this.state.title} />
          </Editing>
          <ColumnChooser
            enabled
            mode="dragAndDrop"
          />
          <Grouping
            allowCollapsing
            autoExpandAll={false}
            contextMenuEnabled
            expandMode="rowClick"
          />
          <GroupPanel
            visible
            allowColumnDragging
          />
          <HeaderFilter
            visible
            allowSearch
          />
          <Column
            dataField="Name"
            dataType="string"
            sortOrder="asc"
          />
          <Column dataField="PhoneNumber" dataType="string" />
          <Column dataField="FaxNumber" dataType="string" />
          <Column
            dataField="PrincipalName"
            dataType="string"
            cellRender={(data) => (
              <a href={`mailto:${data.row.data.PrincipalEmailAddress}`}>
                {data.row.data.PrincipalName}
              </a>
            )}
          />
          <Column
            dataField="PrincipalEmailAddress"
            dataType="string"
          />
          <FilterRow
            visible
          />
          <Column
            dataField="CommRepFullName"
            dataType="string"
            caption="Comm. Rep"
            cellRender={(data) => (
              <a href={`mailto:${data.row.data.CommRepEmailAddress}`}>
                {data.row.data.CommRepFullName}
              </a>
            )}
          />
          <Column
            dataField="CommRepEmailAddress"
            dataType="string"
            caption="Comm. Rep Email Address"
          />
          <Column dataField="Address1" dataType="string" visible={false} />
          <Column dataField="Address2" dataType="string" visible={false} />
          <Column dataField="Current" dataType="boolean" visible={false} />
          <Column dataField="ProvinceCode" dataType="string" visible={false} caption="Province" />
          <Column dataField="PostalCode" dataType="string" visible={false} />
          <Column dataField="EmailAddress" dataType="string" visible={false} />
          <Column dataField="Ward" dataType="numeric" visible={false} />
          <Column dataField="Riding" dataType="string" visible={false} />
          <Column dataField="TotalMemberCount" dataType="number" visible={false} />
          <Column dataField="RepsIdentified" dataType="number" visible={false} />
          <Column dataField="RepsEntitled" dataType="number" visible={false} />
          <Column dataField="DivCounts" dataType="string" visible={false} />
          <Column dataField="RouteNumber" dataType="string" visible={false} />
          <Column dataField="AddressVerified" visible={false}/>
          <Template name="switchCurrent" render={this.toolbarItemRender} />
          <Column
            dataField="CommRepPreferredFirstName"
            dataType="string"
            visible={false}
          />
          <Column
            dataField="CommRepFirstName"
            dataType="string"
            visible={false}
          />
          <Column
            dataField="CommRepLastName"
            dataType="string"
            visible={false}
          />
          <Column
            dataField="CommRepId"
            dataType="string"
            visible={false}
          />
          <Column
            dataField="StaffListReviewedByCommRep"
            dataType="date"
            visible={false}
            format={appConst.dateDisplayFormat}
          />
          <Column type="buttons">
            <Button name="edit" hint="Edit School" onClick={(e) => { this.editIconClick(e.row.key, e.row.data.Name); }} />
            <Button name="delete" hint="Delete School" />
          </Column>
        </DataGrid>
      </>
    );
  }
}
export default Schools;
