import React from 'react';
import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  Selection,
  SearchPanel,
  ColumnChooser,
  Grouping,
  GroupPanel,
  FilterPanel,
  FilterRow,
  HeaderFilter,
} from 'devextreme-react/data-grid';
import SelectBox from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';

import { Autocomplete } from 'devextreme-react/autocomplete';
import {
  Validator,
  RequiredRule,
} from 'devextreme-react/validator';
import {
  CreateAuthorizedDataSource,
} from '../../helpers/data';
import { appConst } from '../../AppConst';
import calcGridHeight, {
  addMemberBaseViewColumns,
  initMemberBaseViewColumns,
} from '../../helpers/ui';
import GridLayout from '../../components/grid-layout-component/GridLayout';

const memberFundsUrl = `${window.env.apiEndpoint}/api/v1/MemberFunds`;
const fundTypesData = CreateAuthorizedDataSource({ loadUrl: `${window.env.apiEndpoint}/api/v1/FundTypes/FundTypesLookup` }, null, 'Value', null, 'Header');
const membersLookupData = CreateAuthorizedDataSource({ loadUrl: `${memberFundsUrl}/GetMembers` }, null, 'Id');

const defaultFundingGridOptions = {
  ...appConst.defaultGridOptions,
  summary: {
    groupItems: [
      ...appConst.defaultGridOptions.summary.groupItems,
      {
        column: 'FundAmount',
        summaryType: 'sum',
        valueFormat: 'currency',
        displayFormat: 'Total: {0}',
      },
      {
        column: 'FundAmount',
        summaryType: 'sum',
        valueFormat: 'currency',
        displayFormat: 'Total: {0}',
        showInGroupFooter: true,
      },
    ],
    totalItems: [
      {
        column: 'FundAmount',
        summaryType: 'sum',
        valueFormat: 'currency',
      },
    ],
  },
};

class Funding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      memberId: null,
    };

    this.dataSource = CreateAuthorizedDataSource(
      {
        loadUrl: `${memberFundsUrl}/Get`,
        updateUrl: `${memberFundsUrl}/Put`,
        insertUrl: `${memberFundsUrl}/Post`,
        deleteUrl: `${memberFundsUrl}/Delete`,
      },
      null, 'MemberFundId',
    );
    this.selectBoxFundTypeColumnTemplate = this.selectBoxFundTypeColumnTemplate.bind(this);
    this.renderFullNameAutoCompleteColumn = this.renderFullNameAutoCompleteColumn.bind(this);
    this.onRowInserting = this.onRowInserting.bind(this);
    this.onRowUpdating = this.onRowUpdating.bind(this);
    this.onEditingStart = this.onEditingStart.bind(this);
    this.dateBoxFundDateColumnTemplate = this.dateBoxFundDateColumnTemplate.bind(this);
  }

  onSelectBoxFundTypeValueChanged(cell, e) {
    cell.setValue(e.value);
    this.setState({ fundTypeId: e.value });
  }

  selectBoxFundTypeColumnTemplate(cell) {
    const onValueChanged = this.onSelectBoxFundTypeValueChanged.bind(this, cell);
    return (
      <SelectBox
        {...appConst.defaultSelectBoxOptions}
        {...cell.column.lookup}
        onValueChanged={onValueChanged}
        itemRender={this.itemRender}
        grouped
        dataSource={fundTypesData}
        defaultValue={cell.row.data.FundTypeId}
      />
    );
  }

  onDateBoxFundDateValueChanged(cell, e) {
    cell.setValue(e.value);
  }

  dateBoxFundDateColumnTemplate(cell) {
    const onValueChanged = this.onDateBoxFundDateValueChanged.bind(this, cell);
    return (
      <DateBox
        {...appConst.defaultSelectDateBoxOptions}
        defaultValue={cell.value}
        onValueChanged={onValueChanged}
        itemRender={this.itemRender}
        displayFormat={appConst.dateDisplayFormat}
      />
    );
  }

  renderFullNameAutoCompleteColumn = (cell) => (
    <Autocomplete
      dataSource={membersLookupData}
      valueExpr="FullName"
      searchExpr="FullName"
      minSearchLength={3}
      maxItemCount={20}
      defaultValue={cell.text}
      onSelectionChanged={(e) => {
        if (typeof e.selectedItem === 'object') {
          if (e.selectedItem) {
            cell.setValue(e.selectedItem.FullName);
            this.setState({ memberId: e.selectedItem.Id });
          } else {
            cell.setValue('');
          }
        }
      }}
      placeholder="Type name..."
      itemRender={(item) => (
        <span>
          {item.FullName}
        </span>
      )}
    />
  )

  componentWillUnmount() {
    if (this.dataSource) { this.dataSource.dispose(); }
  }

  onRowUpdating(e) {
    const { memberId, fundTypeId } = this.state;
    e.newData.MemberId = memberId;
    e.newData.FundTypeId = fundTypeId;
  }

  onRowInserting(e) {
    e.data.MemberId = this.state.memberId;
    e.data.FundTypeId = this.state.fundTypeId;
  }

  onEditingStart(e) {
    this.setState({
      memberId: e.data.MemberId,
    });
  }

  render() {
    return (
      <>
        <div className="title">
          <span>Funding</span>
          <GridLayout
            layoutKey="Funding"
            gridName="fundingGrid"
            pageName="FundingPage"
            gridRef={
              () => this.fundingGrid.instance
            }
          />
        </div>
        <DataGrid
          id="fundingGrid"
          dataSource={this.dataSource}
          {...defaultFundingGridOptions}
          height={() => calcGridHeight('fundingGrid')}
          onRowUpdating={this.onRowUpdating}
          onRowInserting={this.onRowInserting}
          onEditingStart={this.onEditingStart}
          ref={(ref) => { this.fundingGrid = ref; }}
          customizeColumns={addMemberBaseViewColumns}
          onInitialized={(e) => { initMemberBaseViewColumns(e); }}
        >
          <Selection mode="multiple" />
          <SearchPanel
            visible
            width={240}
            placeholder="Search..."
          />
          <Scrolling
            mode="virtual"
            rowRenderingMode="virtual"
          />
          <Editing
            mode="row"
            allowAdding
            allowDeleting
            allowUpdating
          />
          <Column
            dataField="FundDateYear"
            dataType="number"
            allowEditing={false}
          />
          <Column
            dataField="FundDateMonth"
            dataType="string"
            allowEditing={false}
          />
          <Column
            dataField="FundDate"
            dataType="date"
            sortIndex="0"
            sortOrder="asc"
            editCellRender={this.dateBoxFundDateColumnTemplate}
            format={appConst.dateDisplayFormat}
          >
            <Validator>
              <RequiredRule />
            </Validator>
          </Column>
          <Column
            dataField="FundTypeName"
            dataType="string"
            caption="Type"
            editCellRender={this.selectBoxFundTypeColumnTemplate}
            showWhenGrouped
          />
          <Column
            dataField="FullName"
            dataType="string"
            editCellRender={this.renderFullNameAutoCompleteColumn}
          />
          <Column
            caption="Funding Amount"
            dataField="FundAmount"
            dataType="numeric"
            format={appConst.currencyFormat}
            alignment="right"
          />
          <Column
            dataField="PrimarySchoolName"
            dataType="string"
            visible={false}
          />
          <ColumnChooser
            enabled
            mode="dragAndDrop"
          />
          <Grouping
            allowCollapsing
            autoExpandAll={false}
            contextMenuEnabled
            expandMode="rowClick"
          />
          <GroupPanel
            visible
            allowColumnDragging
          />
          <HeaderFilter
            visible
            allowSearch
          />
          <FilterRow visible />
          <FilterPanel visible />
        </DataGrid>
      </>
    );
  }
}

export default Funding;
