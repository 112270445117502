import React from 'react';
import './EditNonMemberForm.scss';
import Form, {
  GroupItem, SimpleItem, Label, RequiredRule, PatternRule, EmptyItem,
} from 'devextreme-react/form';
import { LoadPanel } from 'devextreme-react/load-panel';
import { confirm } from 'devextreme/ui/dialog';
import PropTypes from 'prop-types';
import Tabs from 'devextreme-react/tabs';
import notify from 'devextreme/ui/notify';
import ScrollView from 'devextreme-react/scroll-view';
import { Item } from 'devextreme-react/button-group';
import PersonCallLogGrid from '../../components/person-call-log-grid/PersonCallLogGrid';
import { CreateAuthorizedStore, getNonMemberById } from '../../helpers/data';
import { appConst } from '../../AppConst';
import {
  setPreferredPhoneNumber,
  setPreferredEmailAddress,
  cancelEditFormData,
  changePersonEditFormTitle,
  updateAddressFields,
  setFocusOnEditor,
  compareAddresses,
  getStoredAddress,
  setStoredAddress,
} from '../../helpers/ui';

import CommitteeGrid from '../../components/committee-grid/CommitteeGrid';
import AddressAutoComplete from '../../components/address-auto-complete/AddressAutoComplete';

const confirmDialogContent = '<i>Are you sure?</i>';
const confirmDialogTitle = 'Delete Confirmation';
const URL = `${window.env.apiEndpoint}/api/v1/NonMembers`;

const tabs = [
  {
    id: 0,
    text: "Non-Member's Information",
    icon: 'user',
  },
  {
    id: 1,
    text: 'Calls',
    icon: 'tel',
  },
  {
    id: 2,
    text: 'Committees',
    icon: 'group',
  },
];

class EditNonMemberForm extends React.Component {
  addPhoneButtonOptions = {
    icon: 'add',
    text: 'Add Phone',
    onClick: () => {
      const { rowData } = this.state;
      const tmpState = { ...rowData };
      tmpState.PhoneNumbers.push({ Phone: '' });
      this.setState({ rowData: tmpState });
      setFocusOnEditor(this.form.instance, `PhoneNumbers[${tmpState.PhoneNumbers.length - 1}].Number`);
    },
  }

  addEmailButtonOptions = {
    icon: 'add',
    text: 'Add Email',
    onClick: () => {
      const { rowData } = this.state;
      const tmpState = { ...rowData };
      tmpState.EmailAddresses.push({ Address: '' });
      this.setState({ rowData: tmpState });
      setFocusOnEditor(this.form.instance, `EmailAddresses[${tmpState.EmailAddresses.length - 1}].Address`);
    },
  }

  constructor(props) {
    super(props);

    // I store the rowData passed from the members view in the state.
    // or instead we can call an api to get data for the edited member.

    const { rowData, provincesData, addNewMode } = this.props;
    const province = provincesData && provincesData.find(p => p.Value === rowData.ProvinceId);
    this.state = {
      rowData,
      selectedTabIndex: 0,
      oldRowData: JSON.stringify(rowData),
      city: rowData.City,
      province: province && province.Text,
      storedAddress: getStoredAddress(rowData),
      addNewMode: addNewMode,
      showLoadingPanel: false,
      needToRefreshTheGridOnCancel: false,
    };
    this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);
    this.changeEditFormTitle = this.changeEditFormTitle.bind(this);
    this.saveData = this.saveData.bind(this);
    this.cancelData = this.cancelData.bind(this);
    this.updateAddressFields = this.updateAddressFields.bind(this);
    this.setAddressVerified = this.setAddressVerified.bind(this);
  }

  // this button options are used for the remove email button
  // the index points the order of remove button.
  removeEmailButtonOptions = (index) => ({
    icon: 'trash',
    onClick: () => {
      const result = confirm(confirmDialogContent, confirmDialogTitle);
      result.then((dialogResult) => {
        if (dialogResult) {
          const { rowData } = this.state;
          const tmpState = { ...rowData };
          const i = parseInt(index, 10);
          tmpState.EmailAddresses.splice(i, 1);
          this.setState({ rowData: tmpState });
        }
      });
    },
  })

  // this button options are used for the remove phone button
  // the index points the order of remove button.
  removePhoneButtonOptions = (index) => ({
    icon: 'trash',
    onClick: () => {
      const result = confirm(confirmDialogContent, confirmDialogTitle);
      result.then((dialogResult) => {
        if (dialogResult) {
          const { rowData } = this.state;
          const tmpState = { ...rowData };
          const i = parseInt(index, 10);
          tmpState.PhoneNumbers.splice(i, 1);
          this.setState({ rowData: tmpState });
        }
      });
    },
  })

  saveDataButtonOptions = {
    text: 'Save & Close',
    width: 150,
    onClick: () => {
      this.saveData(true);
    },
  }

  // Cancel hides the edit popup window.
  cancelDataButtonOptions = {
    text: 'Cancel',
    width: 150,
    onClick: () => {
      this.cancelData();
    },
  }

  cancelData() {
    const { hideEditPopupAndRefreshGrid, hideEditPopup } = this.props;
    const { oldRowData, needToRefreshTheGridOnCancel } = this.state;
    const editedData = this.form.instance.option('formData');
    if (needToRefreshTheGridOnCancel)
      cancelEditFormData(oldRowData, JSON.stringify(editedData), this.saveData, hideEditPopupAndRefreshGrid);
    else
      cancelEditFormData(oldRowData, JSON.stringify(editedData), this.saveData, hideEditPopup);
  }

  setAddressVerified(value) {
    const { rowData } = this.state;
    this.setState({
      rowData: { ...rowData, AddressVerified: value },
    });
  }

  async getNonMemberByIdAsync(Id) {
    try {
      const member = await getNonMemberById(Id);
      return member;
    }
    catch (error) {
      notify({ message: error.message, shading: true }, 'error');
    }
  }

  async afterSave(closePopup, Id) {
    if (!closePopup) {
      const nonMember = await this.getNonMemberByIdAsync(Id);
      if (typeof nonMember === "undefined") return;
      this.setState({
        oldRowData: JSON.stringify(nonMember),
        rowData: nonMember,
        addNewMode: false,
        showLoadingPanel: false,
        needToRefreshTheGridOnCancel: true,
      }, () => { return });
    } else {
      const { hideEditPopupAndRefreshGrid } = this.props;
      hideEditPopupAndRefreshGrid();
    }
  }

  saveData(closePopup) {
    const v = this.form.instance.validate();
    if (!v.isValid) return false;
    if (!closePopup)
      this.setState({ showLoadingPanel: true });
    const data = this.form.instance.option('formData');
    const { addNewMode } = this.state;
    const saveNonMemberData = CreateAuthorizedStore(
      { updateUrl: `${URL}/Put`, insertUrl: `${URL}/Post` }, null, 'Id',
    );
    if (!addNewMode) {
      saveNonMemberData.update(data.Id, data).then(
        async (result) => {
            await this.afterSave(closePopup, data.Id);
        },
        (error) => {
          this.setState({ showLoadingPanel: false });
          notify({ message: error.message, shading: true }, 'error');
        },
      );
    } else {
      saveNonMemberData.insert(data).then(
        async (result) => { await this.afterSave(closePopup, result);},
        (error) => {
          this.setState({ showLoadingPanel: false });
          notify({ message: error.message, shading: true }, 'error');
        },
      );
    }
  }

  onTabsSelectionChanged(args) {
    if (args.name === 'selectedIndex') {
      const { addNewMode } = this.state;
      if (!addNewMode) {
        this.setState({
          selectedTabIndex: args.value,
        });
        this.refs.personCallLogGrid.setGridHeight();
        this.refs.committeeGrid.setGridHeight();
      } else {
        notify('You are not allowed to view calls unless non-member is saved.');
      }
      if (args.value === 0) {
        setFocusOnEditor(this.form.instance, "FirstName");
      }
    }
  }

  changeEditFormTitle() {
    if (this.form === null) return;
    const { addNewMode } = this.state;
    const popup = this.props.getNonMemberPopupRef();
    const formData = this.form.instance.option('formData');
    const formScrollView = this.formScrollView.instance;
    changePersonEditFormTitle(addNewMode, popup, formData, formScrollView);
  }

  updateAddressFields(addressObj) {
    const { rowData } = this.state;
    const { provincesData } = this.props;

    this.setState({ rowData: updateAddressFields(addressObj, rowData, provincesData) },
      () => this.setState({ storedAddress: setStoredAddress(addressObj) }));
  }

  render() {
    const {
      phoneTypesData, emailAddressTypesData, salutationsData, pronounsData, provincesData
    } = this.props;
    const { rowData, selectedTabIndex, city, province, storedAddress, showLoadingPanel } = this.state;
    return (
      <>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position="center"
          visible={showLoadingPanel}
        />
        <Tabs
          dataSource={tabs}
          selectedIndex={selectedTabIndex}
          onOptionChanged={this.onTabsSelectionChanged}
        />
        <div className={(selectedTabIndex === 0) ? 'tab-form-container-visible' : 'tab-container-invisible'}>
          <ScrollView ref={(ref) => { this.formScrollView = ref; }}>
            <Form
              ref={(ref) => { this.form = ref; }}
              scrollingEnabled={false}
              formData={rowData}
              {...appConst.defaultFormOptions}
            >
              <GroupItem caption="Personal Information" colCount={4}>
                <SimpleItem
                  dataField="FirstName"
                  editorType="dxTextBox"
                  editorOptions={{
                    valueChangeEvent: 'keyup',
                    onValueChanged: (e) => {
                      this.changeEditFormTitle();
                    },
                  }}
                >
                  <RequiredRule message="First name is required" />
                </SimpleItem>
                <SimpleItem
                  dataField="LastName"
                  editorOptions={{
                    valueChangeEvent: 'keyup',
                    onValueChanged: (e) => {
                      this.changeEditFormTitle();
                    },
                  }}
                />
                <SimpleItem
                  dataField="PreferredFirstName"
                  editorOptions={{
                    valueChangeEvent: 'keyup',
                    onValueChanged: (e) => {
                      this.changeEditFormTitle();
                    },
                  }}
                />
                <SimpleItem dataField="PreviousLastName" />
                <SimpleItem editorType="dxSelectBox" dataField="SalutationId" editorOptions={{ dataSource: salutationsData, ...appConst.defaultSelectBoxOptions }}>
                  <Label text="Salutation" />
                </SimpleItem>
                <SimpleItem editorType="dxSelectBox" dataField="PronounsId" editorOptions={{ dataSource: pronounsData, ...appConst.defaultSelectBoxOptions }}>
                  <Label text="Pronouns" />
                </SimpleItem>
                <SimpleItem dataField="Company" />
                <SimpleItem dataField="Position" />
                <SimpleItem
                  editorType="dxDateBox"
                  dataField="DateVaccinationRecordVerified"
                  editorOptions={{ ...appConst.defaultSelectDateBoxOptions }}
                >
                  <Label text="Vaccination Verified Date" />
                </SimpleItem>
              </GroupItem>
              <GroupItem caption="Contact Information" colCount={4}>
                <Item>
                  <Label text="Address1" />
                  <AddressAutoComplete
                    autoCompleteTextBoxId={"autoCompleteTextBoxId"}
                    updateAddressFields={this.updateAddressFields}
                    value={rowData && rowData.Address1}
                    onValueChanged={(value) => {
                      this.setState({ rowData: { ...rowData, Address1: value } })
                    }}
                    city={city}
                    province={province}
                    width="25%"
                    addressVerified={rowData.AddressVerified}
                    setAddressVerified={this.setAddressVerified}
                  />
                </Item>
                <SimpleItem dataField="City"
                  editorOptions={{
                    ...appConst.defaultAddressInput,
                    onValueChanged: (e) => {
                      if (typeof e.event === "undefined" && e.value === null) return;
                      this.setState({ city: e.value }, () => compareAddresses(e, rowData, storedAddress, this.setAddressVerified))
                    }
                  }} />
                <SimpleItem
                  editorType="dxSelectBox"
                  dataField="ProvinceId"
                  editorOptions={{
                    dataSource: provincesData, ...appConst.defaultSelectBoxOptions, grouped: false,
                    onValueChanged: (e) => {
                      if (typeof e.event === "undefined" && e.value === null) return;
                      this.setState({ province: e.component.option('text') }, () => compareAddresses(e, rowData, storedAddress, this.setAddressVerified));
                    },
                  }}
                >
                  <Label text="Province" />
                </SimpleItem>
                <SimpleItem dataField="PostalCode" editorType="dxTextBox"
                  editorOptions={{
                    ...appConst.defaultAddressInput,
                    onValueChanged: (e) => {
                      if (typeof e.event === "undefined" && e.value === null) return;
                      compareAddresses(e, rowData, storedAddress, this.setAddressVerified);
                    },
                  }}
                />
              </GroupItem>
              <GroupItem name="EmailsAndPhones" colCount={2}>
                <GroupItem name="Emails" colCount={1}>
                  {rowData.EmailAddresses && rowData.EmailAddresses.map((email, index) => (
                    <GroupItem key={`EmailGroup ${index + 1}`} colCount={6}>
                      <SimpleItem
                        key={`Emails${email.Id}`}
                        colSpan={2}
                        dataField={`EmailAddresses[${index}].Address`}
                      >
                        <Label text={`Email ${index + 1}`} />
                        <RequiredRule />
                        <PatternRule
                          pattern={appConst.emailPattern}
                          message={appConst.emailValidationMessage}
                        />
                      </SimpleItem>
                      <SimpleItem
                        key={`PreferredEmails${email.Id}`}
                        dataField={`EmailAddresses[${index}].Preferred`}
                        editorType="dxSwitch"
                        verticalAlignment="bottom"
                        editorOptions={{
                          onValueChanged: () => { this.form && setPreferredEmailAddress(index, this.form.instance); },
                        }}
                      >
                        <Label text="Preferred" />
                      </SimpleItem>
                      <SimpleItem
                        key={`EmailTypes${email.Id}`}
                        colSpan={2}
                        editorType="dxSelectBox"
                        dataField={`EmailAddresses[${index}].EmailTypeId`}
                        editorOptions={
                          {
                            dataSource: emailAddressTypesData,
                            ...appConst.defaultSelectBoxOptions,
                            grouped: false,
                          }
                        }
                      >
                        <Label text={`Email Type ${index + 1}`} />
                      </SimpleItem>
                      <SimpleItem
                        key={`EmailRemoveButton${email.Id}`}
                        itemType="button"
                        horizontalAlignment="left"
                        verticalAlignment="bottom"
                        buttonOptions={this.removeEmailButtonOptions(`${index}`)}
                      />
                    </GroupItem>
                  ))}
                  <SimpleItem
                    itemType="button"
                    horizontalAlignment="left"
                    buttonOptions={this.addEmailButtonOptions}
                  />
                </GroupItem>
                <GroupItem name="Phones" colCount={1}>
                  {rowData.PhoneNumbers && rowData.PhoneNumbers.map((phone, index) => (
                    <GroupItem key={`phoneGroup ${index + 1}`} colCount={6}>
                      <SimpleItem
                        key={`Phones${phone.Id}`}
                        dataField={`PhoneNumbers[${index}].Number`}
                        colSpan={2}
                        editorOptions={appConst.phoneEditorOptions}
                      >
                        <Label text={`Phone ${index + 1}`} />
                        <RequiredRule />
                        <PatternRule
                          pattern={appConst.phone10DigitsPattern}
                          message={appConst.phoneEditorOptions.message}
                        />
                      </SimpleItem>
                      <SimpleItem
                        key={`PreferredPhones${phone.Id}`}
                        dataField={`PhoneNumbers[${index}].Preferred`}
                        editorType="dxSwitch"
                        verticalAlignment="bottom"
                        editorOptions={{
                          onValueChanged: () => { this.form && setPreferredPhoneNumber(index, this.form.instance); },
                        }}
                      >
                        <Label text="Preferred" />
                      </SimpleItem>
                      <SimpleItem
                        key={`PhoneTypes${phone.Id}`}
                        colSpan={2}
                        editorType="dxSelectBox"
                        dataField={`PhoneNumbers[${index}].PhoneTypeId`}
                        editorOptions={
                          {
                            dataSource: phoneTypesData,
                            ...appConst.defaultSelectBoxOptions,
                            grouped: false,
                          }
                        }
                      >
                        <Label text={`Phone Type ${index + 1}`} />
                      </SimpleItem>
                      <SimpleItem
                        key={`PhoneRemoveButton${phone.Id}`}
                        itemType="button"
                        horizontalAlignment="left"
                        verticalAlignment="bottom"
                        buttonOptions={this.removePhoneButtonOptions(`${index}`)}
                      />
                    </GroupItem>
                  ))}
                  <SimpleItem
                    itemType="button"
                    horizontalAlignment="left"
                    buttonOptions={this.addPhoneButtonOptions}
                  />
                </GroupItem>
              </GroupItem>
              <GroupItem colCount={7}>
                <EmptyItem />
                <EmptyItem />
                <SimpleItem
                  itemType="button"
                  horizontalAlignment="right"
                  buttonOptions={this.saveDataButtonOptions}
                />
                <SimpleItem
                  itemType="button"
                  horizontalAlignment="center"
                  buttonOptions={{
                    text: "Save",
                    width: 150,
                    onClick: () => {
                      this.saveData(false);
                    }
                  }
                  }
                />
                <SimpleItem
                  itemType="button"
                  horizontalAlignment="left"
                  buttonOptions={this.cancelDataButtonOptions}
                />
                <EmptyItem />
                <EmptyItem />
              </GroupItem>
            </Form>
          </ScrollView>
        </div>
        <div className={(selectedTabIndex === 1) ? 'tab-form-container-visible' : 'tab-container-invisible'}>
          <PersonCallLogGrid
            ref="personCallLogGrid"
            Id={rowData.Id}
          />
        </div>
        <div className={(selectedTabIndex === 2) ? 'tab-form-container-visible' : 'tab-container-invisible'}>
          <CommitteeGrid
            personId={rowData.Id}
            ref="committeeGrid"
          />
        </div>
      </>
    );
  }
}

EditNonMemberForm.propTypes = {
  pronounsData: PropTypes.object.isRequired,
  salutationsData: PropTypes.object.isRequired,
  emailAddressTypesData: PropTypes.array.isRequired,
  phoneTypesData: PropTypes.array.isRequired,
  rowData: PropTypes.object.isRequired,
  addNewMode: PropTypes.bool.isRequired,
  hideEditPopupAndRefreshGrid: PropTypes.func.isRequired,
  hideEditPopup: PropTypes.func.isRequired,
};
export default EditNonMemberForm;
