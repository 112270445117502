import React from 'react';
import './CallLogs.scss';
import PersonCallLogGrid from '../../components/person-call-log-grid/PersonCallLogGrid';

class CallLogs extends React.Component {
  constructor(props) {
    super(props);

    this.windowResized = this.windowResized.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResized);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResized);
  }

  windowResized() {
    this.refs.personCallLogGrid.setgridHeight();
  }

  render() {
    return (
      <>
        <div className="title">
          <span>Call Logs</span>
        </div>
        <PersonCallLogGrid
          ref="personCallLogGrid"
        />
      </>
    );
  }
}
export default CallLogs;
